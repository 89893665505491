.individual-modal-main{margin:60px 0 50px;}
.ind-intro-main{padding:0 35px;opacity:1;}
.ind-intro-inner{display:-webkit-box;min-height:400px;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;-ms-flex-wrap:wrap;flex-wrap:wrap;border-bottom:1px solid #d7d7d7;padding-bottom:16px;}
.ind-intro-main .ind-intro-image{width:421px;position: relative;}
.ind-intro-main .ind-intro-image img{max-width:100%;}
.ind-intro-main .ind-intro-content{width:calc(100% - 421px);padding-left:20px;position: relative;}
.ind-intro-main .ind-intro-content h1{font-size:36px;margin:0 0 30px;}
.ind-intro-main .ind-intro-content p{color:#707070;font-size:13px;line-height:19px;margin:0 0 20px;}
.individual-modal-main .popular-prod-blk{width:100%;padding-right:0;border-bottom:1px solid #d7d7d7;padding-bottom:27px;}
.individual-modal-main .product-blk-main{margin:42px 0 0;}
.individual-modal-main .popular-product-inner-blk{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;position:relative;}
.individual-modal-main .product-blk-main .loadmore-btn{display:none;}
.individual-modal-main .popular-product-lisitng-main{width:31.7%;}
.individual-modal-main .popular-product-inner-lisitng{width:68.3%;}
.individual-modal-main .prod-list-new{margin-right:0;margin-left:31px;}
.individual-modal-main .popular-product-lisitng-main h3{width:100%;margin-bottom:9px;}
.ind-intro-main.is-loading{opacity: 0.5;}
.ind-intro-main.is-loading .ind-intro-image::before,.ind-intro-main.is-loading .ind-intro-content::before{content:"";width:100%;height:100%; min-height:300px;position: absolute;display:inline-block;background:-o-linear-gradient(135deg,#d7d7d7,#eee,#d7d7d7,#eee);background:linear-gradient(135deg,#d7d7d7,#eee,#d7d7d7,#eee);-webkit-animation:gradient 15s ease infinite;animation:placeholdergradient 1s ease infinite;background-size:400% 400%;}

.most-pop-placeholder.is-loading .product-placeholder::before{content:"";width:100%;height:100%;display:inline-block;background:-o-linear-gradient(135deg,#d7d7d7,#eee,#d7d7d7,#eee);background:linear-gradient(135deg,#d7d7d7,#eee,#d7d7d7,#eee);-webkit-animation:gradient 1s ease infinite;animation:placeholdergradient 1s ease infinite;background-size:400% 400%;}
.most-pop-placeholder .product-placeholder{width:100%;background:rgb(215 215 215 / 40%);position:relative;}
.most-pop-placeholder.popular-product-inner-lisitng{opacity:0.5;}
.most-pop-placeholder .item-image{width:109px;padding:0;margin-right:14px;}
.most-pop-placeholder .item-list-time{height:19px;}
.most-pop-placeholder .item-name{width:calc(100% - 233px);height:19px;}
.most-pop-placeholder .popular-product-lisitng-filter{width:100%;}
.placeholder-warning{color:#c80000;font-size:14px;line-height:16px;margin:13px 0 0;position:relative;padding-left:31px;display:inline-block;width:100%;}
.placeholder-warning::before{content:"";position:absolute;width:24px;height:19px;background-image:url("../assets/images/red-warning.svg");top:50%;left:0;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);}

.individual-modal-main .product-main-list-blk{padding:0 35px;}
.individual-modal-main .product-list-header{margin-bottom:60px;}
.individual-modal-main .large-main-title{font-size:32px;line-height:36px;}
.individual-modal-main .releasedate-blk{padding-top:60px;}
.individual-modal-main .search-txt h5{margin-left:10px;}
@media (max-width:1440px){
	.individual-modal-main .prod-list-new{margin-left:24px;}
	.most-pop-placeholder .item-name{width:calc(100% - 213px);}
}
@media (max-width:1200px){
	.individual-modal-main .popular-product-inner-lisitng{width:100%;}
	.individual-modal-main .popular-product-lisitng-main{width:100%;}
	.individual-modal-main .popular-product-lisitng-main h3{width:auto;margin-bottom:0;}
	.individual-modal-main .prod-list-new{margin-right:24px;margin-left:0;}
	.most-pop-placeholder .popular-product-lisitng-filter{width:auto;}
	.placeholder-warning{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);background:#c80000;padding:18px 18px 18px 50px;border-radius:5px;z-index:1;color:#fff;max-width:319px;}
	.placeholder-warning::before{background-image:url("../assets/images/white-warning.svg");left:18px;}
}
@media (max-width:992px){
	.individual-modal-main{margin:40px 0;}
	.ind-intro-main .ind-intro-image{width:321px;}
	.ind-intro-main .ind-intro-content{width:calc(100% - 321px);}
}
@media (max-width:899px){
	.ind-intro-main{padding:0;}
	.individual-modal-main .product-main-list-blk{padding:0;}
}
@media (max-width:767px){
	.individual-modal-main{margin:20px 0 30px;}
	.ind-intro-inner{border-bottom:none;padding-bottom:0;}
	.ind-intro-main .ind-intro-image img{max-width:177px;}
	.ind-intro-main .ind-intro-image{width:100%;margin:0 0 22px;}
	.ind-intro-main .ind-intro-content{width:100%;padding-left:0;}
	.ind-intro-main .ind-intro-content h1{margin:0 0 17px;}
	.individual-modal-main .popular-prod-blk{border-bottom:none;padding-bottom:0;}
	.individual-modal-main .prod-list-new{margin-right:0;}
	.individual-modal-main .product-blk-main .loadmore-btn{display:block;width:100%;}
	.individual-modal-main .popular-prod-blk .popular-product-lisitng-main{margin-bottom:18px;}
	
	.most-pop-placeholder .item-name{width:calc(100% - 233px);}

	.individual-modal-main .releasedate-blk{padding-top:42px;}
	.individual-modal-main .large-main-title{font-size:20px;line-height:23px;}
	.individual-modal-main .product-list-header{margin-bottom:23px;}
	.ind-intro-main .ind-intro-content p.read-more-txt { color: #000; font-style: italic; font-size: 13px; line-height: 19px; font-weight: 400; }
}
@media (max-width:400px){
	.most-pop-placeholder .item-image{width:80px;}
	.most-pop-placeholder .item-list-time{height:17px;}
	.most-pop-placeholder .item-name{height:17px;width:calc(100% - 184px);}
	.placeholder-warning{min-width:265px;}
}