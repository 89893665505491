/* BebasNeue */
@font-face{font-family:"BebasNeue-Regular";font-style:normal;font-weight:normal;src:local("BebasNeue-Regular"), url('BebasNeue-Regular.ttf') format('truetype')}

/* Helvetica */
@font-face{font-family:"HelveticaNeueLTStd-Hv";font-style:normal;font-weight:normal;src:local("HelveticaNeueLTStd-Hv"), url('HelveticaNeueLTStd-Hv.otf') format('opentype')}
@font-face{font-family:"HelveticaNeueLTStd-It";font-style:normal;font-weight:normal;src:local("HelveticaNeueLTStd-It"), url('HelveticaNeueLTStd-It.otf') format('opentype')}
@font-face{font-family:"HelveticaNeueLTStd-Th";font-style:normal;font-weight:normal;src:local("HelveticaNeueLTStd-Th"), url('HelveticaNeueLTStd-Th.otf') format('opentype')}
@font-face{font-family:"HelveticaNeueLTStd-UltLt";font-style:normal;font-weight:normal;src:local("HelveticaNeueLTStd-UltLt"), url('HelveticaNeueLTStd-UltLt.otf') format('opentype')}
@font-face{font-family:"HelveticaNeueLTStd-Bd";font-style:normal;font-weight:normal;src:local("HelveticaNeueLTStd-Bd"), url('HelveticaNeueLTStd-Bd.otf') format('opentype'),url('HelveticaNeueLTStd-Bd.woff') format('woff'), url('HelveticaNeueLTStd-Bd.ttf')  format('truetype'), url('HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd') format('svg');}
@font-face{font-family:"HelveticaNeueLTStd-Lt";font-style:normal;font-weight:normal;src:local("HelveticaNeueLTStd-Lt"), url('HelveticaNeueLTStd-Lt.otf') format('opentype')}
@font-face{font-family:"HelveticaNeueLTStd-Cn";font-style:normal;font-weight:normal;src:local("HelveticaNeueLTStd-Cn"), url('HelveticaNeueLTStd-Cn.otf') format('opentype')}
@font-face{font-family:"helvetica-normal";font-style:normal;font-weight:normal;src:local("helvetica-normal"), url('helvetica-normal.ttf') format('truetype')}


@font-face{font-family:'Arial Regular';font-style:normal;font-weight:normal;src:local('Arial Regular'), url('ARIAL.woff') format('woff');}
@font-face{font-family:'Arial Bold';font-style:normal;font-weight:normal;src:local('Arial Bold'), url('ARIALBD 1.woff') format('woff');}
@font-face{font-family:'Arial Bold';font-style:normal;font-weight:normal;src:local('Arial Bold'), url('ARIALBD.woff') format('woff');}