@font-face{font-family:'Open Sans';src:url('../assets/fonts/OpenSans-Regular.woff') format('woff'),url('../assets/fonts/OpenSans-Regular.woff2') format('woff2');font-weight:normal;font-style:normal;font-display:swap;}
@font-face{font-family:'Open Sans';src:url('../assets/fonts/OpenSans-SemiBold.woff') format('woff'),url('../assets/fonts/OpenSans-SemiBold.woff2') format('woff2');font-weight:600;font-style:normal;font-display:swap;}
@font-face{font-family:'Open Sans';src:url('../assets/fonts/OpenSans-Bold.woff') format('woff'),url('../assets/fonts/OpenSans-Bold.woff2') format('woff2');font-weight:700;font-style:normal;font-display:swap;}
@font-face{font-family:'Open Sans';src:url('../assets/fonts/OpenSans-ExtraBold.woff') format('woff'),url('../assets/fonts/OpenSans-ExtraBold.woff2') format('woff2');font-weight:800;font-style:normal;font-display:swap;}

body{font-family:'Open Sans',sans-serif;}
body > iframe{display:none;}

/* breadcrumb */
.site-breadcrumb{margin:55px auto 0;}
.site-breadcrumb ul{margin:0;padding:0;list-style-type: none;display:flex;flex-wrap:wrap;gap:8px;font-weight:700;font-size:12px;line-height:16px;}
.site-breadcrumb ul li:not(:last-child):after{content: '>>';margin-left:8px;}
.site-breadcrumb ul li a, .site-breadcrumb ul li span{color: #757575;text-transform:uppercase;}

/* store locator banner */
.store-locator .main-heading{font-weight:700;font-size:36px;line-height:49px;margin:0;}
.store-locator .sub-heading{font-size:15px;margin:0;}
.store-locator .sub-heading .back-link{color:#000;text-decoration:underline;text-decoration-thickness:0.6px;}
.store-locator .store-locator-banner{padding:52px 0 60px;text-align:center;}
.store-locator .store-locator-banner .main-heading{margin:0 0 20px;}

/* store locator form */
.store-locator .store-locator-form{padding:77px 0 60px;max-width:823px;margin:0 auto;}
.store-locator .store-locator-form .store-locator-form__head{text-align:center;margin:0 0 58px;}
.store-locator .store-locator-form .store-locator-form__box{background:#EFEFEF;border-radius:20px;padding:37px 61px;}
.store-locator .store-locator-form .store-locator-form__box-head{text-align:center;margin:0 0 20px;}
.store-locator .store-locator-form .store-locator-form__box-head h2{font-size:20px;line-height:27px;margin:0;font-weight:700;}
.store-locator .store-locator-form .store-locator-form__box-head p{margin:0 0 5px;}
.store-locator .store-locator-form .store-locator-form__box-head .store-locator-form__user-location::before{content:'';margin-right:5px;background:url(../assets/images/location-marker.svg) no-repeat center;background-size:contain;width:23px;height:23px;display:inline-block;vertical-align:middle;transform:translateY(-1px);}
.store-locator .store-locator-form .store-locator-form__box-head .store-locator-form__user-location{cursor: pointer;}
.store-locator .store-locator-form .store-locator-form__box-head .store-locator-form__user-location-clear{cursor: pointer;font-size:0;background:url(../assets/images/circle-xmark-regular.svg) no-repeat center;background-size:contain;width:18px;height:18px;display:inline-block;vertical-align:middle;transform:translateY(-1px);}
.store-locator .store-locator-form .store-locator-form__box-search{position:relative;margin:0 0 50px;}
.store-locator .store-locator-form .store-locator-form__box-search input[type="text"]{width:100%;border:0;border-bottom:1px solid #000;padding:10px 180px 10px 0;background-color:transparent;color:#707070;}
.store-locator .store-locator-form .store-locator-form__box-search input[type="button"]{background-color:#000;color:#fff;border:1px solid #000000;padding:8px 27px;min-width:171px;border-radius:10px;font-size:15px;line-height:20px;font-weight:600;position:absolute;right:0;bottom:5px;}
.store-locator .store-locator-form .store-locator-form__box-btn{text-align:center;}
.store-locator .store-locator-form .store-locator-form__box-btn h3{margin:0;font-weight:800;font-size:15px;line-height:15px;}
.store-locator .store-locator-form .store-locator-form__box-btn h3 a{color: #000;}

/* store locator listing */
.store-locator-listing{padding:60px 0 80px;}
.store-locator-listing .store-locator-listing__head{margin:0 0 27px;display:flex;flex-wrap:wrap;align-items:center;gap:10px 7px;}
.store-locator-listing .store-locator-listing__head p{margin: 0;}
.store-locator-listing .store-locator-listing__head select{border:1px solid #000;font-weight:700;font-size:15px;padding:2px 30px 2px 10px;appearance:none;background:url(../assets/images/caret-down.svg) no-repeat;background-position:right 8px center;}
.store-locator-listing__row{display:grid;gap:27px 24px;grid-template-columns:repeat(3,1fr);}
.store-locator-listing__row .store-locator-listing__item{border:1px solid #000;border-radius:10px;padding:19px 23px;position:relative;}
.store-locator-listing__row .store-locator-listing__item h4{font-weight:700;font-size:20px;line-height:18px;margin:0 0 15px;padding:0 10px 15px;border-bottom:1px solid #CCCBCB;text-transform:uppercase;}
.store-locator-listing__row .store-locator-listing__item > p{font-size:15px;line-height:15px;margin:0 0 8px;padding:0 110px 0 10px;word-break:break-word;}
.store-locator-listing__row .store-locator-listing__item > p.address,
.store-locator-listing__row .store-locator-listing__item > p.tel{font-size:16px;line-height:18px;}
.store-locator-listing__row .store-locator-listing__item > p.time{font-size:12px;font-weight:700;color:#199234;margin-top:10px;}
.store-locator-listing__row .store-locator-listing__item > p.link{font-weight:600;}
.store-locator-listing__row .store-locator-listing__item > p.link a{color:#000;text-decoration:underline;text-decoration-thickness:1px;}
.store-locator-listing__row .store-locator-listing__item > p:last-of-type{margin:0;}
.store-locator-listing__row .store-locator-listing__item .btn-wrap{position:absolute;bottom:19px;right:23px;}
.store-locator-listing__row .store-locator-listing__item .btn-wrap .btn{border:1px solid #000000;border-radius:5px;font-size:12px;line-height:16px;color:#000;}
.store-locator-listing__row .store-locator-listing__item .hidden-link{font-size:0;position:absolute;top:0;right:0;bottom:0;left:0;z-index:1;content: "";cursor:pointer;background-color:rgba(0,0,0,0);}
.store-locator .loadmore-btn{margin-top:30px;}
.store-locator .loadmore-btn .load-more{color:#000;border-color:#000;border-radius:7px;min-width: 200px;}

/* store locator directory listing */
.store-locator-directorys-listing{padding-bottom:60px;}
.store-locator-directory__row{margin:0;padding:0;list-style-type: none;display:grid;gap:14px 10px;grid-template-columns:repeat(5,1fr);}
.store-locator-directory__row .store-locator-directory__item a{border:1px solid #000;border-radius:10px;padding:17px 18px;position:relative;display:inline-flex;align-items:center;width:100%;height:100%;font-size:20px;line-height:18px;font-weight:700;color:#000;text-decoration:none;text-transform:uppercase;}
.store-locator-directory__row .store-locator-directory__item a .count{margin-left:auto;}

/* store locator state listing */
.store-locator-state .store-locator-listing__head{margin:0 0 38px;}
.store-locator-state .store-locator-listing__head h2{text-transform:uppercase;font-weight:700;font-size:24px;line-height:32px;margin:0;}
.store-locator-state .store-locator-listing{padding:15px 0 160px;}

/* store locator detail */
.store-locator-detail__banner{display:flex;flex-wrap:wrap;width:100%;background: #f5f5f5;margin:60px auto 75px;}
.store-locator-detail__banner .store-locator-detail__banner-img{width:62%;position: relative;padding-top: 41.2%;}
.store-locator-detail__banner .store-locator-detail__banner-img img{width:100%;height: 100%;object-fit:cover;object-position: center;position: absolute;inset: 0;}
.store-locator-detail__banner .store-locator-detail__banner-info{width:38%;padding:60px 100px 60px 60px;}
.store-locator-detail__banner .store-locator-detail__banner-info h1{font-size:36px;line-height:1;margin:0 0 10px;font-weight:700;}
.store-locator-detail__banner .store-locator-detail__banner-info p.description{font-size:12px;line-height:1;font-weight:600;}
.store-locator-detail__banner .store-locator-detail__banner-info .location-info{margin:38px 0 40px;}
.store-locator-detail__banner .store-locator-detail__banner-info .location-info p{font-size:15px;line-height:15px;margin:0 0 8px;}
.store-locator-detail__banner .store-locator-detail__banner-info .location-info p.address,
.store-locator-detail__banner .store-locator-detail__banner-info .location-info p.tel{font-size:16px;}
.store-locator-detail__banner .store-locator-detail__banner-info .location-info p.time{font-size:12px;font-weight:700;color:#199234;margin-top:10px;}
.store-locator-detail__banner .store-locator-detail__banner-info .location-info p.link{font-weight:600;}
.store-locator-detail__banner .store-locator-detail__banner-info .location-info p.link a{color:#000;text-decoration:underline;text-decoration-thickness:1px;}
.store-locator-detail__banner .store-locator-detail__banner-info .location-info p:last-of-type{margin:0;}
.store-locator-detail__banner .store-locator-detail__banner-info .btn-wrap{display:flex;flex-wrap:wrap;gap:10px;width:110%;}
.store-locator-detail__banner .store-locator-detail__banner-info .btn-wrap .btn{border:1px solid #000000;border-radius:10px;padding:12px;font-size:12px;line-height:12px;font-weight:600;color:#000;text-decoration:none;text-transform:uppercase;display:inline-flex;flex-direction:column;align-items:center;justify-content:center;min-width:133px;}
.store-locator-detail__banner .store-locator-detail__banner-info .btn-wrap .btn::after{content:'';margin:10px auto 0;width:20px;height:20px;}
.store-locator-detail__banner .store-locator-detail__banner-info .btn-wrap .btn.get-directions::after{width:27px;height:27px;background: url(../assets/images/ic-direction.svg) no-repeat center;background-size:contain;}
.store-locator-detail__banner .store-locator-detail__banner-info .btn-wrap .btn.call-store::after{background: url(../assets/images/ic-call.svg) no-repeat center;background-size:contain;}

/* coupons promo section */
.coupons-promo-section{padding:48px 0 60px;border-top:1px solid #CCCBCB;}
.coupons-promo-section h2{font-size:36px;line-height:49px;font-weight:700;margin:0 0 23px;}
.coupons-promo-grid{display:grid;grid-template-columns:repeat(3,1fr);gap:24px;}
.coupons-promo-grid .coupons-promo-grid__item{background: #000;border-radius:10px;text-align:center;position: relative;color:#fff;padding:18px 18px 85px;align-content:center;}
.coupons-promo-grid .coupons-promo-grid__item.coupons-promo-grid__item--expired{background: #6F6F6F;pointer-events:none;}
.coupons-promo-grid .coupons-promo-tag{width:100%;max-width:256px;font-size:12px;line-height:1;font-weight:600;color:#ffffff;text-align:center;padding:6.5px;border-radius:50px;margin:0 auto;}
.coupons-promo-grid .coupons-promo-tag.coupons-promo-tag--exclusive{background: #F44336;}
.coupons-promo-grid h3{font-size:32px;line-height:1;font-weight:700;margin:0 0 4px;}
.coupons-promo-grid p{margin:0;}
.coupons-promo-grid .coupons-promo-tag + h3{margin:16px 0 0;}
.coupons-promo-grid .btn-wrap{position:absolute;bottom:28px;left:0;right:0;text-align:center;}
.coupons-promo-grid .btn{border:1px solid #fff;border-radius:5px;color:#fff;font-weight:700;text-transform:uppercase;min-width:178px;}

/* product listing */
.store-locator-detail .product-main-list-blk{padding:52px 0 58px;border-top:1px solid #CCCBCB;}
.store-locator-detail .product-list-header{margin:0 0 23px;}
.store-locator-detail .product-list-header h4{font-size:36px;line-height:49px;font-weight:700;}
.store-locator-detail .product-main-list-blk .product-list-header .prod-list-title-wrap{padding:0;width:100%;}

/* store locator feedback modal */
.store-locator-feedback-btn{cursor:pointer;position:relative;text-decoration:underline;}
.store-feedback-modal .modal-dialog{max-width:740px;margin-top:6%;}
.store-feedback-modal .modal-dialog .form-row{width:calc(50% - 8px);}
.store-feedback-modal .modal-dialog .form-row.full-width{width:100%;}
.store-feedback-modal .modal-dialog .user-set-popup-block{display:flex;flex-wrap:wrap;align-items:flex-start;gap:0 15px;}
.store-feedback-modal .modal-dialog .user-set-popup-block .form-row label{margin-bottom:4px;}
.store-feedback-modal .modal-dialog .store-feedback-message{display:none;width:100%;background-color:rgba(0,128,0,0.23);padding:8px 15px;border-radius:4px;margin:10px 0;transition:.3s all ease-in-out;margin:0 0 20px;}
.store-feedback-modal .modal-dialog .store-feedback-message p{color:green;font-family:"HelveticaNeueLTStd-Bd";text-align:left;font-size:14px;padding:4px 0 0;margin:0;}
.store-feedback-modal .modal-dialog .store-feedback-message p .success-icon{color:green;font-size:17px;text-align:center;vertical-align:middle;line-height:12px;margin-top:-3px;margin-right:3px;}

/* media queries */
@media (min-width:1025px){
    .header + div{padding-top:70px;}
}
@media (min-width:1440px){
    .padd-large{padding:0 55px;}
}
@media (max-width:1199px){
    /* store locator listing */
    .store-locator-listing{padding:60px 0 130px;}
    .store-locator-listing__row{grid-template-columns:repeat(2,1fr);gap:22px 20px;}

    /* store locator directory listing */
    .store-locator-directory__row{grid-template-columns:repeat(4,1fr);gap:12px 10px;}
    .store-locator-directory__row .store-locator-directory__item a{padding:15px 16px;}

    /* store locator state listing */
    .store-locator-state .store-locator-listing__head{margin:0 0 30px;}
    .store-locator-state .store-locator-listing{padding:0 0 130px;}

    /* store locator detail */
    .store-locator-detail__banner .store-locator-detail__banner-img{width:58%;}
    .store-locator-detail__banner .store-locator-detail__banner-info{width:42%;padding:40px 60px 40px 40px;}

    /* coupons promo section */
    .coupons-promo-section h2{font-size:32px;line-height:44px;}
    .coupons-promo-grid{gap:18px;}
    .coupons-promo-grid h3{font-size:28px;}

    /* product listing */
    .store-locator-detail .product-list-header h4{font-size:32px;line-height:44px;}
}
@media (max-width:879px){
    /* store locator form */
    .store-locator .store-locator-form{padding:50px 0 30px;}
    .store-locator .store-locator-form .store-locator-form__head{margin:0 0 40px;}

    /* store locator listing */
    .store-locator-listing{padding:30px 0 100px;}
    .store-locator-listing__row .store-locator-listing__item{padding:17px 18px;}
    .store-locator-listing__row .store-locator-listing__item h4{padding:0 7px 15px;}
    .store-locator-listing__row .store-locator-listing__item > p{padding:0 110px 0 7px;}

    /* store locator directory listing */
    .store-locator-directory__row{grid-template-columns:repeat(3,1fr);}

    /* store locator state listing */
    .store-locator-state .store-locator-listing__head{margin:0 0 24px;}
    .store-locator-state .store-locator-listing{padding:0 0 100px;}

    /* store locator detail */
    .store-locator-detail__banner .store-locator-detail__banner-img{width:54%;}
    .store-locator-detail__banner .store-locator-detail__banner-info{width:46%;padding:30px 40px 30px 30px;}
    .store-locator-detail__banner .store-locator-detail__banner-info .location-info{margin:28px 0 30px;}
    .store-locator-detail__banner .store-locator-detail__banner-info .btn-wrap{width:105%;}

    /* coupons promo section */
    .coupons-promo-grid{grid-template-columns:repeat(2,1fr);}

    /* store locator feedback modal */
    .store-feedback-modal .modal-dialog{margin-top:8%;}
}
@media (max-width:779px){
    /* store locator form */
    .store-locator .store-locator-form .store-locator-form__box-search input[type="button"]{min-width:141px;}

    /* store locator listing */
    .store-locator-listing__row .store-locator-listing__item > p{padding:0 7px;}
    .store-locator-listing__row .store-locator-listing__item .btn-wrap{position:static;padding:14px 7px 0;}

    /* store locator directory listing */
    .store-locator-directory__row .store-locator-directory__item a{font-size:18px;line-height:16px;}

    /* store locator detail */
    .store-locator-detail__banner{flex-direction:column-reverse;margin:50px auto 60px;}
    .store-locator-detail__banner .store-locator-detail__banner-img{width:100%;padding-top:44%;}
    .store-locator-detail__banner .store-locator-detail__banner-info{width:100%;padding:30px;}
    .store-locator-detail__banner .store-locator-detail__banner-info .btn-wrap{width:100%;gap:8px;}
    .store-locator-detail__banner .store-locator-detail__banner-info h1{font-size:32px;}
    .store-locator-detail__banner .store-locator-detail__banner-info .btn-wrap .btn::after{width:18px;height:18px;}
    .store-locator-detail__banner .store-locator-detail__banner-info .btn-wrap .btn.get-directions::after{width:22px;height:22px;margin:8px auto 0;}

    /* coupons promo section */
    .coupons-promo-section h2{font-size:28px;line-height:40px;}
    .coupons-promo-grid h3{font-size:24px;}
    .coupons-promo-grid .btn{font-size:14px;}

    /* product listing */
    .store-locator-detail .product-list-header h4{font-size:28px;line-height:40px;}
}
@media (max-width:629px){
    .site-breadcrumb{margin:40px 0 0;}

    /* store locator banner */
    .store-locator .main-heading{font-size:32px;line-height:45px;}
    .store-locator .store-locator-banner{padding:42px 0 50px;}
    .store-locator .store-locator-banner .main-heading{margin:0 0 15px;}

    /* store locator form */
    .store-locator .store-locator-form .store-locator-form__box{padding:30px 40px;}

    /* store locator listing */
    .store-locator-listing__row{grid-template-columns:repeat(1,1fr);gap:20px;}
    .store-locator-listing__row .store-locator-listing__item{padding:16px 14px;}
    .store-locator-listing__row .store-locator-listing__item h4{font-size:18px;line-height:16px;}

    /* store locator directory listing */
    .store-locator-directory__row{grid-template-columns:repeat(2,1fr);}

    /* store locator state listing */
    .store-locator-state .store-locator-listing__head h2{font-size:22px;line-height:30px;}

    /* coupons promo section */
    .coupons-promo-grid{grid-template-columns:repeat(1,1fr);}

    /* store locator feedback modal */
    .store-feedback-modal .modal-dialog{margin-top:5%;}
    .store-feedback-modal .modal-dialog .form-row{width:100%;}
}
@media (max-width:567px){
    /* store locator form */
    .store-locator .store-locator-form .store-locator-form__box{padding:28px 34px;}
    .store-locator .store-locator-form .store-locator-form__box-search{text-align:center;margin:0 0 36px;}
    .store-locator .store-locator-form .store-locator-form__box-search input[type="text"]{padding:10px 0;margin-bottom:20px;}
    .store-locator .store-locator-form .store-locator-form__box-search input[type="button"]{position:static;}

    /* store locator detail */
    .store-locator-detail__banner .store-locator-detail__banner-img{padding-top:48%;}
    .store-locator-detail__banner .store-locator-detail__banner-info{padding:25px;}
    .store-locator-detail__banner .store-locator-detail__banner-info .btn-wrap .btn{min-width: 122px;}

    /* coupons promo section */
    .coupons-promo-section h2{font-size:24px;line-height:34px;}

    /* product listing */
    .store-locator-detail .product-list-header h4{font-size:24px;line-height:34px;}
}
@media (max-width:480px){
    /* store locator directory listing */
    .store-locator-directory__row{grid-template-columns:repeat(1,1fr);}

    /* store locator detail */
    .store-locator-detail__banner .store-locator-detail__banner-img{padding-top:54%;}
    .store-locator-detail__banner .store-locator-detail__banner-info{padding:20px;}
    .store-locator-detail__banner .store-locator-detail__banner-info .location-info{margin:24px 0 26px;}
    .store-locator-detail__banner .store-locator-detail__banner-info .btn-wrap .btn{min-width: 116px;padding:10px;}

    /* coupons promo section */
    .coupons-promo-grid .coupons-promo-tag{max-width:236px;}
}