*{overflow-anchor:none;}
input[type="password"]::-ms-reveal{display:none}
/* Header */
body{font-family:'Arial';font-weight:400;overflow-x:hidden;}
.form-control:focus{box-shadow:none;}
.top-header{padding:15px 40px;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:70px;}
.top-header .navbar-brand{padding:0;margin:0;line-height:1;}
.top-header .navbar-brand .logo img{max-width:100%;}
.top-header .navbar-brand.navbrand-res{display:none;}
.top-header .navbar{padding:0;position:static;}
.top-header .right-head{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;align-items:center;position:static;}
.top-header .right-head .sidelinks{display:-webkit-box;display:-ms-flexbox;display:flex;margin-left:20px;padding-left:20px;border-left:1px solid #fff;}
.top-header .right-head .sidelinks ul{margin:0;padding:0;}
.top-header .right-head .sidelinks ul li{list-style-type:none;float:left;margin:0 20px 0 0}
.top-header button.logout-head{border:0!important;-webkit-box-shadow:none!important;box-shadow:none!important;padding:0!important;background:transparent!important;opacity:1!important;}
.top-header button.logout-head>div{display:none;}
.top-header button.logout-head>span{padding:0!important;font-weight:normal!important;}
.top-header .right-head .sidelinks .nav-link{font-size:12px;color:#fff;line-height:14px;font-family:'Arial';padding:4px 0;font-weight:700;}
.top-header .right-head .sidelinks > .nav-link{margin: 0 20px 0 0;}
.top-header .right-head span{color:#fff;font-size:12px;line-height:14px;font-family:'Arial';}
.top-header .right-head .sidelinks .nav-link.signup{color:#fff;background:#000;border-radius:25px;padding:6.3px 21px 7.71px;margin-left:10px;font-weight:700;line-height:normal;}
.top-header .navbar-nav .nav-link{padding:0;}
.top-header .navbar-nav .has-submenu:hover .sub-mega-menu{visibility:visible;filter:alpha(opacity=9900);filter:alpha(opacity=99);-khtml-opacity:.99;-moz-opacity:.99;opacity:.99;}
.top-header .navbar-nav .nav-item{margin:0 24px 0 0;}
.top-header .navbar-nav .nav-item a{font-size:12px;line-height:14px;color:#fff;font-family:'Arial';padding:10px 0;font-weight:400;display:inline-block;text-decoration:none;}
.top-header .navbar-nav .nav-item > .nav-link > a{min-width:40px;text-align:right;}
.top-header .navbar-nav .nav-item:hover > .nav-link > a{font-weight: 700;}
.top-header .navbar-nav .nav-item:last-child{margin:0;}
.top-header span.timezone-cnt{padding-right:38px;color:#fff;font-size:12px;line-height:14px;font-family:'Arial';transition:.3s all ease-in-out;}
.top-header .header-search{position:relative;width:230px;justify-content:flex-end;}
.top-header .header-search input.form-control{border-radius:20px;color:#fff;padding:5px 28px 5px 15px;font-size:12px;line-height:14px;background-color:transparent;border:1px solid #fff;position:absolute;top:-100px;left:0;right:0;width:100%;height:40px;opacity:0;transition:.3s all ease-in-out;}
.top-header .header-search input[type="search"]::placeholder{color:#fff;}
.top-header .header-search input[type="search"]::-webkit-search-cancel-button{-webkit-appearance:none;}
.top-header .header-search .btn-search,
.top-header .header-search .btn-close{padding:0;border:0;position:absolute;top:-100px;right:20px;width:14px;height:14px;background-size:cover;background-image:url('../assets/images/search-icon.svg');opacity:0;transition:.3s top ease-in-out;}
.top-header .header-search span.btn-close{background-image:url('../assets/images/closeicon-white.svg');}
.top-header .header-search.active{width:230px;height:40px;visibility:visible;opacity:1;}
.top-header .header-search.active span.timezone-cnt{opacity:0;visibility:hidden;}
.top-header .header-search.active input.form-control{opacity:1;top:0;}
.top-header .header-search.active .btn-search-toggle{opacity:0;visibility:hidden;}
.top-header .header-search.active .btn-search,
.top-header .header-search.active .btn-close{opacity:1;top:50%;transform:translateY(-50%);}
.top-header .btn-search-toggle{padding:0;border:0;transition:.3s all ease-in-out;}
.header-res{display:none;}
.main-content{min-height: calc(100vh - 335px);}

.sub-mega-menu{position:absolute;top:60%;min-width:73%;max-width:100%;left:0;visibility:hidden;opacity:0;width:100%;padding-top:28px;}
.sub-mega-menu.active{display:block;}
.sub-mega-menu .megamenu-cnt{padding:30px 30px 0 30px;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;background:rgb(0 0 0 / 60%);-webkit-box-shadow:0px 9px 16.92px 1.08px rgba(0,0,0,0.2);box-shadow:0px 9px 16.92px 1.08px rgba(0,0,0,0.2);min-height:285px;}
.sub-mega-menu .megamenu-cnt ul li{font-size:12px;line-height:14px;}
.sub-mega-menu .megamenu-cnt ul{padding:0;list-style:none;}
.sub-mega-menu .menu-col-right{width:100%;margin:0 0 0 34px;display:flex;justify-content:flex-start;align-items:flex-start;flex-wrap:wrap;}
.sub-mega-menu .menu-col-right .sub-menu-col{width:110px;margin:0 25px 30px 0;}
.sub-mega-menu .menu-col-right .sub-menu-col:last-child{margin:0 0 30px;}
.sub-mega-menu .megamenu-cnt span.brand-title{font-size:12px;font-family:'Arial';font-weight:700;margin-bottom:28px;display:block;}

.nav-watchlist a.nav-link{color:#fff;font-size:12px;font-family:"Arial";position:relative;padding:0;line-height:14px;margin:0 24px 0 0;font-weight:700;}
.nav-watchlist a.nav-link .fa{margin-right:8px;}
.nav-watchlist a.nav-link.active:after{display:inline-block;}
.nav-watchlist.redicon{position:relative;}
.nav-watchlist.redicon a:after{content:"";height:7px;width:7px;background-color:#ff0000;border-radius:100%;display:inline-block;margin-left:3px;vertical-align:middle;}

.banner-carousel.banner-placeholder{background:rgb(215 215 215 / 40%);opacity:0.6;position:relative;}
.banner-carousel.banner-placeholder::before{content:"";width:100%;height:100%;display:inline-block;background:-o-linear-gradient(135deg,#d7d7d7,#eee,#d7d7d7,#eee);background:linear-gradient(135deg,#d7d7d7,#eee,#d7d7d7,#eee);-webkit-animation:gradient 1.5s ease infinite;animation:placeholdergradient 1.5s ease infinite;background-size:400% 400%;position:absolute;top:0;left:0;}
.banner-carousel{min-height:550px}
.banner-carousel .carousel-caption{width:100%;right:0;left:0;bottom:50px;max-width:1440px;display:block;margin:0 auto;padding:0 65px 0 20px;text-align:right;}
.banner-carousel .carousel-caption h5{font-size:26px;line-height:30px;font-weight:700;font-family:"Arial";margin:0 0 8px;}
.banner-carousel .carousel-caption p{font-size:16px;line-height:18px;font-weight:400;font-family:"Arial";margin:0 0 3px;}
.banner-carousel .carousel-caption a,
.banner-carousel .carousel-caption span{color:#fff;font-size:24px;}
.banner-carousel .carousel-caption span{font-size:12px;line-height:14px;font-family:'Arial';font-weight:400;padding:5px 21px;border:1px solid #fff;border-radius:20px;margin-top:5px;display:inline-block;}
.banner-carousel .carousel-indicators li{width:23%;background-color:#fff;opacity:0.4;height:5px;}
.banner-carousel .carousel-indicators li.active{opacity:1;}
.banner-carousel[data-slide-items="1"] .carousel-indicators{display:none;}
.banner-carousel[data-slide-items="2"] .carousel-indicators li{width:48%;}
.banner-carousel[data-slide-items="3"] .carousel-indicators li{width:31%;}
.banner-carousel[data-slide-items="4"] .carousel-indicators li{width:23%;}
.banner-carousel[data-slide-items="5"] .carousel-indicators li{width:18%;}
.banner-carousel .indicators-inner{display:inline-block;width:100%;}
.banner-carousel .carousel-indicators{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:0;max-width:100%;margin:0 auto;padding:0 15px}
.banner-carousel .carousel-indicators li:first-of-type{margin-left:0!important;}
.banner-carousel .carousel-indicators li:last-of-type{margin-right:0;}
.banner-carousel .carousel-item{background-blend-mode:luminosity;background-color:rgba(113,113,113,0.09);}
.banner-carousel .carousel-inner .carousel-item{position:relative;width:100%;overflow:hidden;height:550px}
.banner-carousel .carousel-inner .carousel-item a{position:relative;display:inline-block;width:100%;height:100%;}
.banner-carousel .carousel-inner .carousel-item a:before{content:'';background-color:rgb(0 0 0 / 35%);position:absolute;width:100%;height:100%;z-index:1}
.banner-carousel .carousel-inner .carousel-item img{position:absolute;left:-100%;right:-100%;top:-100%;bottom:-100%;margin:auto;height:100%;width:100%!important;object-fit:cover;}
.flex-end-cnt{-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}

/* Footer */
footer{background:#000;padding:50px 0 25px;}
footer .container{padding:0 50px;}
footer .footer-img{margin-bottom:20px;}
footer .footer-img svg{max-width:235px;}
footer .storelist .footer-img{margin-bottom:15px;max-width: 125px;border:1px solid #d7d7d7;border-radius:4px;}
footer ul{margin:0;padding:0;list-style:none;font-family:'Arial';font-weight:400;}
footer ul li a,
footer ul li{color:#d7d7d7;}
footer ul li a:hover,
footer ul li:hover{color:white;text-decoration:none;}
footer ul.socialmedia{width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:15px;line-height:1;}
footer ul.socialmedia li{font-size:20px;margin-left:10px;}
footer ul.socialmedia li:first-child{margin-left:0;}
footer ul.linklist li,
footer ul.loggedOutlist li{font-size:16px;margin-bottom:15px;line-height:18px;}
footer ul.copyright li{color:#d7d7d7;display:inline-block;font-size:11px;line-height:13px;margin-left:31px;}
footer ul.storelist li{display:inline-block;margin-left:10px;}
footer ul.copyright li:first-child,
footer ul.storelist li:first-child{margin-left:0;}
footer ul.copyright,
footer ul.storelist{text-align:right;}
footer .full-footer{display:inline-block;width:100%;}
footer .top-footer.full-footer,
footer .middle-footer.full-footer{margin-bottom:20px;}
footer .middle-footer.full-footer .row{-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;}
footer .middle-footer.full-footer{margin-bottom:0;}
footer ul.copyright{display:block}
footer ul.linklist li:last-child,
footer ul.loggedOutlist li:last-child{margin-bottom:0;}
footer .footer-cnt-wrap{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;}
footer .footer-logo{width:255px;padding-right:20px;}
footer .footer-nav{width:calc(100% - 255px);padding-left:20px;}
footer .footer-nav .footer-nav-menu{max-width:230px;}

/* Tooltip */
.tooltip .tooltip-inner{border:1px solid #000;background:#fff;color:#000;line-height:normal;}
.tooltip .arrow:before,
.tooltip .arrow:after{left:50%;border:solid transparent;content:" ";height:0;width:0;position:absolute;pointer-events:none;border-color:rgba(194,225,245,0);border-top-color:#000000;border-width:9px;margin-left:-9px;}
.tooltip .arrow:after{border-color:rgba(136,183,213,0);border-top-color:#ffffff;border-width:8px;margin-left:-8px;}
.tooltip .arrow{bottom:-.35rem;left:50%;-webkit-transform:translate(-50%,0);-ms-transform:translate(-50%,0);transform:translate(-50%,0);}
.tooltip.show{opacity:1;}

/* Product Listing */
.allproducts-with-date{position:relative;width:100%;display:inline-block;margin-bottom:5px;}
.allproducts-with-date::before{content:"";position:absolute;width:100%;height:1px;border-bottom:1px solid #d7d7d7;bottom:20px;left:0;}
.allproducts-with-date:last-child::before{bottom:26px;}
.allproducts-with-date:last-child .allproducts{margin-bottom:13px;}
.allproducts{padding:0;margin:0 -15px;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-bottom:30px;}
.allproducts>li{display:inline;float:left;padding:0 15px;width:25%;margin:0 0 40px;}
.allproducts li .product-item img{width:100%;height:100%;object-fit:cover;object-position:center;}
.allproducts li .product-item .infos,
.productdetail-cnt .product-item .infos{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;-ms-flex-wrap:wrap;flex-wrap:wrap;margin:0;padding:0;width:60px;}
.allproducts li .product-item .infos .info-count,
.productdetail-cnt .product-item .infos .info-count{padding:0;margin:0;list-style:none;}
.allproducts li .product-item .prod-list-new-cnt,
.productdetail-cnt .product-item .prod-list-new-cnt{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;margin-top:17.5px;align-items:flex-start;}
.allproducts li .product-item .prod-list-new-cnt .prod-detail-cnt,
.productdetail-cnt .product-item .prod-list-new-cnt .prod-detail-cnt{width:100%;margin-top:10px;}
.allproducts li .product-item .prod-list-new-cnt .prod-detail-cnt p,
.productdetail-cnt .product-item .prod-list-new-cnt .prod-detail-cnt p{margin:0;color:#707070;font-size:11px;line-height:13px;}
.allproducts li .product-item .prod-details,
.you-may-slider .product-item .prod-details{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;font-size:18px;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;width:calc(100% - 60px);}
.allproducts li .product-item .prod-date,
.productdetail-cnt .product-item .prod-date,
.productdetail-cnt .product-item .prod-price{width:100%;font-family:'Arial';font-weight:400;font-size:17px;line-height:19px;color:#000000;margin:0 0 3px;}
.allproducts li .product-item .infos .datetime,
.productdetail-cnt .product-item .infos .datetime{color:#737373;font-family:'HelveticaNeueLTStd-Bd';font-size:16px;}
.allproducts li .product-item .infos .info-count li,
.productdetail-cnt .product-item .infos .info-count li{color:#737373;position:relative;width:100%;padding-left:0;font-family:'Arial';font-weight:400;font-size:17px;line-height:1;text-align:right;color:#707070;margin:0 0 5px;}
.allproducts li .product-item .infos .info-count li:last-child,
.productdetail-cnt .product-item .infos .info-count li:last-child{margin:0;}
.allproducts li .product-item .infos .info-count li .tooltip,
.productdetail-cnt .product-item .infos .info-count li .tooltip{bottom:130%;visibility:hidden;left:50%;-webkit-transform:translate(-50%,0);-ms-transform:translate(-50%,0);transform:translate(-50%,0);}
.allproducts li .product-item .infos .info-count li:hover .tooltip,
.productdetail-cnt .product-item .infos .info-count li:hover .tooltip{opacity:1;visibility:visible;}
.allproducts li .product-item .prod-details .prod-name,
.you-may-slider .product-item .prod-details .prod-name{font-family:'Arial';font-size:17px;line-height:19px;color:#000000;margin:0 0 3px;width:100%;white-space:normal;font-weight:700;}
.allproducts li .product-item .prod-details .prod-name a,
.you-may-slider .product-item .prod-details .prod-name a{font-size:17px;line-height:19px;color:#000000;text-decoration:none;}
.allproducts li .product-item .prod-details .prod-color{display:inline;font-family:'Arial';font-weight:400;font-size:17px;}
.allproducts li .product-item .prod-details .prod-price{font-family:'Arial';font-size:17px;line-height:19px;color:#000000;text-align:left;width:100%;font-weight:400;}
.allproducts li .product-item i.fire svg,
.productdetail-cnt .product-item i.fire svg{height:14px;margin-top:-6px;}
.allproducts li .product-item i.eye-icon svg{margin-top:-5px}
.allproducts li .product-item li[title="Views"] i{font-size:21px;}
.allproducts li .product-item li[title="Likes"] i:hover svg path,
.allproducts li .product-item li[title="Likes"].active i svg path,
.prod-info-count .info-count li[title="Likes"].active i svg path{fill:#ea953d;stroke:#ea953d;}
.allproducts li .product-item li[title="Watchlist"] i,
.productdetail-cnt .product-item li[title="Watchlist"] i{font-size:14px;}
.allproducts li .product-item li[title="Watchlist"] i:hover,
.allproducts li .product-item li[title="Watchlist"].active i,
.prod-info-count .info-count li[title="Watchlist"].active i{color:#000;}
.allproducts li .product-item li[title="Watchlist"] i:hover:before,
.allproducts li .product-item li[title="Watchlist"].active i:before,
.prod-info-count .info-count li[title="Watchlist"].active i:before{content:'\f02e';}
.main_content.wrapp{padding:55px 0 40px;min-height:calc(100vh - 200px)}
.main_content.home-wrap{padding:30px 0 40px}
button.fullbtn.view-more:hover,
.user-set-popup-block .save-btn:hover,
.user-set-popup-block .cancel-btn:hover{background-color:rgba(0,0,0,0.9);}
.sign-block button.signin-btn:hover,
.sign-block button.signup-btn:hover{background:transparent}
.sidebar .sidebar-label{font-size:30px;font-family:"BebasNeue-Regular";}
.sidebar .sidebox ul{padding-left:20px;margin:0;list-style:none;}
.sidebar .sidebox ul li span{color:#000;font-size:18px;cursor:pointer;}
.sidebar .sidebox ul li span:hover{text-decoration:none;font-weight:700;-webkit-transition:0.3s;-o-transition:0.3s;transition:0.3s;}
.sidebar .sidebox ul li + li{margin-top:5px;-webkit-transition:0.3s;-o-transition:0.3s;transition:0.3s;}
.sidebar .sidebox ul li:hover span{-webkit-transition:0.3s;-o-transition:0.3s;transition:0.3s;}
.sidebar .sidebox select#timezones{border:1px solid black;padding:5px;width:90%;height:40px;border-radius:10px;outline:none;}
.sidebar .sidebox + .sidebox{margin-top:30px;}
.sidebar .has-sub-child .showmenu{cursor:pointer;margin-left:10px;position:relative;top:-2px;}
button.fullbtn.view-more{margin-top:15px;width:100%;border:0;background:#000;color:#fff;height:60px;font-family:"HelveticaNeueLTStd-Bd";}
.view-more .count{font-family:"helvetica-normal";}
.sidebar .sidebox ul li.active span{font-weight:bold;}
.prod-info-count .info-count li[title="Watchlist"] i{margin-right:5px}

/* Signup */
.modal{z-index:10500;overflow-x:hidden;overflow-y:auto;}
.signModal .container .modal-dialog{padding:0;max-width:516px;margin-top:120px;}
.signModal .container .modal-dialog .modal-content{border-radius:15px;border:0;}
.signModal .container .modal-dialog .modal-content .modal-body{padding:0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:stretch;-ms-flex-align:stretch;align-items:stretch;}
.signModal .container .modal-dialog .modal-content .modal-body .img-block{width:60%;float:left;overflow:hidden;}
.signModal .container .modal-dialog .modal-content .modal-body .img-block img{-o-object-fit:cover;object-fit:cover;height:100%;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block{width:100%;float:left;padding:57px 35px 70px;text-align:center;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block .navbar-brand{margin-bottom:40px;margin-right:0;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block h3{font-family:"Arial";font-size:34px;line-height:24px;font-weight:400;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block h3 span{font-size:13px;font-family:"Arial";font-weight:400;margin-top:10px;display:block;line-height:17px;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block h3 span strong{font-family:"Arial";font-weight:400;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block p{font-size:16px;margin-top:40px;line-height:24px;font-family:'Arial';font-weight:400;color:#707070;margin-bottom:20px;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block p a{width:auto;display:inline-block;margin:0;font-size:16px;line-height:24px;font-family:'Arial';font-weight:600;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block .navbar-brand + p{margin-top:0}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row{margin-left:0;margin-right:0}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input{width:100%;height:35px;border-radius:0;padding:0 10px;color:#707070;background:#F1F1F1;font-size:13px;line-height:24px;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input::-webkit-input-placeholder{font-size:13px;color:#707070;line-height:24px;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input::-moz-placeholder{font-size:13px;color:#707070;line-height:24px;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input:-ms-input-placeholder{font-size:13px;color:#707070;line-height:24px;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input::-ms-input-placeholder{font-size:13px;color:#707070;line-height:24px;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input::placeholder{font-size:13px;color:#707070;line-height:24px;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row + .form-row{margin-top:10px;margin-right:0;margin-left:0;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row.userForgotPwdMsg{margin-top:20px;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row.userForgotPwdMsg p{margin:10px 0;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input#check_agree,
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input#check_remember,
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input#news_letter{width:14px;height:14px;border-radius:0;position:static;margin:0px 10px 0 0;background:transparent;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row label.form-check-label[for="news_letter"],.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row label.form-check-label[for="check_agree"]{padding-left:21px;text-indent:-21px;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row label.form-check-label{font-size:13px;color:#707070;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row label.form-check-label span{color:#c90505;font-size:16px;line-height:0;}
.sign-block button.signup-btn,
.sign-block .signin-btn{display:block;width:100%;height:39px;margin-top:20px;border-radius:10px;background:#fff;color:#707070;border:1px solid #707070;font-size:14px;line-height:16px;font-family:"Arial";font-weight:400;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row label.form-check-label a{color:#707070;text-decoration:underline;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .checkbox-row{text-align:left;}
.sign-block form .form-row label.form-check-label{position:relative;cursor:pointer;}
.sign-block form .form-row label.form-check-label:before{content:'';-webkit-appearance:none;background-color:transparent;border:1px solid #9c9c9c;-webkit-box-shadow:0 1px 2px rgba(0,0,0,.05),inset 0 -12px 10px -4px rgba(0,0,0,.05);box-shadow:0 1px 2px rgba(0,0,0,.05),inset 0 -12px 10px -4px rgba(0,0,0,.05);padding:6px;display:inline-block;position:relative;vertical-align:middle;cursor:pointer;margin-right:5px;margin-top:-3px;}
.sign-block form .form-row input#check_agree,
.sign-block form .form-row input#check_remember,
.sign-block form .form-row input#news_letter{display:none}
.sign-block form .form-row input:checked + label:after{content:'';display:block;position:absolute;top:3px;left:4px;width:6px;height:10px;border:solid #000000;border-width:0 2px 2px 0;-webkit-transform:rotate(38deg);-ms-transform:rotate(38deg);transform:rotate(38deg);}
.sign-block span.or{display:block;text-align:center;margin-top:6px;color:#707070;font-family:"Arial";position:relative;font-size:10px;line-height:11px;font-weight:400;}
form#signupForm{max-width:313px;margin:0 auto;}
.sign-block .kf-login-buttons{display:flex;justify-content:space-between;flex-wrap:wrap;}
.sign-block .kf-login-buttons button.google-signup{max-width:152px;font-size:10px!important;}
.sign-block button.google-signup{display:block;width:100%;height:39px;font-family:"Arial"!important;font-weight:400;margin-top:10px;border-radius:10px!important;background:#fff!important;color:#707070!important;font-size:14px!important;line-height:16px;border:0!important;border:1px solid #707070!important;position:relative;-webkit-box-shadow:none!important;box-shadow:none!important;opacity:1!important;}
.sign-block button.google-signup:before{content:"\f1a0";display:inline;font:normal normal normal 14px/1 'FontAwesome';position:absolute;left:0;width:38px;height:100%;border-right:1px solid #707070;top:0;font-size:14px;line-height:38px;}
.sign-block button.apple-signup{display:block;width:100%;height:39px;font-family:"Arial"!important;font-weight:400;margin-top:10px;border-radius:10px!important;background:#fff!important;color:#707070!important;font-size:10px!important;line-height:16px;border:0!important;border:1px solid #707070!important;position:relative;-webkit-box-shadow:none!important;box-shadow:none!important;opacity:1!important;max-width:150px;padding:0;}
.sign-block button.apple-signup:before{content:"\f179";display:inline;font:normal normal normal 14px/1 'FontAwesome';position:absolute;left:0;width:38px;height:100%;border-right:1px solid #707070;top:0;font-size:14px;line-height:38px;}
.sign-block button.apple-signup div{position:absolute;width:100%;height:100%;top:0;left:0;right:0;bottom:0;opacity:0;}
.signModal .container .modal-dialog button.close{position:absolute;top:25px;right:25px;z-index:1;background-image:url('../assets/images/Vector.svg');background-repeat:no-repeat;height:18px;width:18px;}
.sign-block span.or + form{margin-top:20px;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row .left,
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row .right{width:50%;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row .right{text-align:right}
.sign-block button.signin-btn{margin:0;max-width:100%;display:inline-block;}
.sign-block .forgot-pwd{color:#707070;display:block;text-align:center;width:100%;font-family:"Arial";font-style:normal;font-weight:400;font-size:10px;line-height:11px;text-align:center;margin-bottom:4px;}
.sign-block .notice{color:red}
.signModal#forgotPwdModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row .right{width:100%}
.signModal#signinModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row label.form-check-label{margin-top:10px}
.signModal#resetModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input::-webkit-input-placeholder{color:#929292}
.signModal#resetModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input::-moz-placeholder{color:#929292}
.signModal#resetModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input:-ms-input-placeholder{color:#929292}
.signModal#resetModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input::-ms-input-placeholder{color:#929292}
.signModal#resetModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input::placeholder{color:#929292}
.signModal#resetModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row .right{width:100%}
.sign-block button.google-signup span{width:100%;padding:0!important;font-weight:normal!important;}
.sign-block button.google-signup>div,
footer ul li button.logout-foot>div{display:none;}
.sign-block .kf-login-buttons button.google-signup span, .sign-block .kf-login-buttons button.apple-signup span{padding:0 0 0 38px!important;width:100%;display:block;}
#signinModal h3{font-size:19px;line-height:24px;color:#707070;text-align:left;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block h3 span.account-link{display:inline-block;float:right;margin-top:0;color:#000;font-size:12px;line-height:24px;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block h3 span strong{font-weight:700;}
.signModal#forgotPwdModal h3,
.signModal#resetModal h3{font-size:19px;line-height:24px;color:#707070;text-align:left;}
.signModal#signinModal .container .modal-dialog .modal-content .modal-body .sign-block,
.signModal#forgotPwdModal .container .modal-dialog .modal-content .modal-body .sign-block,
.signModal#resetModal .container .modal-dialog .modal-content .modal-body .sign-block{max-width:313px;margin:0 auto;padding:57px 0px 70px;}
.signModal#signinModal .container .modal-dialog .modal-content .modal-body .sign-block h3 span strong a{color:#000;}
.signModal#signinModal .container .modal-dialog .modal-content .modal-body .sign-block h3 span strong a:hover{color:#000;}
.signModal#signinModal .sign-block span.or{margin-top:5px;}

/* common */
*{outline:none!important}
footer ul li button.logout-foot{color:#9c9c9c!important;font-size:20px!important;background:transparent!important;border:0!important;-webkit-box-shadow:none!important;box-shadow:none!important;font-family:"helvetica-normal"!important;}
footer ul li button.logout-foot:hover{color:#fff!important;}
footer ul li button.logout-foot> span{padding:0!important;font-weight:normal!important;}
body.nav-down .header{-webkit-transform:translate3d(0,-100%,0);transform:translate3d(0,-100%,0);}
body .header{top:0;z-index:999;-webkit-transition:-webkit-transform 0.4s;transition:-webkit-transform 0.4s;-o-transition:transform 0.4s;transition:transform 0.4s;transition:transform 0.4s, -webkit-transform 0.4s;transition:transform 0.4s,-webkit-transform 0.4s;position:fixed;right:0;right:0;width:100%;background:rgba(0,0,0,1);left:0;}
body.nav-up .header,
body.nav-down.fixed-header .header{-webkit-transform:none;-ms-transform:none;transform:none;}
body.nav-up.fixed-header .header{background:rgba(0,0,0,1);}
body.nav-up .header{background:rgba(0,0,0,0.8);}
body.home .header ,body.nav-up.fixed-header.home .header{ background: rgba(0,0,0,0.6); }
.errorClass p,
.userMsg p{color:red;font-family:"HelveticaNeueLTStd-Bd";text-align:left;padding-top:10px;}
.errorClass.hide{display:none;}
.signModal .container .modal-dialog .modal-content .modal-body .sign-block form.modal-box.error .form-row input,
.sign-block form.modal-box.error .form-row label.form-check-label:before{border-color:red;}
span.error{color:red;padding-top:5px;}
#resetModal .container .modal-dialog .modal-content .modal-body .sign-block .modal-box .form-row .left{width:100%}

/* Latest News Carousel */
.latestnews-wrapper{display:inline-block;width:100%;margin:78px 0 0;min-height:200px}
.latestnews-title{font-size:40px;margin-bottom:25px;font-family:'HelveticaNeueLTStd-Bd';}
.latestnews-wrapper .rcs_sliderSet{position:relative;}
.sneakernews-blk .slick-initialized .slick-slide{padding:0 15px;}
.latestnews-wrapper .slick-list{margin:0 -15px;}
.latestnews-blk-list p.post-detail{font-size:17px;line-height:30px;color:#707070;white-space:normal;}
.latestnews-wrapper .slick-prev,
.latestnews-wrapper .slick-next{-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);width:auto;height:auto;z-index:11;opacity:0;}
.latestnews-wrapper:hover .slick-prev,
.latestnews-wrapper:hover .slick-next{opacity:1;}
.latestnews-wrapper .slick-prev:before{content:"";background-image:url('../assets/images/left-arrow.svg');background-position:center;background-size:cover;background-repeat:no-repeat;height:40px;display:block;width:17px;opacity:0.4;}
.latestnews-wrapper .slick-prev{left:-35px}
.latestnews-wrapper .slick-next{right:-35px;}
.latestnews-wrapper .slick-next:before{content:"";background-image:url('../assets/images/rightt-arrow.svg');background-position:center;background-size:cover;background-repeat:no-repeat;height:40px;display:block;width:17px;opacity:0.4;}
.latestnews-blk-list p.post-detail span.author-name{color:#707070}
.latestnews-text-blk h5{font-size:23px;line-height:29px;font-family:'HelveticaNeueLTStd-Bd';}
.latestnews-text-blk h5 a{color:#000}
.latestnews-text-blk h5 a:hover{text-decoration:none;}
.ltst-news-blk-image{margin-bottom:23px!important;height:230px;overflow:hidden;}
.ltst-news-blk-image img{height:100%;width:100%;}
.latestnews-wrapper .slick-prev:hover:before,
.latestnews-wrapper .slick-prev:focus:before,
.latestnews-wrapper .slick-next:hover:before,
.latestnews-wrapper .slick-next:focus:before{opacity:0.4;}

/* User Setting */
.usersetting-wrapper{margin-bottom:55px;margin-top:145px;}
h4.user-title{font-size:36px;line-height:41px;font-family:'Arial';font-weight:700;margin-bottom:10px;color:#000;position:relative;padding-right:30px;}
h4.user-title .user-close-icon{position:absolute;right:0;cursor:pointer;}
.cst-select{border:1px solid black;padding:5px;width:90%;height:40px;border-radius:10px;outline:none;max-width:285px;margin:14px 0;}
.user-setting-row{border-top:1px solid #d7d7d7;padding:12px 0;line-height:1;}
.user-setting-row .row{-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.user-setting-row span.span-newsletter{font-size:15px;line-height:17.25px;font-family:'Arial';font-weight:400;color:#949494;padding:0 10px;}
span.user-set-span{font-size:15px;line-height:17.25px;font-family:'Arial';font-weight:400;padding:0 19px;color:#000;}
span.edittxt{color:#707070;font-size:15px;line-height:17.25px;font-family:'Arial';cursor:pointer;font-weight:400;}
label.user-set-lbl{font-size:15px;line-height:17.25px;margin-bottom:0;font-family:'Arial';font-weight:700;color:#000;}
.toggle-switch input[type=checkbox]{height:0;width:0;visibility:hidden;position:absolute;}
.toggle-switch label{cursor:pointer;text-indent:-9999px;background:#c4c4c4;display:block;border-radius:30px;position:relative;width:26px;height:10px;padding:2px;margin-bottom:0;}
.toggle-switch label:after{content:'';position:absolute;top:50%;left:0px;width:18px;height:18px;background:#fff;border-radius:30px;-webkit-transition:all .2s ease;-o-transition:all .2s ease;transition:all .2s ease;border:1px solid #707070;-webkit-transform:translate(0,-50%);-ms-transform:translate(0,-50%);transform:translate(0,-50%);}
.toggle-switch input:checked + label:after{left:calc(100% - 0px);-webkit-transform:translate(-100%,-50%);-ms-transform:translate(-100%,-50%);transform:translate(-100%,-50%);background:#000;}
.toggle-switch{text-align:right;float:right;display:inline-block;position:relative;padding:5px 0;}
.btn-setting{margin:5px 0 10px;background-color:#707070;color:#fff;font-size:17px;font-family:'Arial';font-weight:400;line-height:20px;border:1px solid #707070;border-radius:12px;padding:8px 30px;width:246px;}
.btn-setting:hover{color:#707070;background-color:transparent;-webkit-transition:all 0.2s ease;-o-transition:all 0.2s ease;transition:all 0.2s ease;}
.btn-setting.is-loading{padding:9px 30px 4px;}
.btn-logout{margin:5px 13px 10px 0;background-color:#fff;color:#707070;font-size:17px;font-family:'Arial';font-weight:400;line-height:20px;border:1px solid #707070;border-radius:12px;padding:8px 30px;width:130px;}
.btn-logout.is-loading{padding:9px 30px 4px;}
.user-setting-row .nav-link{padding:0;display:inline-block;}
.user-setting-row .logout-head{margin:5px 13px 10px 0;background-color:#fff!important;color:#707070!important;font-size:17px!important;font-family:'Arial'!important;font-weight:400;line-height:20px;border:1px solid #707070!important;border-radius:12px!important;padding:8px 30px!important;width:130px;box-shadow:none!important;display:inline-block!important;}
.user-setting-row .logout-head > div{display:none;}
.user-setting-row .logout-head > span{padding:0!important;}
.user-set-popup .modal-title h5{border-bottom:1px solid #dedede;font-size:20px;line-height:67px;font-family:'Arial';font-weight:700;padding:0 25px;margin-bottom:0;}
.user-set-popup .modal-title{position:relative;}
.user-set-popup .modal-body{padding:30px 25px}
.user-set-popup .modal-content{border:0;border-radius:15px;}
.user-set-popup .modal-title button.close{position:absolute;top:12px;right:25px;font-size:35px;font-weight:normal;opacity:1;}
.user-set-popup-block .form-row{margin:0 0 15px}
.user-set-popup-block .form-row label{display:block;width:100%;font-size:14px;margin-bottom:0;font-family:'Arial';font-weight:400;color:#000;}
.user-set-popup-block .button-row{padding:0}
.user-set-popup-block .txt-cntrl{width:100%;height:46px;border-radius:0;padding:0 10px;-webkit-box-shadow:none;box-shadow:none;color:#757575;background:#f1f1f1;font-size:13px;line-height:24px;font-family:'Arial';font-weight:400;}
.user-set-popup-block .btn{color:#fff;border:0;font-size:14px;font-family:'Arial';font-weight:700;line-height:24px;border-radius:10px;padding:5px 30px;width:125px;margin:20px 5px 0;}
.user-set-popup-block .cancel-btn{background-color:#888888;}
.user-set-popup-block .save-btn{background-color:#000;}
.user-set-popup .modal-dialog{margin-top:15%;}
.user-set-popup .errorMsg .alert-icon,
.errorMsg .alert-icon{height:12px;width:12px;border-radius:50%;background-color:#ed1c24;color:#fff;font-size:10px;text-align:center;vertical-align:middle;line-height:12px;margin-top:-2px;margin-right:3px;}
.user-set-popup .errorMsg,
.errorMsg{color:#ed1c24;font-size:12px;padding:0 5px}
.no-search-found{min-height:600px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;}
.search-found-blk{margin:20px 0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;}
.search-alert-icon{margin:0 20px 0 0;}
.search-alert-icon img{width:100%}
.search-txt h5{font-size:34px;line-height:39px;color:#000;margin-bottom:20px;font-family:'Arial';font-weight:700;}
.search-txt p{font-size:23px;line-height:26px;color:#707070;margin-bottom:0;font-family:'Arial';font-weight:400;}
button.fullbtn.view-more.is-loading{cursor:default;pointer-events:none;background:#d3d3d3;font-size:0;}
button.fullbtn.view-more .loadicon{display:none;}
button.fullbtn.view-more.is-loading .loadicon{display:inline-block;-webkit-animation:spin 1s linear infinite;animation:spin 1s linear infinite;font-size:18px;color:#000;}
span.search-error-msg{position:absolute;bottom:-25px;font-size:17px;left:15px;color:red;}
button.is-loading{cursor:default;pointer-events:none;background:#D3D3D3;font-size:0;}
button .loadicon{display:none;}
button.is-loading .loadicon{display:inline-block;-webkit-animation:spin 1s linear infinite;animation:spin 1s linear infinite;font-size:18px;color:#000;margin-left:10px;}
@-webkit-keyframes spin{
	0%{-webkit-transform:rotate(0deg);}
	100%{-webkit-transform:rotate(360deg);}
}
@keyframes spin{
	0%{-webkit-transform:rotate(0deg);transform:rotate(0deg);}
	100%{-webkit-transform:rotate(360deg);transform:rotate(360deg);}
}
button.btn.btn-close i.fa.fa-close{color:#bdbbbc;}
.product-item span.just-added{background:#000;padding:3px 12px;font-size:16px;color:#fff;position:absolute;left:0;top:0;}
.product-item{position:relative;}
.product-img{display:block;}
.modal-popup-open{overflow:hidden;}
.modal-popup-open .modal{overflow-x:hidden;overflow-y:auto;}
span.btn-close{background-image:url('../assets/images/closeicon-white.svg');height:20px;width:20px;display:inline-block;padding:0;border:0;position:absolute;top:10px;right:19px;outline:none;-webkit-box-shadow:none;box-shadow:none;background-repeat:no-repeat;}
.userResetMsg p{display:inline-block;width:100%;background-color:rgba(0,128,0,0.23);padding:8px 15px;border-radius:12px;margin:30px 0 15px;-webkit-transition:.3s all ease-in-out;-o-transition:.3s all ease-in-out;transition:.3s all ease-in-out;color:green;font-weight:600;font-family:"HelveticaNeueLTStd-Bd";text-align:center;font-size:16px;}
.userResetMsg span{display:inline-block;width:100%;font-family:"HelveticaNeueLTStd-Bd";text-align:center;font-size:16px;}
.userResetMsg .forgot-pwd{color:#007bff;font-family:"HelveticaNeueLTStd-Lt";}
.red{color:red!important;}
.text-left{float:left;}
.profileUpdatemsg p{color:green!important;}
.success-icon{color:green;font-size:17px;text-align:center;vertical-align:middle;line-height:12px;margin-top:-2px;margin-right:3px;}
button.is-loading{cursor:default;pointer-events:none;background:#D3D3D3!important;font-size:0;color:#000!important;}
button .loadicon{display:none;}
button.is-loading .loadicon{display:inline-block;-webkit-animation:spin 1s linear infinite;animation:spin 1s linear infinite;font-size:18px;color:#000!important;}
.form-row .errorMsg{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1;}
.form-row .errorMsg + input{border-color:#ed1c24!important;}
.profileUpdatemsg{display:inline-block;width:100%;background-color:rgba(0,128,0,0.23);padding:8px 15px;border-radius:12px;margin:10px 0;-webkit-transition:.3s all ease-in-out;-o-transition:.3s all ease-in-out;transition:.3s all ease-in-out;}
.profileUpdatemsg p{padding:0;margin:0;text-align:center;}
.userSignUpMsg{display:inline-block;width:100%;background-color:rgba(0,128,0,0.23);padding:8px 15px;border-radius:12px;margin:30px 0;-webkit-transition:.3s all ease-in-out;-o-transition:.3s all ease-in-out;transition:.3s all ease-in-out;color:green;font-weight:600;font-family:"HelveticaNeueLTStd-Bd";text-align:center;}
.sidebar .sidebox.by-brand ul li.active span:after{content:"";display:inline-block;background-image:url('../assets/images/cross-close.svg');height:15px;width:15px;position:absolute;background-repeat:no-repeat;background-size:100%;margin:6px;}
span.deletetxt{color:#ba0000;font-size:16px;vertical-align: middle;line-height:17.25px;font-family:'Arial';cursor:pointer;font-weight:400;}
.delete-trash{color:#ba0000;padding-right: 5px;}
.delete-popup  .form-row {width: 100%;}
.user-set-popup-block.delete-popup p {font-size:22px;width:100%;font-family:"BebasNeue-Regular";margin-bottom: 5px;}
.user-set-popup-block.delete-popup span {font-size: 13px;color:#ba0000;}
.user-set-popup-block button.btn.is-loading {font-size: 0;padding: 9px 30px 4px;line-height: 15px;}
.user-set-popup-block button.btn.is-loading:focus{box-shadow: none;}

/*About Page*/
.about-page .cms-page-title{word-break:break-word;}
.about-page .cms-cnt-txt{padding:112px 0 100px;}
.by-release.sidebox.disabled ul li,
.archive.sidebox.disabled ul li{pointer-events:none;color:#e3e3e3;}
.by-release.sidebox.disabled ul li a,
.archive.sidebox.disabled ul li a{color:#999;font-weight:normal;}

/* CMS Pages */
.cms-page{min-height:calc(100vh - 300px);}
.cms-column-layout .cms-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin:0 -15px;}
.cms-column-layout .cms-row .cms-col{padding:0 15px;}
.cms-column-layout .cms-row .cms-col:first-child{width:30%;}
.cms-column-layout .cms-row .cms-col:last-child{width:70%;}
.about-page.cms-column-layout .cms-row .cms-col:first-child{width:26%;}
.about-page.cms-column-layout .cms-row .cms-col:last-child{width:74%;}
.cms-img-conatiner{margin:60px 0;}
.cms-cnt-wrapper{margin:80px 0 80px;}
.cms-page-title{font-size:66px;line-height:69px;text-transform:uppercase;font-family:"BebasNeue-Regular";}
.cms-page:not(.about-page) .cms-page-title{text-align:center;margin:70px 0;}
.cms-cnt-txt p{font-size:28px;line-height:45px;font-family:'Arial';font-weight:400;margin-bottom:50px;}
.cms-cnt-txt p + ul,
.cms-cnt-txt p + ol{margin-top:-50px;}
.cms-page hr{border-top-color:#000;margin:70px 0 30px;}
.cms-page h3{margin-bottom:10px;font-size:40px;line-height:45px;font-family:'Arial';font-weight:400;}
.cms-page h2{margin-bottom:20px;font-family:"BebasNeue-Regular";font-size:66px;line-height:69px;text-transform:uppercase;}
.cms-page ol,
.cms-page ul{margin-bottom:50px;padding:0;}
.cms-page ol li,
.cms-page ul li{font-size:28px;line-height:45px;font-family:'Arial';font-weight:400;}
.cms-page ul li{list-style-type:none;position:relative;padding-left:20px;}
.cms-page ul li:before{content:'-';display:inline-block;position:absolute;left:0;top:20px;line-height:2px;}

/* 404 page */
.cnt-wrapper-404{margin:80px 0 0;}
.top-wrap-404{margin:0 0 70px;}
.top-wrap-404>.row{-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.label-404{font-size:280px;line-height:270px;text-transform:uppercase;font-family:"BebasNeue-Regular";word-break:break-word;display:block;text-align:center;}
.title-404{font-size:45px;line-height:55px;color:#717171;margin-top:85px;}
.search-wrap-404 .search-404-control input{font-size:40px;line-height:24px;color:#bdbbbc;font-family:"HelveticaNeueLTStd-It";border:0;border-radius:0;border-bottom:1px solid #848484}
.search-wrap-404 .search-404-control input::-webkit-input-placeholder{color:#bdbbbc;}
.search-wrap-404 .search-404-control input::-moz-placeholder{color:#bdbbbc;}
.search-wrap-404 .search-404-control input:-ms-input-placeholder{color:#bdbbbc;}
.search-wrap-404 .search-404-control input::-ms-input-placeholder{color:#bdbbbc;}
.search-wrap-404 .search-404-control input::placeholder{color:#bdbbbc;}
.search-wrap-404 .search-404-control input:focus{outline:none;-webkit-box-shadow:none;box-shadow:none;}
.search-404-control button.btn.btn-search{position:absolute;right:0;top:20px;}
.search-404-control{position:relative;}
.search-404-control button.btn.btn-search i{font-size:30px;color:#bdbbbc}
.search-product-listing{width:100%;display:block;}
.search-below-cnt{margin-top:180px;padding-top:20px;padding-bottom:60px;background-color:#f8f8f8;position:relative;width:100%;display:inline-block;}
.search-below-cnt:before,
.search-below-cnt:after{background:#f8f8f8;content:"";display:inline-block;height:100%;width:49vw;position:absolute;top:0;z-index:-1;}
.search-below-cnt:before{left:50%;-webkit-box-shadow:50px 0 #f8f8f8;box-shadow:50px 0 #f8f8f8;}
.search-below-cnt:after{right:50%;-webkit-box-shadow:-50px 0 #f8f8f8;box-shadow:-50px 0 #f8f8f8;}
h4.search-title{display:block;width:100%;padding:0 15px;font-size:40px;font-family:"HelveticaNeueLTStd-Bd";margin-bottom:30px}
.search-more-btn button.fullbtn.view-more{font-size:22px;margin-bottom:30px;}
::-webkit-search-cancel-button{-webkit-appearance:none;}
.user-set-popup-block h2{font-size:40px;margin-top:10px;margin-bottom:20px;}
.user-set-popup-block p{font-size:18px;}
.user-set-popup-block p > span{cursor:pointer;text-decoration:underline;color:#2184ff}
.user-set-popup-block .profileUpdatemsg{width:100%}

/* product list */
.allproducts a.product-img{display:block;overflow:hidden;position:relative;background-repeat:no-repeat;background-position:center top;background-size:cover;background-blend-mode:luminosity;background-color:rgba(113,113,113,0.09);margin:0 auto;filter:alpha(opacity=0);-khtml-opacity:0;-moz-opacity:0;opacity:1;-webkit-transition:all .3s ease-in;-o-transition:all .3s ease-in;transition:all .3s ease-in;}
.allproducts a.product-img:after{display:block;position:absolute;top:0;left:0;right:0;bottom:0;background-color:rgba(113,113,113,0.09);filter:alpha(opacity=0);-khtml-opacity:0;-moz-opacity:0;opacity:0;-webkit-transition:opacity .3s ease-in;-o-transition:opacity .3s ease-in;transition:opacity .3s ease-in;pointer-events:none;content:'';z-index:1;}
.latestnews-blk-list .ltst-news-blk-image{display:block;position:relative;background-repeat:no-repeat;background-position:center top;background-size:cover;background-blend-mode:luminosity;background-color:rgba(113,113,113,0.09);margin:0 auto;filter:alpha(opacity=0);-khtml-opacity:0;-moz-opacity:0;opacity:1;-webkit-transition:all .3s ease-in;-o-transition:all .3s ease-in;transition:all .3s ease-in;}
.latestnews-blk-list .ltst-news-blk-image img:after{display:block;position:absolute;top:0;left:0;right:0;bottom:0;background-color:rgba(113,113,113,0.09);filter:alpha(opacity=0);-khtml-opacity:0;-moz-opacity:0;opacity:0;-webkit-transition:opacity .3s ease-in;-o-transition:opacity .3s ease-in;transition:opacity .3s ease-in;pointer-events:none;content:'';z-index:1;}
.allproducts a.product-img img,
.banner-carousel .carousel-item img,
.latestnews-blk-list .ltst-news-blk-image img,
.productdetail-cnt .slider-img-cnt-blk img{filter:alpha(opacity=0);-khtml-opacity:0;-moz-opacity:0;opacity:0;-webkit-transition:opacity 0.4s ease-in;-o-transition:opacity 0.4s ease-in;transition:opacity 0.4s ease-in}
.allproducts a.product-img img.loaded,
.banner-carousel .carousel-item img.loaded,
.latestnews-blk-list .ltst-news-blk-image img.loaded,
.productdetail-cnt .slider-img-cnt-blk img.loaded{filter:alpha(opacity=0.99);-khtml-opacity:0.99;-moz-opacity:0.99;opacity:0.99}
.button-container{padding:0 15px;position:relative;z-index:998;}

/*Product Detail*/
.kf-warning{padding:28px 30px 28px 70px;border-radius:20px;background-color:#FFF0D3;width:100%;font-size:16px;line-height:18px;margin:15px 0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-ms-flex-wrap:wrap;flex-wrap:wrap;position:relative;}
.kf-warning i{position:absolute;left:25px;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);}
.kf-warning b{margin-right:10px;}
.btn-primary-gray{margin:6px 0;background-color:#707070;color:#fff;font-size:17px;font-family:'Arial';font-weight:400;line-height:20px;border:1px solid #707070;border-radius:12px;padding:8px 20px;width:100%;min-height:38px}
.btn-primary-gray:hover{color:#707070;background-color:transparent;-webkit-transition:all 0.2s ease;-o-transition:all 0.2s ease;transition:all 0.2s ease;}
.btn-primary-gray i:not(.loadicon){margin-right:5px;color:inherit;}
.btn-primary-gray i.fire svg{height:18px;width:14px;}
.btn-primary-gray i path{stroke:#fff;}
.btn-primary-gray:hover i path{stroke:#707070;}
.btn-primary-gray.active{background-color:transparent;color:#707070}
.btn-primary-gray.active i path{stroke:#707070}
.btn-primary-gray.is-loading i:not(.loadicon){display:none;}
.btn-primary-gray.is-loading .loadicon{margin:0}
.productdetail-main-blk{margin:110px 0 82px}
.productdetail-main-blk .slider-cnt{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:16px;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;}
.productdetail-main-blk .slider-cnt .col-slide-left{width:calc(100% - 505px);display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;padding-right:15px;position:relative;}
.productdetail-main-blk .slider-cnt .col-slide-left .slick-slider{width:100%}
.productdetail-main-blk .slider-cnt .col-slide-left .zoom-img-icon{position:absolute;right:20px;bottom:43px;cursor:pointer;}
.productdetail-main-blk .slider-cnt .col-slide-left .slide-wrapper .slider-img-cnt-blk img{cursor:pointer;}
.productdetail-main-blk .slider-cnt .col-slide-right{width:505px;padding-left:15px;}
.productdetail-main-blk .slider-cnt .col-slide-right .prod-info-count{margin-bottom:8px;}
.prod-rank-wrp{margin:0 0 10px;}
.prod-rank-wrp .prod-rank-item{border:1px solid #707070;border-radius:15px;font-size:16px;line-height:18px;color:#707070;width:124px;height:28px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin:0;}
.prod-rank-wrp .prod-rank-status{margin-right:10px;}
.prod-rank-wrp .prod-rank-num{color:#000;}
.prod-rank-wrp .prod-rank-num i{font-size:18px;margin:0 5px 0 0;line-height:10px;}
.prod-rank-wrp .prod-rank-num.prod-rank-positive{color:#8DC63F;}
.prod-rank-wrp .prod-rank-num.prod-rank-negative{color:#C90505;}
.prod-rank-wrp .prod-rank-num.prod-rank-negative .fa-caret-up:before{content:"\f0d7"}
.prod-rank-wrp .prod-rank-new{border:1px solid #AD05C9;border-radius:9px;font-size:11px;line-height:15px;color:#AD05C9;text-transform:uppercase;width:36px;height:16px;display:inline-block;text-align:center;font-weight:700}
.productdetail-main-blk .slider-cnt .prod-count-release{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;margin:10px 0 32px;}
.productdetail-main-blk .slide-title h4{font-size:35px;line-height:40px;text-transform:capitalize;font-family:'Arial';font-weight:700;}
.productdetail-main-blk .slide-title .title-nickname{font-family:'Arial';font-weight:400;display:block;}
.productdetail-main-blk .slide-title .color-text{font-weight:400;font-size:14px;line-height:1;display:block;margin-top:6px;text-transform:uppercase;}
.productdetail-main-blk .release-day span{font-size:20px;font-weight:700;font-family:"arial";color:#9c9c9c;}
.productdetail-main-blk .slider-cnt ul.info-count{list-style:none;padding:0;margin:0 0 0 5px;min-width:100px;}
.productdetail-main-blk .slider-cnt ul.info-count li{font-size:20px;color:#b4b4b4;margin-bottom:5px;text-align:right;line-height:23px;}
.productdetail-main-blk .slider-cnt ul.info-count li:last-child{margin-bottom:0;}
.productdetail-main-blk span.number{font-size:20px;color:#707070;}
.productdetail-main-blk .slider-cnt ul.info-count li[title="Watchlist"] i{font-size:16px;margin-right:5px;}
.productdetail-main-blk .slider-cnt ul.info-count i.eye-icon{vertical-align:text-bottom;margin-right:5px;}
.productdetail-main-blk span.release-date-txt{font-size:20px;}
.productdetail-main-blk .release-date{font-size:20px;line-height:23px;font-family:'Arial';font-weight:700;color:#000;}
.productdetail-main-blk span.rel-time{display:block;font-family:'Arial';font-weight:400;margin-top:5px;}
.prod-footer-cnt .product-list-header{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:14px;}
.prod-footer-cnt .product-list-header .prod-list-title-wrap{margin-bottom:0;}
.prod-sku-table table{width:100%;margin-bottom:10px;}
.prod-sku-table th{color:#707070;font-size:17px;line-height:20px;padding:13px 0;font-weight:normal;}
.prod-sku-table td{font-size:19px;line-height:22px;color:#000;padding:21px 0 20px;font-weight:normal;}
.prod-sku-table th:nth-child(1),
.prod-sku-table td:nth-child(1){text-align:left;width:40%;}
.prod-sku-table th:nth-child(2),
.prod-sku-table td:nth-child(2){text-align:center;width:30%;}
.prod-sku-table th:nth-child(3),
.prod-sku-table td:nth-child(3){text-align:right;width:30%;}
.prod-sku-table tr{border-bottom:1px solid #d7d7d7;}
.prod-sku-table td .copysku{margin-left:5px;cursor:pointer;position:relative;margin-right:-15px;}
.prod-sku-table td span.copied-clipboard{background:#fff;color:#000;border:1px solid #000;padding:.35rem .5rem;border-radius:.25rem;top:-37px;left:50%;position:absolute;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%);width:60px;text-align:center;font-style:normal;font-size:12px;line-height:normal;-webkit-animation:tooltip 300ms;animation:tooltip 300ms;}
.prod-sku-table td span.copied-clipboard .arrow{position:absolute;display:block;width:.8rem;height:.4rem;bottom:-.37rem;left:50%;transform:translate(-50%,0);}
.prod-sku-table td span.copied-clipboard .arrow:before,
.prod-sku-table td span.copied-clipboard .arrow:after{left:50%;border:solid transparent;content:" ";height:0;width:0;position:absolute;pointer-events:none;border-color:rgba(194,225,245,0);border-top-color:#000000;border-width:9px;margin-left:-9px;}
.prod-sku-table td span.copied-clipboard .arrow:after{border-color:rgba(136,183,213,0);border-top-color:#ffffff;border-width:8px;margin-left:-8px;}

@-webkit-keyframes tooltip{
	from{top:-44px;}
	to{top:-37px;}
}
@keyframes tooltip{
	from{top:-44px;}
	to{top:-37px;}
}
.prodcolor-blk p{font-size:18px;font-family:"helvetica-normal";margin-bottom:0;}
.prodcolor-blk{margin-bottom:40px;}
.post-disclamier-blk span{width:100%;display:inline-block;vertical-align:middle;font-size:16px;line-height:18px;color:#707070;font-family:'Arial';font-weight:400;}
.moreinfo-blk{margin-top:10px;}
.post-disclamier-blk{margin:14px 0 9px;font-style:italic;}
.post-sn-disclamier-blk{margin:14px 0 9px;}
.post-sn-disclamier-blk a{color:#000;text-decoration:underline;}
.post-sn-disclamier-blk a:hover{text-decoration:none;}
.moreinfo-blk img{max-width:130px;vertical-align:text-top;}
.prod-below-cnt{margin-top:50px;}
.moreinfo-blk span{font-size:20px;font-family:"helvetica-normal";}
.moreinfo-blk a.prod-img-link{color:#000;font-weight:700;text-decoration:none;border-bottom:2px solid #b4b4b4;}
.timezone-select{text-align:right;position:relative;}
.timezone-select .cst-select{border-color:#dcdcdc;padding:3px 30px 3px 20px;margin:0;width:100%;-webkit-appearance:none;-ms-appearance:none;-moz-appearance:none;appearance:none;z-index:10;background-color:transparent;cursor:pointer;position:relative;max-width:264px;height:33px;border-radius:5px;font-size:15px;line-height:16px;}
.timezone-select::after{content:"";display:inline-block;position:absolute;right:15px;top:50%;z-index:5;background-image:url(../assets/images/sort-icon.svg);width:10px;height:17px;transform:translateY(-50%);}
.prod-cat-row{margin:0 0 100px;}
.prod-cat-row:last-child{margin:0 0 40px;}
.prod-cat-row .prod-row-header{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-ms-flex-wrap:wrap;flex-wrap:wrap;margin:0 0 25px;}
.prod-cat-row .prod-head-info{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;}
.prod-cat-row .prod-head-info .prod-head-collout{padding:15px 40px 15px 60px;border-radius:15px;background:#d7d7d7;font-size:17px;color:#000;position:relative;}
.prod-cat-row .prod-head-info .prod-head-collout a{color:#000;text-decoration:underline;}
.prod-cat-row .prod-head-info .prod-head-collout i{position:absolute;left:18px;top:50%;transform:translateY(-50%);}
.prod-cat-row .prod-cat-body .retailer-not-found{display:flex;justify-content:center;align-items:center;font-size:27px;color:#707070;min-height:160px;text-align:center;}
table.prod-table{width:100%;border-spacing:0;display:inline-block;}
table.prod-table thead,
table.prod-table tbody{width:100%;border-spacing:0;display:inline-block;}
table.prod-table thead th{font-size:18px;font-weight:normal;color:#707070;line-height:20px;}
table.prod-table tr{border-bottom:1px solid #d7d7d7;}
table.prod-table tbody tr td{font-size:18px;line-height:20px;}
table.prod-table tr{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;border-spacing:0;}
table.prod-table tbody tr td,
table.prod-table thead tr th{min-height:56px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;padding:0 15px 0;text-align:center;}
table.prod-table thead tr th{min-height:48px;}
table.prod-table tbody tr{border-bottom:1px solid #ddd;}
table.prod-table tbody tr td b{font-family:'Arial';font-weight:400;}
table.prod-table tbody tr td.retailer-col i.fa.fa-external-link{font-size:13px;padding-left:5px;vertical-align:middle;color:#9b9b9b;}
table.prod-table tbody tr td.retailer-col,
table.prod-table thead tr th.retailer-col{width:18%;text-align:left;padding:0 15px 0 0;}
table.prod-table tbody tr td.time-col,
table.prod-table thead tr th.time-col{width:13%}
table.prod-table tbody tr td.ships-col,
table.prod-table thead tr th.ships-col{width:18%}
table.prod-table tbody tr td.release-type,
table.prod-table thead tr th.release-type{width:16%}
table.prod-table tbody tr td.btn-col,
table.prod-table thead tr th.btn-col{width:18%}
table.prod-table tbody tr td.reminder-col,
table.prod-table thead tr th.reminder-col{padding:0 0 0 15px;width:17%;}
table.prod-table tbody tr td a.btn{font-size:15px;background-color:transparent;color:#fff;border-radius:5px;padding:3px 10px;display:inline-block;line-height:17px;width:auto;border:1px solid #707070}
table.prod-table tbody tr td a{color:inherit;text-decoration:none;}
table.prod-table tbody tr td a:hover{text-decoration:none;}
table.prod-table tbody tr td.retailer-col span{font-family:'Arial';font-weight:700;}
table.prod-table tbody tr td a.btn-grey{background-color:#9c9c9c;}
table.prod-table tbody tr td a.btn-black{background-color:#000;}
.ad-cnt-blk{background-color:#f5f5f5;text-align:center;width:100%;display:inline-block;padding:35px 0px;margin:40px 0;position:relative;}
.ad-cnt-blk:before,
.ad-cnt-blk:after{background:#f5f5f5;content:"";display:inline-block;height:100%;width:49vw;position:absolute;top:0;z-index:-1;}
.ad-cnt-blk:before{left:50%;-webkit-box-shadow:50px 0 #f5f5f5;box-shadow:50px 0 #f5f5f5;}
.ad-cnt-blk:after{right:50%;-webkit-box-shadow:50px 0 #f5f5f5;box-shadow:50px 0 #f5f5f5;}
.prod-footer-cnt .latestnews-wrapper + .latestnews-wrapper{margin-top:10px;margin-bottom:0;}
.productdetail-main-blk ul.info-count i.fire{vertical-align:text-bottom;}
.main-title{display:block;width:100%;padding:0;font-size:32px;font-family:'Arial';margin-bottom:30px;font-weight:700;}
.release-soon-blk{margin-top:20px;}
.productdetail-main-blk .slider-caption{position:absolute;bottom:10px;right:10px;background-color:rgba(0,0,0,0.25);}
.productdetail-main-blk .slider-caption span{color:#fff;z-index:999999;padding:10px;font-size:20px;font-family:'Arial';}
.productdetail-main-blk .slider-caption span.slide-item-number{font-family:'Arial';padding:0;font-weight:700;}
.productdetail-main-blk .slider-img-cnt{position:relative;width:50%;padding:0 7px;margin-bottom:14px;}
.productdetail-main-blk .slider-img-cnt.hide-slide-blk{display:none;}
.prod-restock-date .release-date{margin-bottom:0;font-size:26px;}
.prod-restock-date{margin-bottom:20px;}
.productdetail-main-blk .slider-cnt .slick-next::before{content:"";background-image:url('../assets/images/mainslider-arrow.svg');background-position:center;background-size:cover;background-repeat:no-repeat;height:35px;display:block;width:20px;opacity:1;}
.productdetail-main-blk .slider-cnt .slick-prev{left:10px;z-index:989;height:35px}
.productdetail-main-blk .slider-cnt .slick-next{-webkit-transform:rotate(-180deg);-ms-transform:rotate(-180deg);transform:rotate(-180deg);margin-top:-17px;right:10px;height:35px}
.productdetail-main-blk .slider-cnt .slick-prev::before{content:"";background-image:url('../assets/images/mainslider-arrow.svg');background-position:center;background-size:cover;background-repeat:no-repeat;height:35px;display:block;width:20px;opacity:1;}
.productdetail-main-blk .prod-footer-cnt .latestnews-wrapper{margin-top:40px;}
.productdetail-main-blk .release-soon-blk{margin-top:50px}
.productdetail-main-blk .prod-footer-cnt .latestnews-title{display:inline-block;}
.productdetail-main-blk .prod-footer-cnt span.news-viewmore-btn{padding:2px 15px;border:1px solid #9c9c9c;vertical-align:text-top;border-radius:20px;line-height:normal;margin-left:10px;display:inline-block;margin-top:0}
.productdetail-main-blk .prod-footer-cnt span.news-viewmore-btn a{font-size:12px;font-family:"helvetica-normal";color:#9c9c9c;text-transform:uppercase;}
.productdetail-main-blk .prod-footer-cnt .slick-slider .product-item img{max-width:100%;width:100%;}
.productdetail-main-blk h4{font-size:34px;line-height:39px;}
.productdetail-main-blk h4.main-title{margin-bottom:23px;}
.productdetail-cnt .product-item i.eye-icon svg{margin-top:-5px}
.productdetail-cnt .product-item li[title="Views"] i{font-size:21px;}
.productdetail-cnt .product-item li[title="Likes"] i:hover svg path,
.productdetail-cnt .product-item li[title="Likes"].active i svg path{fill:#ea953d;stroke:#ea953d;}
.productdetail-cnt .product-item li[title="Watchlist"] i:hover,
.productdetail-cnt .product-item li[title="Watchlist"].active i{color:#000;}
.productdetail-cnt .product-item li[title="Watchlist"] i:hover:before,
.productdetail-cnt .product-item li[title="Watchlist"].active i:before{content:'\f02e';}
.productdetail-cnt .prod-counrywise-tbl:not(:last-child){margin-bottom:70px;}
.productdetail-main-blk .slider-cnt ul.info-count i.fire{margin-top:-6px;vertical-align:middle;display:inline-block;margin-right:5px}
.productdetail-main-blk .slider-cnt ul.info-count i.fire svg{width:14px;height:18px;}
.productdetail-cnt .slider-cnt ul.info-count li[title="Likes"] i:hover svg path,
.productdetail-cnt .slider-cnt ul.info-count li[title="Likes"].active i svg path{fill:#ea953d;stroke:#ea953d;}
.productdetail-cnt .slider-cnt ul.info-count li[title="Watchlist"] i:hover,
.productdetail-cnt .slider-cnt ul.info-count li[title="Watchlist"].active i{color:#000;}
.productdetail-cnt .slider-cnt ul.info-count li[title="Watchlist"] i:hover:before,
.productdetail-cnt .slider-cnt ul.info-count li[title="Watchlist"].active i:before{content:'\f02e';}
table.prod-table tbody tr td.retailer-col span.external-link img{width:13px;margin-left:5px;height:13px;}
.img-scrollar-active-body{overflow:hidden;}
.img-scrollar-active-body .header{background:rgba(0,0,0,1)!important;transform:none!important;}
.img-scrollar-popup{position:fixed;top:0;left:0;right:0;bottom:0;z-index:998;background-color:#fff;display:none;overflow:auto;width:100%;height:100%;padding:80px 0 20px}
.img-scrollar-popup.img-scrollar-active{display:block;}
.img-scrollar-popup .slider-img-cnt{width:100%;margin:0 0 10px;padding:0;}
.img-scrollar-popup .slider-img-cnt img{max-width:100%;margin:0 auto}
.img-scrollar-popup .close-img-scrollar{cursor:pointer;display:inline-block;position:fixed;top:100px;right:50px;z-index:997;}

/* Watchlist */
.watchlist-not-found{height:600px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.watchlist-not-found .wtch-not-fount-box{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;width:940px;max-width:76%;}
.watchlist-not-found .wtch-col-img{width:120px;}
.watchlist-not-found .wtch-col-content{width:calc(100% - 120px);padding-left:30px;}
.watchlist-not-found .wtch-col-content h3{font-size:34px;line-height:39px;font-family:'Arial';font-weight:700;margin-bottom: 18px;}
.watchlist-not-found .wtch-col-content p{font-size:23px;line-height:26px;margin-bottom:23px;color:#707070;}
.watchlist-not-found .wtch-col-content p b a{color:#000;}
.watchlist-not-found .wtch-col-content p b a:hover{color:#000}
.watchlist-not-found .wtch-col-img .info-count{padding:0;margin:0;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;list-style:none;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;height:100%;position:relative;}
.watchlist-not-found .info-count li{color:#707070;position:relative;width:100%;text-align:right;font-size:34px;line-height:39px;}
.watchlist-not-found .info-count li .bookmark-icon{position:relative;}
.watchlist-not-found .info-count li .bookmark-icon:before{content:'';position:absolute;top:-8px;left:-20px;width:58px;height:58px;border-radius:50px;border:5px solid #f90000;}
.watchlist-not-found .allproducts li .product-item .prod-details .prod-name,
.watchlist-not-found .allproducts li .product-item .prod-details .prod-name a,
.watchlist-not-found .allproducts li .product-item .prod-details .prod-price{color:rgba(0,0,0,0.3);}
.watchlist-wrap{margin-top:20px}
.watchlist-wrap .container{padding:0 50px;}
.watchlist-wrap .allproducts:empty{margin-bottom:0;}
.watchlist-wrap .product-main-list-blk .product-list-header{margin:0 0 30px;padding:0 0 30px;border-bottom:1px solid #d7d7d7;}
.watchlist-wrap .large-main-title{font-size:59px;line-height:69px;}
.productdetail-main-blk .latestnews-wrapper ul.info-count i.fire,
.productdetail-main-blk .release-soon-blk ul.info-count i.fire{vertical-align:initial;}
.allproducts li .product-item .infos .info-count li i:not(.eye-icon),
.productdetail-cnt .product-item .infos .info-count li i:not(.eye-icon){cursor:pointer}
.productdetail-main-blk .slider-cnt ul.info-count li i:not(.eye-icon){cursor:pointer}
table.prod-table tbody tr td.reminder-col span{cursor:pointer;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
table.prod-table tbody tr td.reminder-col span span{border-radius:15px;font-size:12px;line-height:1;height:20px;margin:0 6px;}
table.prod-table tbody tr td.reminder-col .google-cal{border: 1px solid #EAB73F;width:74px;color:#2D66C4}
table.prod-table tbody tr td.reminder-col .google-cal i{font-style:normal;margin-left:3px;color:#55A059}
table.prod-table tbody tr td.reminder-col .ical{border:1px solid #707070;width:44px;}
table.prod-table tbody tr td.reminder-col .ical i{margin-right:2px;line-height:9px;}
.latestnews-blk-list p.post-detail a:hover{text-decoration:none;}
.productdetail-main-blk .prod-footer-cnt span.news-viewmore-btn a:hover{text-decoration:none;font-weight:700}
.modal-popup-open .modal#forgotPwdModal .container .modal-dialog .modal-content .modal-body{-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.signModal#resetModal .container .modal-dialog .modal-content .modal-body{-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.signModal .container .modal-dialog button.close{opacity:1;}
.latestnews-wrapper .slick-track{margin-left:0;margin-right:0;}
.user-setting-select{position:relative;margin:5px 0;max-width:285px;float:right;}
.user-setting-select select.cst-select{-webkit-appearance:none;-moz-appearance:none;appearance:none;background-color:transparent;padding:5px 40px 5px 10px;z-index:10;cursor:pointer;position:relative;width:100%;margin:0}
.user-setting-select:after{content:"\f0d7";font-size:22px;color:#000;display:inline-block;font-family:"FontAwesome";position:absolute;right:15px;top:50%;z-index:5;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);}
.productdetail-main-blk .you-may-slider .product-img img{width:100%;height:100%;object-fit:cover;object-position:center;min-height:200px;max-height:200px;}
table.prod-table tbody tr td span > span.text-center{display:inline-block;width:100%;text-align:center;}
h6.notification-bar{position:absolute;top:150px;width:100%;text-align:center;text-transform:uppercase;z-index:99;background:rgba(0,255,0,0.56);color:#fff;font-size:36px;line-height:41px;padding:11px 10px;font-family:"BebasNeue-Regular";}
.pointer-cursor{cursor:pointer;}
h4.cntry-title{font-size:27px;font-family:'Arial';line-height:31px;margin-bottom:15px;color:#030303;font-weight:700;}
h4.cntry-title span{font-family:'Arial';color:#707070;vertical-align:text-top;display:inline-block;margin-right:5px;font-weight:400;}
.prod-cat-title{font-size:17px;color:#000;width:100%;line-height:1;margin-bottom:15px;}
.prod-cat-title strong{margin-right:10px;display:inline-block;vertical-align:baseline;font-family:'Arial';font-weight:700;}
.prod-cat-title > strong{font-size:35px}
.prod-cat-title span{display:inline-block;vertical-align:baseline;color:#707070}
.prod-cat-subtitle{display:inline-block;vertical-align:baseline;margin:5px 0 0;font-size:17px;line-height:20px;}
.signup-screen a.nav-link{display:inline-block;font-family:"Arial";padding:2px 10px;color:#fff;background:#282828;border:1px solid #000000;-webkit-box-sizing:border-box;box-sizing:border-box;border-radius:6px;line-height:17px;}

/*New design*/
.pad-50-blk{padding:0 50px;width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;}
.product-blk-main{margin:12px 0 0;}
.product-blk-wrap{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;width:100%;padding:0 35px;}
.popular-product-inner-lisitng{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;height:440px;-ms-flex-line-pack:start;align-content:flex-start;}
.popular-prod-blk{width:68.2%;}
.popular-prod-blk .popular-product-lisitng-main{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;margin-bottom:20px;}
.popular-prod-blk .popular-product-lisitng-main h3{margin:0 15px 0 0;}
.popular-prod-blk .popular-product-lisitng-filter{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;padding:0;margin:0;list-style:none;}
.popular-prod-blk .popular-product-lisitng-filter li{margin-left:8px;font-size:14px;line-height:16px;font-family:"Arial";font-weight:400;color:#a8a8a8;cursor:pointer;}
.popular-prod-blk .popular-product-lisitng-filter li a{color:inherit;text-decoration:none;}
.popular-prod-blk .popular-product-lisitng-filter li:first-child{margin-left:0;}
.popular-prod-blk .popular-product-lisitng-filter li.active{color:#000;font-weight:700;}
.popular-prod-blk .loadmore-btn.loadbtn-disabled a {cursor:default;pointer-events:none;}
.prod-list-new{margin-right:31px;padding:10px 0;border-top:1px solid #D7D7D7;width:calc(50% - 31px);display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.prod-list-new .item-list-counter,
.prod-list-new .item-list-time{width:110px;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;}
.prod-list-new:nth-child(5n){border-bottom:1px solid #D7D7D7;}
.prod-list-new:last-child{border-bottom:1px solid #D7D7D7;}
.productdetail-main-blk .prod-list-new:nth-child(5n){border-bottom:none;}
.productdetail-main-blk .prod-list-new:last-child{border-bottom:1px solid #D7D7D7;}
.prod--main-title{font-family:'Arial';font-size:32px;line-height:1;margin:0;font-weight:700;}

.prod--main-title a{color:#000}
.prod--main-title a:hover{color:#000;text-decoration: none;}
span.item-number{display:inline-block;font-family:'Arial';font-weight:700;font-size:24px;line-height:27px;text-align:center;color:#000000;padding:0 5px;margin-right:6px;width:50px;}
span.item-rank{display:inline-block;color:#000;font-family:'Arial';font-style:normal;font-weight:700;padding:3px 6px 3px 0;font-size:16px;line-height:18px;margin-right:6px;}
span.item-rank.rank-positive{color:#8DC63F;}
span.item-rank.rank-positive .fa-caret-down:before{content:"\f0d8"}
span.item-rank.rank-negative{color:#C90505}
span.item-rank .rank-item-new{border: 1px solid #AD05C9;border-radius:9px;font-size:11px;line-height:15px;color:#AD05C9;text-transform:uppercase;width:36px;height:16px;display:inline-block;text-align:center;}
.item-uploaded{display:inline-block;font-family:'Arial';font-weight:700;font-size:16px;line-height:18px;color:#707070;margin-right:12px;width:100%;text-align:left;}
.item-image{display:inline-block;width:109px;height:63px;padding-right:14px;}
span.item-nickname{font-family:'Arial';font-weight:400;}
.item-image img{height:100%!important;width:100%;-o-object-fit:cover;object-fit:cover;-o-object-position:center;object-position:center;}
.item-name{display:inline-block;font-family:'Arial';font-style:normal;font-weight:700;font-size:13px;line-height:15px;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#000000;width:calc(100% - 219px);}
.item-name a{color:#000000;text-decoration:none;}
.item-time{font-size:11px;line-height:12px;display:block;margin-top:2px;font-weight:400;}
.item-time p{margin:0;}
.recent-prod-blk{width:31.8%;}
.recent-prod-blk .prod--main-title{margin-bottom:20px}
.recent-prod-blk .popular-product-inner-lisitng{display:block;}
.recent-prod-blk .popular-product-inner-lisitng .prod-list-new{margin-right:0;width:100%;}
.loadmore-btn{text-align:center;margin-top:10px;}
button.load-more,
.loadmore-btn a.load-more{border:1px solid #707070;-webkit-box-sizing:border-box;box-sizing:border-box;border-radius:10px;font-family:'Arial';font-size:17px;font-style:normal;font-weight:400;line-height:20px;letter-spacing:0em;text-align:center;color:#707070;background:transparent;padding:6px 20px;min-width:246px;}
.releasedate-blk button.load-more,
.prod-footer-cnt button.load-more{padding:8px 20px;}
.large-main-title{font-family:'Arial';font-style:normal;font-weight:700;font-size:56px;line-height:64px;color:#000000;margin:0 10px 0 0;display:inline-block;}
span.sub-title{font-family:'Arial';font-weight:700;font-size:14px;line-height:16px;color:#000000;display:inline-block;}
span.sub-title a{color:#000;text-decoration:underline;}
.staffpick-blk .prod-name a,
.availablenow-blk .prod-name a,
.sneakernews-blk .prod-name a{font-family:'Arial';font-weight:700;font-style:normal;font-size:13px;line-height:15px;text-align:center;color:#000000;text-decoration:none;}
.staffpick-blk .prod-name .prod-color,
.availablenow-blk .prod-name .prod-color{font-weight:normal;font-size:13px;line-height:15px;}
.staffpick-blk .prod-name,
.availablenow-blk .prod-name,
.sneakernews-blk .prod-name{text-align:center;font-size:initial;line-height:normal;}
.staffpick-blk .product-img,
.availablenow-blk .product-img,
.sneakernews-blk .product-img{margin-bottom: 10px}
.staffpick-blk .prod-price{display:none;}
.staffpick-blk .post-date{font-size:13px;line-height:14px;text-align:center;margin:0;color:#000;}
.staffpick-blk .prod-name a:hover{text-decoration:none;}
.staffpick-blk .slick-slider,
.availablenow-blk .slick-slider,
.sneakernews-blk .slick-slider,
.productdetail-main-blk .you-may-slider .slick-slider{border-bottom:1px solid #D7D7D7;padding-bottom:30px;}
.staffpick-blk .latestnews-wrapper .slick-list{margin:0 -23px;}
.staffpick-blk .slick-initialized .slick-slide{padding:0 23px;}
.availablenow-blk .prod-list-title-wrap,
.sneakernews-blk .prod-list-title-wrap,
.staffpick-blk .prod-list-title-wrap,
.live-updates-blk .prod-list-title-wrap{margin-bottom:22px;}
.availablenow-blk .latestnews-wrapper .slick-list,
.productdetail-main-blk .sneakernews-blk .slick-list{margin:0 -23px;}
.availablenow-blk .slick-initialized .slick-slide,
.productdetail-main-blk .sneakernews-blk .slick-slide,
.productdetail-main-blk .you-may-slider .slick-slide{padding:0 23px;}
.availablenow-blk .prod-price{font-family:'Arial';font-size:13px;line-height:15px;text-align:center;color:#000000;}
.sneakernews-blk .latestnews-wrapper{margin-top:69px;}
.sneakernews-blk .latestnews-text-blk h5{font-family:'Arial';font-style:normal;font-weight:700;font-size:13px;line-height:15px;text-align:center;color:#000000;min-height:40px;}
.sneakernews-blk .latestnews-blk-list p.post-detail{font-size:13px;line-height:15px;text-align:center;margin-top:10px;}
.releasedate-blk{padding-top:40px;}
.allproducts:empty{margin-bottom:0;}
.releasedate-blk p.release-title{margin-top:41px;padding:0;font-family:'Arial';font-weight:700;font-size:23px;line-height:26px;}
.releasedate-blk p.release-title span{font-weight:400;font-family:'Arial'}
.releasedate-blk p.release-title.only-release{font-size:29px;line-height:30px;}
.home-wrap .releasedate-blk p.release-title{margin-top:55px;}
.product-main-list-blk{position:relative;width:100%;min-height:200px}
.product-main-list-blk #product_listing{min-height:100px}
.product-main-list-blk .product-list-header{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-ms-flex-wrap:wrap;flex-wrap:wrap;}
.product-main-list-blk .product-list-header .prod-list-title-wrap{padding-right:20px;width:calc(100% - 330px)}
.product-main-list-blk .product-list-header.search-with-filter .prod-list-title-wrap{width:calc(100% - 540px)}
.product-main-list-blk .product-list-header .filters-blk-wrap{width:330px;display:flex;justify-content:flex-end;align-items:center;flex-wrap:wrap;}
.product-main-list-blk .product-list-header.search-with-filter .filters-blk-wrap{width:540px;}
.product-main-list-blk .product-list-header .filter-search{position:relative;width:calc(100% - 130px);margin:0 15px 0 0;max-width:405px;}
.product-main-list-blk .product-list-header .form-control{border-radius:18px;width:100%;height:35px;color:#000;padding:5px 28px 5px 15px;font-size:15px;background-color: #fff;border: 1px solid #707070;}
.product-main-list-blk .product-list-header input[type="search"]::placeholder{color:#000;}
.product-main-list-blk .product-list-header input[type="search"]::-webkit-search-cancel-button{-webkit-appearance:none;}
.product-main-list-blk .product-list-header .btn-close{background-image:url('../assets/images/closeicon.svg');background-size:cover;width:14px;height:14px;top:50%;transform:translateY(-50%);}
.product-main-list-blk .product-list-header .btn-search{padding:0;border:0;position:absolute;top:50%;right:20px;width:15px;height:15px;background-size:15px;background-image:url('../assets/images/search-icon-gray.svg');transform:translateY(-50%);}
.live-updates-blk .slick-track{display:flex;}
.live-updates-blk .slick-slide{padding:0 20px;height:auto;}
.live-updates-blk .slick-slide>div{height:100%;}
.live-updates-blk .product-item{border-radius:25px;border:1px solid #D7D7D7;overflow:hidden;height:100%;}
.live-updates-blk .prod-images{display:grid;height:210px;overflow:hidden;border-bottom:1px solid rgba(0, 0, 0, 0.12);}
.live-updates-blk .prod-images img{max-width:100%;width:100%;height:100%;object-fit:cover;object-position:center;}
.live-updates-blk .prod-images.has-1-image>div{height:210px;}
.live-updates-blk .prod-images.has-2-image{grid-template-areas:"prodImage0 prodImage1";}
.live-updates-blk .prod-images.has-2-image>.prod-image-0{border-right:1px solid rgba(0, 0, 0, 0.12);}
.live-updates-blk .prod-images.has-3-image{grid-template-areas:"prodImage0 prodImage1" "prodImage0 prodImage2";}
.live-updates-blk .prod-images.has-3-image>.prod-image-0{border-right:1px solid rgba(0, 0, 0, 0.12);height:210px;}
.live-updates-blk .prod-images.has-3-image>.prod-image-1{border-bottom:1px solid rgba(0, 0, 0, 0.12);}
.live-updates-blk .prod-images.has-3-image>div{height:105px;}
.live-updates-blk .prod-images.has-4-image{grid-template-areas:"prodImage0 prodImage1" "prodImage2 prodImage3";}
.live-updates-blk .prod-images.has-4-image>.prod-image-0{border-right:1px solid rgba(0, 0, 0, 0.12);border-bottom:1px solid rgba(0, 0, 0, 0.12);}
.live-updates-blk .prod-images.has-4-image>.prod-image-1{border-bottom:1px solid rgba(0, 0, 0, 0.12);}
.live-updates-blk .prod-images.has-4-image>.prod-image-2{border-right:1px solid rgba(0, 0, 0, 0.12);}
.live-updates-blk .prod-images.has-4-image>div{height:105px;}
.live-updates-blk .prod-images>.prod-image-0{grid-area:prodImage0;}
.live-updates-blk .prod-images>.prod-image-1{grid-area:prodImage1;}
.live-updates-blk .prod-images>.prod-image-2{grid-area:prodImage2;}
.live-updates-blk .prod-images>.prod-image-3{grid-area:prodImage3;}
.live-updates-blk .prod-details{padding:20px 20px 50px;height:calc(100% - 210px);position:relative;}
.live-updates-blk .prod-name{font-weight:500;font-size:14px;line-height:16px;color:#000;cursor:pointer}
.live-updates-blk .prod-name a{color:#707070;text-decoration:none;}
.live-updates-blk .prod-name a pre{white-space:pre-line;font-family:'Arial';color:#000;font-size:14px;}
.live-updates-blk .post-time{font-size:14px;line-height:16px;color:#707070;position:absolute;bottom:25px;left:20px;}
.live-updates-blk .latestnews-wrapper .slick-prev, .live-updates-blk .latestnews-wrapper .slick-next{top:50%;transform:translateY(-50%);}

/* search list */
.product-main-list-blk.is_search_list-blk .product-list-header{margin:0 0 20px;padding:0 0 30px;border-bottom:1px solid #d7d7d7;}
.product-main-list-blk.is_search_list-blk .large-main-title{font-size:59px;line-height:69px}

/* filter block */
.filter-btn-blk{display:inline-block;width:100%;text-align:right;margin-bottom:5px}
span.filter-btn{border:1px solid #707070;display:inline-block;cursor:pointer;-webkit-box-sizing:border-box;box-sizing:border-box;border-radius:11px;font-family:'Arial';font-weight:400;font-size:17px;line-height:20px;-webkit-box-align:center;-ms-flex-align:center;align-items:center;text-align:right;padding:7px 21px;}
span.filter-btn svg{margin-right:6px;vertical-align:middle;}
span.filter-btn svg path{fill:#707070}
.filters-blk{top:0;background:#FFFFFF;border:1px solid #D7D7D7;position:absolute;right:0;z-index:99;-webkit-box-sizing:border-box;box-sizing:border-box;border-radius:15px;-webkit-box-shadow:0px 4px 10px rgba(0,0,0,0.25);box-shadow:0px 4px 10px rgba(0,0,0,0.25);max-width:456px;padding:15px;display:none;}
.filters-blk .filter-btn-blk{margin-bottom:10px}
.filters-blk span.filter-btn{background:#707070;color:#FFFFFF;}
.filters-blk span.filter-btn svg path{fill:#fff}
.filter-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;padding:8px 0;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;border-top:1px solid #D7D7D7;}
.filter-row .filter-col-left{margin-right:10px;}
.filter-row .filter-col-left label{font-family:'Arial';margin-bottom:0;font-weight:700;font-size:13px;line-height:15px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;letter-spacing:0.095em;color:#000000;text-transform:uppercase;}
.filter-row .filter-col-right{text-align:right;}
span.filter-span{border:1px solid #707070;-webkit-box-sizing:border-box;box-sizing:border-box;border-radius:23px;font-family:'Arial';font-weight:400;font-size:13px;line-height:15px;text-align:center;color:#707070;padding:3px 8px;margin-right:10px;vertical-align:middle;cursor:pointer;display:inline-block;}
span.filter-span.disabled{color:#d7d7d7;border-color:#d7d7d7;pointer-events:none;}
span.filter-span.active-filter{background-color:#707070;color:#fff;}
select.filter-select{background:#E9E8E8;-webkit-appearance:none;-moz-appearance:none;appearance:none;border-radius:3px;border:0;padding:8px 20px 8px 8px;font-family:Arial;font-style:normal;font-weight:normal;font-size:10px;line-height:11px;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#707070;margin-right:10px;width:100%}
.filter-select-wrapper{position:relative;display:inline-block;margin-right:10px;}
.filter-select-wrapper:after{content:"\f0d7";font-size:10px;display:inline-block;position:absolute;color:#707070;font:normal normal normal 14px/1 FontAwesome;right:5px;top:7px;}
select.filter-select:last-child,
span.filter-span:last-child,
.filter-select-wrapper:last-child{margin-right:0;}
.filter-select-wrapper.filter-by-year{min-width:76px;}
.filter-select-wrapper.filter-by-month{min-width:96px;}
.filter-select-wrapper.filter-by-brand,
.filter-select-wrapper.filter-by-modal{max-width:133px;}
.filter-dropdown-list{margin-top:10px;width:100%;text-align:right;}
button.btn-plus{border:1px solid #707070;border-radius:100%;background:#fff;height:14px;width:14px;color:#707070;font-size:8px;font-weight:700;vertical-align:middle;line-height:1;text-align:center;padding:1px;letter-spacing:-1px;}
button.btn-plus.disabled{color:#d7d7d7;border-color:#d7d7d7;pointer-events:none;}
.selected-filters{display:block;width:100%;text-align:right;}
.selected-filters .reset-filter{font-family:Arial;font-size:10px;line-height:11px;margin-right:10px;text-transform:uppercase;color:#707070;cursor:pointer;}
.selected-filters .reset-filter.disabled{color:#d7d7d7;}
.filter-row:last-child{border-top:0;padding-top:0;}
.filter-row .selected-filters{padding:2px 0}
.filter-row .selected-filters span.filter-span{margin:3px 10px 3px 0;cursor:auto;}
.filter-row .selected-filters .filter-close-icon{line-height:8px;margin-left:5px;display:inline-block;cursor:pointer;vertical-align:middle;margin-bottom:2px;}
.filters-btn-blk{width:100%;}
.filters-btn-blk button.apply-filter-btn{width:100%;background:#707070;border:1px solid #707070;-webkit-box-sizing:border-box;box-sizing:border-box;border-radius:9px;margin-bottom:7px;color:#fff;text-transform:uppercase;font-family:'Arial';font-style:normal;font-weight:400;font-size:10px;line-height:12px;padding:7px;}
.filters-btn-blk button.cancel-btn{background:#FFFFFF;border:1px solid #707070;-webkit-box-sizing:border-box;box-sizing:border-box;border-radius:9px;width:100%;font-family:Arial;font-style:normal;font-weight:bold;font-size:10px;line-height:11px;text-align:center;color:#707070;padding:7px;}
.active-filter-blk{display:block;}
/*.header div[class^="col-"]{position:relative}*/
.non-active-selected-filters{text-align:right;width:100%}
.non-active-selected-filters .selected-filters{padding:5px 0}
.non-active-selected-filters .selected-filters:empty{padding:0}
.non-active-selected-filters span.filter-span{margin:3px 10px 3px 0}
.non-active-selected-filters .filter-close-icon{line-height:8px;margin-left:5px;display:inline-block;cursor:pointer;vertical-align:middle;}
.active-filter .non-active-selected-filters,
.active-filter > .filter-btn{visibility:hidden;opacity:0}
.availablenow-blk .slick-slide img,
.staffpick-blk .slick-slide img,
.sneakernews-blk .slick-slide img{width:100%;height:100%;-o-object-fit:cover;object-fit:cover;-o-object-position:center;object-position:center;}

.hide{display:none;}
.cls-1{fill:#fff;}

/* simple loader */
.loader-active{position:relative;}
.loader-active:after{content:"";display:inline-block;position:absolute;z-index:100;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);transform:translate(-50%, -50%);width:100px;height:100px;background-image:url("../assets/images/loader.svg");}
.loader-active:before{background:#fff;content:"";height:100%;width:100%;display:inline-block;position:absolute;z-index:99;opacity:0.6;left:0}

/* sticky loader */
.sticky-loader-active{position:relative;}
.sticky-loader-wrap{position:absolute;width:100%;height:100%;left:0;right:0;top:0;bottom:0;}
.sticky-loader-active .sticky-loader-span{position:sticky;z-index:100;top:50%;left:50%;display:inline-block;width:100px;height:100px;background-image:url("../assets/images/loader.svg");transform:translateX(-50%);}
.sticky-loader-active .sticky-loader-span:after{content:"";}
.sticky-loader-active:before{background:#fff;content:"";height:100%;width:100%;display:inline-block;position:absolute;z-index:99;opacity:0.6;left:0;right:0;bottom:0;top:0}

.top-header .navbar-nav .sub-mega-menu .megamenu-cnt ul li a{padding:4.5px 0;}
.top-header .navbar-nav .sub-mega-menu .megamenu-cnt ul li:first-child a{padding:0 0 4.5px;}
.header-navigation .navbar-nav .nav-link a{color:#fff;}
.header-navigation .navbar-nav .nav-link a:hover{text-decoration:none;}

/* form general */
.form-field-wrp{position:relative;width:100%}
.form-field-wrp input, .form-field-wrp textarea{border:2px solid transparent;padding:4px 46px 3px 11px!important;background-color:#eee}
.form-field-wrp.kf-toggle-pass input{padding:4px 72px 3px 11px!important;background-color:#eee}
.form-field-wrp.kf-focus input:focus, .form-field-wrp.kf-focus textarea:focus{border-color:#000;}
/* .form-field-wrp.kf-focus input:active, .form-field-wrp.kf-focus textarea:active{box-shadow:0px 300px 0px 0px rgb(0 0 0 / 8%) inset;border-color:rgb(0 0 0 / 8%);} */
.form-field-wrp.kf-status.kf-e-blur .kf-status-complete{position:absolute;background-image:url("../assets/images/kf-complete.svg");width:14px;height:11px;background-repeat:no-repeat;background-size:cover;top:50%;right:25px;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);}
.form-field-wrp.kf-status.kf-e-blur .kf-status-incomplete{position:absolute;background-image:url("../assets/images/kf-incomplete.svg");width:12px;height:12px;background-repeat:no-repeat;background-size:cover;top:50%;right:25px;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);}
.form-field-wrp.kf-e-focus.kf-clear .kf-clear-span{position:absolute;width:15px;height:15px;top:50%;right:25px;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);cursor:pointer;background-image:url("../assets/images/kf-clear.svg");background-repeat:no-repeat;background-size:cover;z-index:1;}
.form-field-wrp.kf-toggle-pass .kf-toggle-pass-span{position:absolute;width:16px;height:15px;top:50%;right:25px;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);cursor:pointer;background-image:url("../assets/images/hide-password-icon.svg");background-repeat:no-repeat;background-size:15px;margin-top:1px;z-index:1;}
.form-field-wrp.kf-toggle-pass .kf-toggle-pass-span.kf-show-pass{background-image:url("../assets/images/show-password-icon.svg");}
.form-field-wrp.kf-e-focus.kf-clear.kf-toggle-pass .kf-clear-span{right:56px;}
.form-field-wrp.kf-positive input:focus, .form-field-wrp.kf-positive textarea:focus{border-color:#66D19E!important;background-color:#E6F2ED!important;}
.form-field-wrp.kf-negative input:focus, .form-field-wrp.kf-negative textarea:focus{border-color:#F1998E!important;background-color:#FFEFED!important;}
.form-field-wrp.kf-disabled input, .form-field-wrp.kf-disabled textarea{background-color:#F6F6F6;color:#AFAFAF;pointer-events:none;cursor:default;}

/* about us */
.about-us-page .cms-cnt-inner{padding:129px 0 108px 0;margin:0;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;}
.about-us-page .cms-cnt-inner .cms-img-conatiner{width:50%;margin:0;padding:0 20px 0 0;}
.about-us-page .cms-cnt-inner .cms-cnt-wrapper{width:50%;padding:0 0 0 20px;margin:175px 0 0 0;}
.about-us-page.cms-column-layout .cms-row{margin:0;}
.about-us-page.cms-column-layout .about-title{width:100%;margin:0 0 34px 0;padding:0;}
.about-us-page.cms-column-layout .cms-row .cms-col{width:100%;padding:0;}
.about-us-page.cms-column-layout .cms-cnt-txt{margin:0 0 60px 0;}
.about-us-page.cms-page .cms-cnt-inner .cms-page-title{margin:0 0 6px 0;text-align:left;font-size:20px;line-height:23px;text-transform:none;font-family:"Arial";font-weight:700;}
.about-us-page .cms-cnt-inner p{font-size:17px;line-height:20px;text-transform:none;font-family:"Arial";font-weight:400;color:#707070;margin:0 0 20px 0;}
.about-us-page .cms-cnt-inner .cms-cnt-form .cms-page-title{margin:0 0 20px 0;}
.about-us-page form{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin:0 -13px;}
.about-us-page form .form-field-wrp{margin:0 0 2px;}
.about-us-page form > div{padding:0 13px;margin:0 0 19px 0;}
.about-us-page form > .col-lg-12{margin:0 0 17px 0;}
.about-us-page form div .form-field-wrp{padding:0;margin:0 0 2px;line-height:1;}
.about-us-page form .about-label-button{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;margin:0 0 2px;}
.about-us-page form .about-label-button p{margin:0;font-size:12px;line-height:13px;font-style:italic;margin-right:32px;}
.about-us-page form .about-label-button p span{color:#c90505;font-size:18px;}
.about-us-page form label{font-size:13px;line-height:24px;text-transform:none;font-family:"Arial";font-weight:700;color:#707070;display:inline-block;width:100%;margin:0 0 4px 0;}
.about-us-page form select{border:2px solid transparent;}
.about-us-page form input, .about-us-page form textarea, .about-us-page form select{font-weight:400;background-color:#F1F1F1;padding:4px 11px 3px 11px;-webkit-appearance:none;-moz-appearance:none;appearance:none;resize:none;margin:0;width:100%;font-family:"Arial";font-size:13px;line-height:24px;text-transform:none;color:#707070;}
.about-us-page form label{padding:0 0 0 11px;}
.about-us-page form label span{color:#c90505;font-size:18px;}
.about-us-page form .select-data{position:relative;padding:0;width:100%;}
.about-us-page form .select-data::after{content:"\f0d7";font-size:20px;color:#707070;display:inline-block;font-family:"FontAwesome";position:absolute;right:12px;top:3px;z-index:1;}
.about-us-page form textarea{height:185px;}
.about-us-page form .form-field-wrp.kf-e-focus.kf-clear textarea + .kf-clear-span{top:auto;bottom:15px;}
.about-us-page form button[type="submit"]{border:1px solid #707070;border-radius:10px;background-color:transparent;padding:8px;font-size:17px;line-height:20px;text-transform:none;font-family:"Arial";font-weight:400;color:#707070;min-width:246px;-webkit-transition:all 0.2s ease;-o-transition:all 0.2s ease;transition:all 0.2s ease;}
.about-us-page form button[type="submit"]:hover{background-color:#707070;color:#fff;}
.about-us-page .userMsgSuccess{margin:0}
.userMsgSuccess{display:inline-block;width:100%;background-color:rgba(0,128,0,0.23);padding:8px 15px;border-radius:12px;margin:10px 0;transition:.3s all ease-in-out;text-align:center;}
.userMsgSuccess p{padding:0;margin:0!important;text-align:center;color:green!important;font-family:"Arial"!important;font-size:16px!important;font-weight:700;}

/*terms and condition page*/
.terms-privacy-main{padding:50px 0 0;}
.terms-privacy-main .Iframe-content-97a9de{min-height:calc(100vh - 288px);}
.terms-privacy-main .Iframe-mask-a8eeac{top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);}

/*Product details page placeholder design*/
@-webkit-keyframes placeholdergradient{
	0%{background-position:0% 50%;}
	50%{background-position:100% 50%;}
	100%{background-position:0% 50%;}
}
@keyframes placeholdergradient{
	0%{background-position:0% 50%;}
	50%{background-position:100% 50%;}
	100%{background-position:0% 50%;}
}
.slider-cnt-placeholder.slider-cnt{opacity:0.6;}
.slider-cnt-placeholder .product-placeholder{width:100%;background:rgb(215 215 215 / 40%);position:relative;}
.slider-cnt-placeholder .product-placeholder::before{content:"";width:100%;height:100%;display:inline-block;background:-o-linear-gradient(135deg,#d7d7d7,#eee,#d7d7d7,#eee);background:linear-gradient(135deg,#d7d7d7,#eee,#d7d7d7,#eee);-webkit-animation:gradient 1s ease infinite;animation:placeholdergradient 1s ease infinite;background-size:400% 400%;}
.slider-cnt-placeholder .prod-rank-wrp .prod-rank-item{border:none;}
.slider-cnt-placeholder .prod-img-cnt-blk{height:190px;}
.slider-cnt-placeholder .slide-title h4{height:35px;}
.slider-cnt-placeholder .slider-cnt .prod-release-wrap{width:calc(100% - 60px);height:20px;}
.slider-cnt-placeholder .slider-cnt ul.info-count{text-align:right;}
.slider-cnt-placeholder .slider-cnt ul.info-count li{width:40px;height:20px;display:inline-block;}
.slider-cnt-placeholder .post-disclamier-blk span{height:35px;margin-bottom:10px;margin-top:10px;}
.slider-cnt-placeholder .prod-sku-table ul.info-count{width:100%;}
.slider-cnt-placeholder .prod-count-release ul.info-count li{width:40px;margin-left:auto;height:22px;}
.slider-cnt-placeholder.slider-cnt .prod-release-wrap{height:25px;}
.slider-cnt-placeholder .prod-sku-table ul.info-count li{width:100%;margin-bottom:12px;border-bottom:1px solid #d7d7d7;}
.slider-cnt-placeholder .button-row button{opacity:0.3;}

.slide-wrapper{ display: flex; flex-wrap: wrap; width:100%;}
.slide-wrapper-blk{float:left;}
.productdetail-main-blk .slide-single-wrapper { background: #f5f5f5;width:100%; padding: 0; display: flex; justify-content: center; align-items: center; } 
.productdetail-main-blk .slide-single-wrapper .slider-img-cnt{width:100%;padding:0;margin:0}
.productdetail-main-blk .single-image-blk { background: #f5f5f5; width: 100%; padding: 20px; display: flex;flex-wrap: wrap; justify-content: center; align-items: center; }
.productdetail-main-blk .single-image-blk div img{margin:0 auto;max-width:300px;}
.productdetail-main-blk .single-image-blk span { font-size: 26px; color: #707070; padding: 5px; text-align: center; display: block; border: 1px solid #707070; border-radius: 15px; line-height: 29px;width: auto; max-width: 340px; margin: 10px auto;}

.productdetail-main-blk .slide-wrapper .slick-slider .slick-dots{bottom:0;display:flex!important;justify-content:center;align-items:center;line-height:1}
.productdetail-main-blk .slide-wrapper .slick-slider .slick-dots li{margin:0;width:100%;height:6px;line-height:1;background-color:#d7d7d7}
.productdetail-main-blk .slide-wrapper .slick-slider .slick-dots li.slick-active{background-color:#707070}
.productdetail-main-blk .slide-wrapper .slick-slider .slick-dots li button{display:none;}
.productdetail-main-blk .slide-wrapper .slick-slider .slick-prev,.productdetail-main-blk .slide-wrapper .slick-slider .slick-next{display: none!important;}
.productdetail-main-blk .slide-wrapper .slick-slider .slider-img-cnt{padding:0;margin:0;}
.productdetail-cnt .slider-img-cnt-blk{background:#ecedee}

ul.sizerun-list { display: flex; align-items: flex-end; width: 100%; justify-content: flex-end; padding: 0; margin: 5px; min-height:13px;}
ul.sizerun-list li{list-style:none;font-size:11px;line-height:13px;color: #707070;font-family: 'arial';font-weight: 400;} 
ul.sizerun-list li.size-active {font-weight:700;color:#000}
ul.sizerun-list li:after{content: "/";padding: 0 4px;color: #707070}
ul.sizerun-list li:last-child::after{content: "";padding:0;}
.productdetail-main-blk .release-date .release-weekday{font-weight:400;text-transform: capitalize;font-family: 'Arial';}
.productdetail-main-blk .release-date .current-display-release{padding-right:5px;}

.popular-product-inner-lisitng.is-loading{opacity: 0.5;}
.popular-product-inner-lisitng.is-loading .item-list-time::before,.popular-product-inner-lisitng.is-loading .item-image::before,.popular-product-inner-lisitng.is-loading .item-name::before{content:"";width:100%;height:100%;/* min-height:300px; */position: absolute;display:inline-block;background:-o-linear-gradient(135deg,#d7d7d7,#eee,#d7d7d7,#eee);background:linear-gradient(135deg,#d7d7d7,#eee,#d7d7d7,#eee);-webkit-animation:gradient 15s ease infinite;animation:placeholdergradient 1s ease infinite;background-size:400% 400%;} 
.popular-product-inner-lisitng.is-loading .item-list-time::before{min-height:15px;width:95%}
/* .popular-product-inner-lisitng.is-loading .item-name::before{min-height:40px;} */
.popular-product-inner-lisitng.is-loading .prod-list-new { position: relative; } 
.popular-product-inner-lisitng.is-loading .item-image,.popular-product-inner-lisitng.is-loading .item-name ,.popular-product-inner-lisitng.is-loading  .item-list-time{ position: relative; }

.latestnews-wrapper.slider-placeholder .product-item-wrap{display:flex;justify-content:space-between;align-items:center;margin:0 -5px}
.latestnews-wrapper.slider-placeholder .product-item{width:24%;height:auto;margin:0 5px;padding:0 23px;min-height:200px;}
.latestnews-wrapper.slider-placeholder .product-item,
.latestnews-wrapper.slider-placeholder .product-list-header{background:rgb(215 215 215 / 40%);opacity:0.6;position:relative;}
.latestnews-wrapper.slider-placeholder .product-item::before,
.latestnews-wrapper.slider-placeholder .product-list-header::before{content:"";width:100%;height:100%;display:inline-block;background:-o-linear-gradient(135deg,#d7d7d7,#eee,#d7d7d7,#eee);background:linear-gradient(135deg,#d7d7d7,#eee,#d7d7d7,#eee);-webkit-animation:gradient 1.5s ease infinite;animation:placeholdergradient 1.5s ease infinite;background-size:400% 400%;position:absolute;top:0;left:0;}

.latestnews-wrapper{position:relative;}
.latestnews-wrapper .button-slide{visibility:hidden;font-size:0;position:absolute;}
/* .latestnews-wrapper button.slick-arrow { width: 300px !important; height: 250px !important; } */

.individual-product-main{min-height:45vh;}
.individual-product-main-content{min-height:calc(100vh - 210px);}

.popular-prod-blk .loadmore-btn a{border: 1px solid #707070; text-decoration: none; box-sizing: border-box; border-radius: 10px; font-family: "Arial"; font-size: 17px; font-style: normal; font-weight: 400; line-height: 20px; letter-spacing: 0; text-align: center; color: #707070;display: inline-block; background: transparent; padding: 6px 20px; min-width: 246px;}
.user-set-popup-block .save-btn.disabled{pointer-events:none;cursor:default;}

/* Apple Login */
.main_content.wrapp.apple-login-wrap{padding:75px 0 40px;min-height:calc(100vh - 216px);}
.apple-login-wrap.loader-active::before{left:0;right:0;top:0;bottom:0;}

/* media */
@media (min-width:768px){
	/*product detail page*/
	.prod-cat-row .prod-cat-body{background-color:#fcfcfc;-webkit-box-shadow:0px 4px 10px 3px rgba(0, 0, 0, 0.25);box-shadow:0px 4px 10px 3px rgba(0, 0, 0, 0.25);border-radius:6px;padding:30px;}
	.productdetail-main-blk .prod-info-count .button-row{display:flex;gap:10px;}
}
@media (min-width:992px){
	.banner-carousel .carousel-indicators{max-width:960px;padding-right:30px;}
	.header-navigation nav.navbar{width:77%;position:relative;}
	#sidebar{-webkit-transition:.5s all;-o-transition:.5s all;transition:.5s all;position:-webkit-sticky!important;position:sticky!important;top:10px;}
	.nav-up #sidebar{top:160px}
	.nav-up .home-wrap #sidebar{top:160px}
	.latestnews-blk-list p.post-detail{margin-bottom:5px;}
	.archive.sidebox .sidebar-label i.fa-caret-down{font-size:26px;margin:5px;cursor:pointer}
	.archive.sidebox ul{display:none;}
	.archive.sidebox ul.option-active{display:block;}
	.nav-up .sidebar.up-sidebar{top:160px!important}
	.header .navbar-nav .nav-item.hidemenu .sub-mega-menu{opacity:0;visibility:hidden;}
	.usersetting-wrapper{min-height:calc(100vh - 450px);}
	.allproducts a.product-img{height:120px}
	.availablenow-blk .slick-slide img,
	.staffpick-blk .slick-slide img,
	.sneakernews-blk .slick-slide img,
	.productdetail-main-blk .you-may-slider .product-img img{min-height:120px;max-height:120px;}
	.latestnews-wrapper.slider-placeholder{min-height:220px}
	.latestnews-wrapper.slider-placeholder .product-item{min-height:120px}
	.prod-cat-row .prod-cat-body{padding:40px 50px;}
	.latestnews-wrapper .slick-prev,
	.latestnews-wrapper .slick-next{top:26%;}
	.staffpick-blk .latestnews-wrapper .slick-prev,
	.staffpick-blk .latestnews-wrapper .slick-next{top:34%;}
	.you-may-slider .latestnews-wrapper .slick-prev,
	.you-may-slider .latestnews-wrapper .slick-next{top:22%;}
	.productdetail-main-blk .single-image-blk{min-height:450px;}
}
@media (min-width:1200px){
	.top-header .right-head .sidelinks .nav-link.is-new{padding:4px 0 4px 28px;background:url('../assets/images/new-menu-tag.png') no-repeat left center;background-size:contain;}
	.banner-carousel .carousel-indicators{max-width:1140px;}
	.allproducts a.product-img{height:150px}
	.availablenow-blk .slick-slide img,
	.staffpick-blk .slick-slide img,
	.sneakernews-blk .slick-slide img,
	.productdetail-main-blk .you-may-slider .product-img img{min-height:150px;max-height:150px;}
	.latestnews-wrapper.slider-placeholder{min-height:260px}
	.latestnews-wrapper.slider-placeholder .product-item{min-height:150px}
	.latestnews-wrapper .slick-prev,
	.latestnews-wrapper .slick-next{top:30%;}
	.staffpick-blk .latestnews-wrapper .slick-prev,
	.staffpick-blk .latestnews-wrapper .slick-next{top:35%;}
	.you-may-slider .latestnews-wrapper .slick-prev,
	.you-may-slider .latestnews-wrapper .slick-next{top:26%;}

	.slider-cnt-placeholder .prod-img-cnt-blk{height:270px;}
	.slider-cnt-placeholder .slide-title h4{margin-bottom:25px;}
	.slider-cnt-placeholder .slider-cnt .prod-count-release{margin-bottom:25px;}
	.slider-cnt-placeholder .prod-sku-table ul.info-count{margin-bottom:30px;}
	.slider-cnt-placeholder .post-disclamier-blk span{height:40px;margin-bottom:20px;}
	.slider-cnt-placeholder .prod-sku-table ul.info-count li{height:30px;}
	.productdetail-main-blk .single-image-blk{min-height:550px;}
}
@media (min-width:1440px){
	.container{max-width:1440px}
	.banner-carousel .carousel-indicators{max-width:1440px;}
	.productdetail-main-blk .container{padding:0 50px;}
	.allproducts a.product-img{height:205px;}
	.availablenow-blk .slick-slide img,
	.staffpick-blk .slick-slide img,
	.sneakernews-blk .slick-slide img,
	.productdetail-main-blk .you-may-slider .product-img img{min-height:200px;max-height:200px;}
	.latestnews-wrapper .slick-prev,
	.latestnews-wrapper .slick-next{top:34%;}
	.staffpick-blk .latestnews-wrapper .slick-prev,
	.staffpick-blk .latestnews-wrapper .slick-next{top:36%;}
	.you-may-slider .latestnews-wrapper .slick-prev,
	.you-may-slider .latestnews-wrapper .slick-next{top:28%;}

	.latestnews-wrapper.slider-placeholder{min-height:300px}
	.slider-cnt-placeholder .prod-img-cnt-blk{height:350px;}
	.slider-cnt-placeholder .slide-title h4{margin-bottom:40px;}
	.slider-cnt-placeholder .slider-cnt .prod-count-release{margin-bottom:40px;}
	.slider-cnt-placeholder .prod-sku-table ul.info-count{margin-bottom:50px;}
	.slider-cnt-placeholder .post-disclamier-blk span{height:55px;margin-bottom:30px;}
	.slider-cnt-placeholder .prod-sku-table ul.info-count li{height:35px;}
	.productdetail-main-blk .single-image-blk{min-height:700px;}

	/* about us */
	.about-us-page .container{padding:0 50px;}
}
@media (min-width:1440px) and (max-width:1550px) {
	.sub-mega-menu .megamenu-cnt .menu-col-left{margin-left:-30px;}
}
@media (max-width:1600px){
	.megamenu-cnt{padding:40px 30px}
	.sub-mega-menu .brand-logo-sct img{max-width:180px;}
	.menu-col-right{margin-left:30px}
	table.prod-table tbody tr td,
	table.prod-table thead tr th{padding:0 10px;}
	table.prod-table tbody tr td.retailer-col, table.prod-table thead tr th.retailer-col{padding:0 10px 0 0;}
	table.prod-table tbody tr td.reminder-col, table.prod-table thead tr th.reminder-col{padding:0 0 0 10px;}
}
@media (max-width:1440px){
	.title-404{font-size:35px;line-height:45px;margin-bottom:0;}
	span.item-number{width:40px;padding:0 3px;}
	.prod-list-new{margin-right:24px;width:calc(50% - 24px);}

	/* home */
	span.item-rank{line-height:22px;}
	.prod-list-new .item-list-counter,
	.prod-list-new .item-list-time{width:90px;}
	.item-name{width: calc(100% - 199px);}
	.sub-mega-menu .menu-col-right{flex-wrap: nowrap;}
}
@media (max-width:1400px){
	table.prod-table tbody tr td{width:33.33%;}
	.signModal .container .modal-dialog .modal-content .modal-body .img-block,
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block{width:100%}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block{padding:40px 30px 70px;}
	.ltst-news-blk-image{height:auto;}
	.megamenu-cnt{padding:40px 20px}
	.sub-mega-menu .brand-logo-sct img{max-width:160px;}
	.menu-col-right{margin-left:30px}
	.sub-mega-menu .megamenu-cnt span.brand-title{font-size:14px;}
	.sub-mega-menu .megamenu-cnt ul li{font-size:14px;}
	.about-page .cms-page-title{font-size:60px}
	.allproducts li .product-item i.fire svg,
	.productdetail-cnt .product-item i.fire svg{margin-top:-4px;}
	.cms-page h3{font-size:30px;line-height:35px;}
	.cms-page h2{font-size:45px;line-height:55px;}
	.cms-cnt-txt p,
	.cms-page ol li,
	.cms-page ul li{font-size:22px;line-height:35px;}
	span.item-rank{font-size:14px;line-height:16px;}

	/* about us */
	.about-us-page .cms-cnt-inner .cms-cnt-wrapper{margin:130px 0 0 0;padding:0 0 0 15px;}
	.about-us-page .cms-cnt-inner .cms-img-conatiner{padding:0 15px 0 0;}

	span.item-number{width:40px;font-size:18px;line-height:22px;padding:0 3px;}
}
@media (max-width:1200px){
	.latestnews-wrapper .slick-next:before{width:15px;height:30px;}
	.latestnews-wrapper .slick-prev:before{width:15px;height:30px;}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block{padding:30px 40px;}
	.user-setting-row span.span-newsletter{width:calc(100% - 80px);display:inline-block;vertical-align:middle;padding:0 15px;}
	.megamenu-cnt{padding:40px 15px}
	.sub-mega-menu .brand-logo-sct img{max-width:150px;}
	.menu-col-right{margin-left:20px}
	.sub-mega-menu .megamenu-cnt span.brand-title{font-size:13px;}
	.sub-mega-menu .megamenu-cnt ul li{font-size:13px;}
	.top-header .navbar-nav .nav-item{margin:0 13px 0 0;}
	.top-header .right-head .sidelinks{margin-left:13px;padding-left:13px;}
	.nav-watchlist a.nav-link{margin:0 15px 0 0;}
	.top-header span.timezone-cnt{padding-right:25px;}
	.top-header .right-head .sidelinks ul li{margin:0 13px 0 0;}
	.top-header .right-head .sidelinks > .nav-link{margin: 0 13px 0 0;}
	.about-page .cms-page-title{font-size:50px}
	.about-page .cms-cnt-txt{padding:70px 0}
	table.prod-table thead tr th,
	table.prod-table tbody tr td{font-size:15px}
	table.prod-table tbody tr td a.btn{font-size:14px}
	.productdetail-main-blk .slide-title h4{font-size:33px;line-height:36px;}
	.productdetail-main-blk .release-date{font-size:18px;line-height:20px;}
	.productdetail-main-blk ul.info-count i.eye-icon svg{max-width:24px}
	.productdetail-main-blk ul.info-count i.fire svg{max-width:20px;margin-top:0}
	.productdetail-main-blk .prod-footer-cnt ul.info-count i.fire svg{margin-top:-3px;}
	.productdetail-main-blk .slider-cnt .prod-count-release{margin:10px 0 16px;}
	.prod-sku-table th{font-size:12px;line-height:13px;}
	.prod-sku-table td{font-size:14px;line-height:16px;padding:15px 0 14px;}
	.post-disclamier-blk span{font-size:10px;line-height:11px;}
	.prod-cat-subtitle{font-size:13px;line-height:15px;}
	.label-404{font-size:200px}
	.title-404{font-size:30px;line-height:40px;margin-top:60px;}
	.search-below-cnt{margin-top:150px;}
	h6.notification-bar{font-size:30px;}
	.staffpick-blk .prod-name,.availablenow-blk .prod-name,.sneakernews-blk .prod-name{margin:0 0 5px;}
	.sneakernews-blk .latestnews-blk-list p.post-detail{margin-top:5px;}

	/* home */
	.allproducts-with-date::before{bottom:14px;}
	.home-wrap .releasedate-blk .allproducts-with-date p.release-title{margin-top:35px;}
	.allproducts-with-date .allproducts{margin-bottom:0;}
	.product-blk-wrap{-ms-flex-wrap:wrap;flex-wrap:wrap;}
	.popular-prod-blk{width:100%;}
	.recent-prod-blk{width:100%;margin-top:36px;}
	.prod-list-new{width:calc(50% - 12px);}
	.popular-prod-blk .popular-product-lisitng-main{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;}

	/* about us */
	.about-us-page form{margin:0 -8px;}
	.about-us-page form > div{padding:0 8px;}
	.about-us-page form .errorMsg{font-size:13px;}
	.about-us-page .cms-cnt-inner .cms-cnt-wrapper{margin:90px 0 0 0;}

}
@media (max-width:1180px){
	.productdetail-main-blk .slider-cnt .col-slide-left{width:calc(100% - 445px);}
	.productdetail-main-blk .slider-cnt .col-slide-right{width:445px;}
}
@media (max-width:1024px){
	.latestnews-wrapper .slick-prev{left:-20px;opacity:1;}
	.latestnews-wrapper .slick-next{right:-20px;opacity:1;}

	body.active-menu{overflow:hidden;}
	body.active-menu .header{transform:none;}
	.header .container{max-width:100%;}
	.top-header{background-color:#000;padding:15px 0;position:relative;height:60px;}
	.top-header .right-head{display:none;}
	.top-header .header-search-res{width:265px;height:40px;position:relative;margin:0 0 20px auto;max-width:100%;}
	.top-header .header-search-res .form-control{position:static;border-radius:20px;color:#fff;padding:5px 28px 5px 15px;font-size:15px;background-color:transparent;border:1px solid #fff;width:100%;height:40px;}
	.top-header .header-search-res input[type="search"]::placeholder{color:#fff;}
	.top-header .header-search-res input[type="search"]::-webkit-search-cancel-button{-webkit-appearance:none;}
	.top-header .header-search-res .btn-search,
	.top-header .header-search-res .btn-close{padding:0;border:0;position:absolute;top:50%;right:20px;width:14px;height:14px;background-size:cover;background-image:url('../assets/images/search-icon.svg');transform:translateY(-50%);}
	.top-header .header-search-res span.btn-close{background-image:url('../assets/images/closeicon-white.svg');}
	.header-res{position:static;justify-content:flex-end;padding-left:0;display:flex;}
	.top-header .navbar-brand{width:100%}
	.top-header .navbar-brand.navbrand-desktop{display:none;}
	.top-header .navbar-brand.navbrand-res{display:block;}
	.header-res nav.navbar.navbar-dark button.navbar-toggler{border:0;padding:0;color:#fff;display:inline-block;width:20px;height:21px;line-height:1;font-size:0;cursor:pointer;}
	.header-res nav.navbar.navbar-dark button.navbar-toggler span.menuicon{width:20px;height:3px;background-color:#fff;margin:2px 0;transition:all .2s linear;display:inline-block;}
	.header-res nav.navbar.navbar-dark button.navbar-toggler:not(.collapsed) span.menuicon1{-webkit-transform:rotate(-45deg) translate(-6px,6px);transform:rotate(-45deg) translate(-6px,6px);}
	.header-res nav.navbar.navbar-dark button.navbar-toggler:not(.collapsed) span.menuicon2{opacity:0;}
	.header-res nav.navbar.navbar-dark button.navbar-toggler:not(.collapsed) span.menuicon3{-webkit-transform:rotate(45deg) translate(-4px,-4px);transform:rotate(45deg) translate(-4px,-4px);}
	.header-res .navbar-collapse{position:fixed;z-index:9999;top:60px;right:-100%;background:rgba(0,0,0,0.8);width:80%;padding:25px 15px;border-top:1px solid #4d4d4d;text-align:right;display:block;transition:all .25s linear;height:calc(100vh - 45px);overflow-y:scroll;overflow-x:hidden;max-width:320px}
	.header-res .navbar-collapse.show{right:0;}
	.header-res .navbar-nav .nav-item{margin:0;}
	.top-header .right-head .form-inline .form-control{display:none;}
	.header-res .nav-watchlist{margin-bottom:10px;}
	.header-res .nav-watchlist.redicon a:after{margin-left:7px;height:8px;width:8px;}
	.header-res .navbar-nav li.nav-item a{color:#fff;padding:0;font-size:14px;line-height:16px;}
	.header-res .aboutnav-links li,
	.header-res .aboutnav-links li a{line-height:normal;}
	.header-res .sidelinks .new-user,
	.header-res .aboutnav-links{padding:0;list-style:none;margin-bottom:0;padding-bottom:5px;}
	.header-res .sidelinks span.forgot-pass{display:inline-block;width:100%;}
	.header-res .sidelinks{margin:0 0 15px;padding:0 0 15px;position:relative;}
	.header-res .sidelinks:after{content:'';width:160px;max-width:100%;height:1px;background-color:#fff;display:inline-block;position:absolute;bottom:0;right:0;}
	.header-res .navbar-nav .nav-label{font-size:14px;color:#707070;margin:0 0 5px;}

	.top-header ul.logged-in{list-style:none;margin-bottom:0;padding-left:0;}
	body .header{-webkit-transform:translate(0);-ms-transform:translate(0);transform:translate(0);height:60px;position:fixed;width:100%;z-index:999;}
	.header-res .sidelinks .nav-link{color:#fff;text-decoration:none;padding:0;font-size:25px;line-height:28px;display:inline-block;}
	.header-res .sidelinks .nav-link.is-new{padding:0 0 1px 34px;background:url('../assets/images/new-menu-tag.png') no-repeat left center;background-size:contain;}
	.header-res .nav-watchlist a.nav-link{margin:0;font-size:25px;line-height:28px;display:inline-block;}
	.header+div{padding-top:60px;}
	.top-header button.logout-head{background-color:#000!important;border-radius:0!important;color:#fff!important;text-transform:uppercase;font-weight:700!important;font-size:20px!important;font-family:"arial"!important;}

	.header-res .sidelinks ul.new-user{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;}
	.header-res .sidelinks ul.new-user li{width:100%;margin:0 0 10px;}
	.header-res .sidelinks ul.new-user li:last-child{margin:0;}

	.sub-mega-menu .brand-logo-sct img{max-width:115px;}
	.megamenu li.nav-item.jordan-submenu .sub-mega-menu{min-width:70%;}
	.megamenu li.nav-item.adidas-submenu .sub-mega-menu{min-width:65%;}
	.productdetail-main-blk .slider-cnt{align-items:flex-start;}
	.productdetail-main-blk .slider-img-cnt{margin:0;padding:0}
	.productdetail-main-blk .slider-cnt .col-slide-left{width:calc(100% - 500px);padding-right:0;margin:0 0 40px}
	.productdetail-main-blk .slider-cnt .col-slide-right{width:500px;padding-left:20px}
	.productdetail-main-blk .slider-cnt .slick-dots{bottom:0;display:flex!important;justify-content:center;align-items:center;line-height:1}
	.productdetail-main-blk .slider-cnt .slick-dots li{margin:0;width:100%;height:6px;line-height:1;background-color:#d7d7d7}
	.productdetail-main-blk .slider-cnt .slick-dots li.slick-active{background-color:#707070}
	.productdetail-main-blk .slider-cnt .slick-dots li button{display:none;}
	.product-main-list-blk .product-list-header.search-with-filter .prod-list-title-wrap{width:calc(100% - 400px)}
	.product-main-list-blk .product-list-header.search-with-filter .filters-blk-wrap{width:400px}
	.productdetail-main-blk .slider-cnt .slick-prev,.productdetail-main-blk .slider-cnt .slick-next{display:none !important}
	.img-scrollar-popup .slider-img-cnt{margin:0 0 10px}
	.watchlist-wrap .releasedate-blk{padding-top:0;}
	.terms-privacy-main{padding:0;}

	.slider-cnt-placeholder .slider-img-cnt:not(:first-child){display:none;}
	.slider-cnt-placeholder .slider-img-cnt{width:100%;}
	.slider-cnt-placeholder .prod-img-cnt-blk{height:430px;}
	.slider-cnt-placeholder .prod-sku-table ul.info-count li{height:26px;}
	.productdetail-main-blk .single-image-blk span {font-size:22px;width:auto;}

	.home-wrap .releasedate-blk:first-child{padding-top:0}
	.banner-carousel{min-height:450px}
	.banner-carousel .carousel-inner .carousel-item{height:450px;}
	.live-updates-blk .prod-details{height:calc(100% - 160px);padding:10px 10px 30px;}
	.live-updates-blk .prod-images{height:160px;}
	.live-updates-blk .prod-images.has-1-image>div{height:160px}
	.live-updates-blk .prod-images.has-3-image>div{height:80px;}
	.live-updates-blk .prod-images.has-3-image>.prod-image-0{height:160px;}
	.live-updates-blk .prod-images.has-4-image>div{height:80px;}
}
@media (min-width:992px){
	.closeicon-btn{display:none;}
	.top-header .right-head .sidelinks .nav-link.signup:hover{background-color:rgba(0,0,0,0.9);}
	.home-wrap .prod_list button.fullbtn.view-more,
	.watchlist-wrap .prod_list button.fullbtn.view-more{font-size:22px}
	.top-header .right-head .sidelinks ul.new-user li.signup-link{display: none}
}
@media (max-width:992px){
	.latestnews-wrapper .slick-prev,
	.latestnews-wrapper .slick-next{top:26%;}
	.staffpick-blk .latestnews-wrapper .slick-prev,
	.staffpick-blk .latestnews-wrapper .slick-next{top:34%;}
	.you-may-slider .latestnews-wrapper .slick-prev,
	.you-may-slider .latestnews-wrapper .slick-next{top:22%;}
	.latestnews-wrapper .slick-prev{left:5px;}
	.latestnews-wrapper .slick-next{right:5px;}
	.button-container{padding:0}
	.signModal .container .modal-dialog .modal-content .modal-body .img-block,
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block{width:100%}
	.signModal .container .modal-dialog .modal-content .modal-body{-ms-flex-wrap:wrap;flex-wrap:wrap}
	.container{max-width:100%;}
	footer ul.copyright{text-align:right;}
	.banner-carousel{min-height:420px}
	.banner-carousel .carousel-inner .carousel-item{height:420px;}
	.banner-carousel .carousel-caption a,
	.banner-carousel .carousel-caption span{font-size:20px;}
	.banner-carousel .carousel-caption{padding: 0 45px 0 20px;}
	
	.banner-carousel .carousel-indicators{margin-bottom:0;max-width:100%;padding:0 30px;}
	.banner-carousel .carousel-indicators li{height:3px;}
	.main_content.wrapp{padding:20px 0 50px;min-height:calc(100vh - 415px);}
	.ltst-news-blk-image{height:auto;margin:0 0 15px}
	.sidebar{display:none;}
	.sidebar.active{display:block;position:fixed!important;top:0!important;background:#000;width:100%;left:0;z-index:999;color:#fff;margin-top:60px;height:calc(100vh - 50px);overflow:scroll}
	body.nav-up.sidebar-active .header{-webkit-transform:none;-ms-transform:none;transform:none;}
	input.form-control.search-input-cntrl{background:transparent;border:0;border-top:1px solid #242424;border-bottom:1px solid #4d4d4d;border-radius:0;font-size:24px;color:#656565;font-style:italic;width:100%;padding:20px 35px}
	input.form-control.search-input-cntrl::-webkit-search-cancel-button{-webkit-appearance:none;}
	input.form-control.search-input-cntrl::-webkit-input-placeholder{color:#656565;}
	input.form-control.search-input-cntrl::-moz-placeholder{color:#656565;}
	input.form-control.search-input-cntrl:-ms-input-placeholder{color:#656565;}
	input.form-control.search-input-cntrl::-ms-input-placeholder{color:#656565;}
	input.form-control.search-input-cntrl::placeholder{color:#656565;}
	input.form-control.search-input-cntrl:focus{outline:none;-webkit-box-shadow:none;box-shadow:none}
	.serach-responsive button.btn.btn-close{padding:0;border:0;position:absolute;right:15px;top:10px;outline:none;-webkit-box-shadow:none;box-shadow:none;background-image:url('../assets/images/closeicon-white.svg');width:26px;height:26px;background-size:100%;}
	.sidebar .sidebox h3{padding:15px 35px;position:relative;border-bottom:1px solid #4d4d4d;border-top:1px solid #4d4d4d;font-family:"Arial";font-weight:bold;font-size:20px;text-transform:uppercase;}
	.sidebar .sidebox + .sidebox{margin-top:0;}
	.sidebar .sidebox h3{border-top:0;margin-bottom:0;}
	.sidebar .sidebox ul{padding:8px 35px 10px}
	.sidebar .sidebox ul li span{color:#7a7a7a;font-size:20px}
	.sidebar .sidebox ul li{font-size:20px;}
	span.search-error-msg{display:none}
	.signModal .container .modal-dialog .modal-content .modal-body .img-block img{-o-object-fit:cover;object-fit:cover;height:100%;width:100%;}
	.signModal .container .modal-dialog .modal-content .modal-body .img-block{height:300px}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block{padding:30px 30px 50px}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block .navbar-brand{margin-bottom:30px;}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block h3{font-size:29px;line-height:22px;}
	.sign-block button.google-signup,
	.sign-block button.apple-signup,
	.sign-block span.or{margin-top:8px;}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row label.form-check-label,
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block p{font-size:14px;}
	.sign-block button.signup-btn,
	.sign-block .signin-btn{font-size:12.35px;line-height:14px;height:34px;}
	.sign-block button.google-signup,
	.sign-block button.apple-signup{font-size:12.35px!important;}
	.sign-block .kf-login-buttons button.google-signup,
	.sign-block .kf-login-buttons button.apple-signup{font-size:10px!important}
	.sign-block button.google-signup:before,
	.sign-block button.apple-signup:before{width:33px;font-size:14px;}
	.sign-block .kf-login-buttons button.google-signup span, .sign-block .kf-login-buttons button.apple-signup span{padding:0 0 0 33px!important}
	
	.sidebar-active .carousel{display:none}
	.sidebar-active .header+div{padding-top:0;}
	.sidebar-active{overflow:hidden;}
	.top-header button.logout-head>span{font-weight:400!important;font-size:20px!important}
	.about-img-conatiner{margin:30px 0;}
	.about-page .cms-cnt-txt{padding:40px 0;}
	.about-page .cms-page-title{font-size:43px;line-height:48px;}
	.cms-page h2{font-size:50px;line-height:55px;}
	.cms-page h3{font-size:35px;line-height:40px;}
	.closeicon-btn img{max-width:20px;}
	.sidebar .sidebox h3:after{content:"";display:inline-block;background-size:100%;background-image:url('../assets/images/downarrow.svg');height:15px;width:25px;position:absolute;right:15px;background-repeat:no-repeat;top:22px;-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg);}
	.sidebar .sidebox ul li.active span{color:#fff;font-weight:normal;position:relative}
	.sidebar .sidebox ul li span:hover{font-family:"helvetica-normal"}
	.sidebar .sidebox ul{display:none;}
	.sidebar .sidebox ul.ul-active{display:block;border-bottom:1px solid #4d4d4d;}
	.sidebar .sidebox.sidebox-active h3:after{-webkit-transform:rotate(0deg);-ms-transform:rotate(0deg);transform:rotate(0deg);}
	.sidebar .sidebox.by-brand ul li.active span:after{content:"";display:inline-block;background-image:url('../assets/images/cross-close-res.svg');height:27px;width:22px;position:absolute;background-repeat:no-repeat;background-size:100%;margin:3px 10px;}
	.filter-btn-div{padding:25px 40px;}
	button.filter-btn{font-size:20px;font-family:arial;text-transform:uppercase;font-weight:bold;width:100%;border-radius:40px;border:0;padding:10px;background:#979797;color:#000;}
	.header-res .aboutnav-links li a,
	.header-res .aboutnav-links li{font-weight:normal;}
	.cms-cnt-wrapper{margin:60px 0 60px;}
	.cms-page-title{font-size:50px;line-height:55px;}
	.cms-page:not(.about-page) .cms-page-title{text-align:center;margin:50px 0;}
	.cms-page h3{font-size:30px;line-height:35px;}
	.cms-page p{font-size:22px;line-height:34px;margin-bottom:40px;}
	.cms-cnt-txt p + ul,
	.cms-cnt-txt p + ol{margin-top:-40px;}
	.cms-page ul,
	.cms-page ol{margin-bottom:40px;}
	.cms-page ul li,
	.cms-page ol li{font-size:22px;line-height:34px;}
	.cms-page ul li:before{top:15px;}
	.cms-page hr{margin:60px 0 20px;}
	.latestnews-wrapper .latestnews-blk-cnt{margin:0 -10px;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;}
	.label-404{font-size:140px;line-height:normal;}
	.title-404{font-size:28px;line-height:38px;text-align:center;margin-top:0;}
	.search-wrap-404 .search-404-control input{font-size:24px;padding:10px 0;}
	.search-404-control button.btn.btn-search{top:4px}
	.search-404-control button.btn.btn-search i{font-size:26px;}
	.search-below-cnt{margin-top:50px;}
	h4.search-title{font-size:26px;text-align:center;}
	.productdetail-main-blk .latestnews-wrapper .latestnews-blk-list:nth-child(2n+1),
	.search-product-listing .allproducts >li:nth-child(2n+1){clear:both;}
	.search-more-btn{padding:0}
	.cnt-wrapper-404{margin:50px 0 0}
	.productdetail-cnt .prod-footer-cnt .product-item .infos .info-count li .tooltip{left:25%;}
	.productdetail-cnt .prod-footer-cnt .product-item .infos .info-count li .tooltip .arrow{left:70%;}
	
	.productdetail-main-blk .prod-below-cnt,
	.productdetail-main-blk .prod-footer-cnt{padding:0 15px}
	h4.cntry-title{font-size:24px;margin-bottom:20px;}
	table.prod-table tr{padding:10px 0;justify-content:space-between;}
	table.prod-table tbody tr td.retailer-col,
	table.prod-table thead tr th.retailer-col{width:32%}
	table.prod-table tbody tr td{padding:0 10px;line-height:normal;}
	table.prod-table tbody tr td.retailer-col{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1;}
	table.prod-table tbody tr td.time-col{-webkit-box-ordinal-group:6;-ms-flex-order:5;order:5;width:60%;text-align:left;color:#707070;padding:0 10px 0 0;}
	table.prod-table tbody tr td.ships-col{-webkit-box-ordinal-group:7;-ms-flex-order:6;order:6;width:auto;min-width:auto;font-size:18px;color:#707070;padding-right:10px;display:none;}
	table.prod-table tbody tr td.release-type{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2;width:auto;min-width:auto;color:#707070;}
	table.prod-table tbody tr td.btn-col{-webkit-box-ordinal-group:4;-ms-flex-order:3;order:3;padding:0 10px;}
	table.prod-table tbody tr td.reminder-col{-webkit-box-ordinal-group:8;-ms-flex-order:7;order:7;width:40%;min-width:auto;font-size:18px;color:#707070;}
	table.prod-table tbody tr td.reminder-col > span,
	table.prod-table tbody tr td.reminder-col > span > span:first-child:last-child{justify-content:flex-end;text-align:right!important;}
	table.prod-table thead tr th.res-arrow,
	table.prod-table tbody tr td.res-arrow{-webkit-box-ordinal-group:5;-ms-flex-order:4;order:4;width:5%;display:-webkit-inline-box!important;display:-ms-inline-flexbox!important;display:inline-flex!important;padding:0 5px 0 0}
	table.prod-table tbody tr td.res-arrow i{font-size:32px;color:#a7a9ab}
	table.prod-table tbody tr td.release-type,
	table.prod-table thead tr th.release-type{width:31%;}
	table.prod-table tbody tr td.btn-col,
	table.prod-table thead tr th.btn-col{width:31%;text-align:right;}
	table.prod-table tbody tr td.btn-col .tbl-btn-wrapper{text-align:right;}
	.productdetail-main-blk table.prod-table thead tr th.ships-col,
	.productdetail-main-blk table.prod-table thead tr .time-col,
	.productdetail-main-blk table.prod-table thead tr th.reminder-col,
	.productdetail-main-blk table.prod-table tbody tr:not(.is_open) td.ships-col,
	.productdetail-main-blk table.prod-table tbody tr:not(.is_open) td.time-col,
	.productdetail-main-blk table.prod-table tbody tr:not(.is_open) td.reminder-col{display:none}
	.productdetail-main-blk table.prod-table tbody tr.is_open td.res-arrow i::before{content:"\f0d8"}
	.productdetail-main-blk table.prod-table tbody tr:not(.is_open) td{padding-bottom:0}
	.productdetail-main-blk .timezone-select{display:none}
	.productdetail-main-blk{margin:60px 0}
	.productdetail-main-blk .slider-img-cnt{padding:0}
	.productdetail-main-blk .slider-cnt .col-slide-left{width:100%;padding-right:0;}
	.productdetail-main-blk .slider-cnt .col-slide-right{width:100%;padding-left:0;}
	.productdetail-main-blk .slick-prev{left:0}
	.productdetail-main-blk .slick-next{right:0}
	.productdetail-main-blk ul.info-count i.fire svg{vertical-align:middle;}
	.productdetail-main-blk .slide-title h4{margin-bottom:2px;}
	table.prod-table thead tr th,
	table.prod-table tbody tr td{min-height:35px;}
	.productdetail-main-blk .prod-footer-cnt span.news-viewmore-btn{display:none;}
	.productdetail-main-blk .prod-footer-cnt .latestnews-title{display:block;border:0}
	.productdetail-main-blk .prod-below-cnt,
	.productdetail-main-blk .prod-footer-cnt{padding:0}
	.responsive-filter ul.filter-list{padding:0;list-style:none;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;}
	.responsive-filter ul.filter-list li{margin:0 12px 10px;font-size:20px;font-family:arial;color:#fff;background:#000;padding:8px 20px;border-radius:17px;line-height:normal;}
	.responsive-filter ul.filter-list li .filter-res-close{background-image:url('../assets/images/closeicon.png');height:16px;width:16px;background-size:100%;background-repeat:no-repeat;display:inline-block;margin-left:5px;}
	.responsive-filter input.form-control.search-input-cntrl{font-style:normal;text-transform:capitalize;border-top:0;height:55px;}
	.responsive-filter form{margin-bottom:20px;position:relative}
	.responsive-filter form button.btn-close{background-image:url('../assets/images/closeicon.svg');height:30px;width:30px;background-size:100%;top:15px;position:absolute;right:15px;background-repeat:no-repeat;}
	.release-soon-blk{margin-top:50px}
	.productdetail-main-blk .prod-footer-cnt ul.info-count i.fire svg{margin-top:-5px}
	.sign-block form .form-row input:checked + label:after{top:4px;}
	.allproducts li .product-item li[title="Likes"] i:hover svg path,
	.productdetail-cnt .slider-cnt ul.info-count li[title="Likes"] i:hover svg path,
	.productdetail-cnt .product-item li[title="Likes"] i:hover svg path{fill:none!important;stroke:rgb(115,115,115)!important;}
	.allproducts li .product-item li[title="Likes"].active i svg path,
	.prod-info-count .info-count li[title="Likes"].active i svg path,
	.productdetail-cnt .slider-cnt ul.info-count li[title="Likes"].active i svg path,
	.productdetail-cnt .product-item li[title="Likes"].active i svg path{fill:#ea953d!important;stroke:#ea953d!important;}
	.allproducts li .product-item li[title="Watchlist"] i:hover,
	.productdetail-cnt .product-item li[title="Watchlist"] i:hover,
	.productdetail-cnt .slider-cnt ul.info-count li[title="Watchlist"] i:hover{color:#737373;}
	.allproducts li .product-item li[title="Watchlist"] i:hover:before,
	.productdetail-cnt .product-item li[title="Watchlist"] i:hover:before,
	.productdetail-cnt .slider-cnt ul.info-count li[title="Watchlist"] i:hover:before{content:"\f097"}
	.allproducts li .product-item li[title="Watchlist"].active i,
	.prod-info-count .info-count li[title="Watchlist"].active i,
	.productdetail-cnt .product-item li[title="Watchlist"].active i,
	.productdetail-cnt .slider-cnt ul.info-count li[title="Watchlist"].active i{color:#000}
	.allproducts li .product-item li[title="Watchlist"].active i:hover:before,
	.productdetail-cnt .product-item li[title="Watchlist"].active i:hover:before,
	.productdetail-cnt .slider-cnt ul.info-count li[title="Watchlist"].active i:hover:before{content:'\f02e';}
	.productdetail-main-blk .prod-footer-cnt .latestnews-wrapper .slick-prev{left:0}
	.productdetail-main-blk .prod-footer-cnt .latestnews-wrapper .slick-next{right:0}
	table.prod-table tbody tr td span>span.text-center{text-decoration:none!important;}
	.search-more-btn button.fullbtn.view-more{font-size:inherit;}
	h6.notification-bar{top:60px;font-size:25px;line-height:28px;}
	.productdetail-cnt .prod-counrywise-tbl:not(:last-child){margin-bottom:30px;}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block h3 span{font-size:11px;line-height:15px;}
	#signupModal.signModal .container .modal-dialog .modal-content .modal-body .sign-block h3 span strong{font-weight:normal;}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block p{margin-bottom:40px;}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input{font-size:11px;line-height:21px;height:30px;}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input::-webkit-input-placeholder{font-size:11px;line-height:21px;}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input::-moz-placeholder{font-size:11px;line-height:21px;}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input:-ms-input-placeholder{font-size:11px;line-height:21px;}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input::-ms-input-placeholder{font-size:11px;line-height:21px;}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row input::placeholder{font-size:11px;line-height:21px;}
	#signinModal h3{font-size:16.76px;line-height:21px;}
	.signModal#forgotPwdModal h3,
	.signModal#resetModal h3{font-size:16.76px;line-height:21px;}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block h3 span.account-link{font-size:11px;line-height:21px;}
	h4.user-title{margin-bottom:40px;}
	.usersetting-wrapper{margin:50px 0;}
	h4.user-title .user-close-icon{top:-40px;}
	.staffpick-blk .slick-initialized .slick-slide,
	.availablenow-blk .slick-initialized .slick-slide,
	.sneakernews-blk .slick-initialized .slick-slide,
	.productdetail-main-blk .sneakernews-blk .slick-slide,
	.productdetail-main-blk .you-may-slider .slick-slide{padding:0 10px;}
	.staffpick-blk .latestnews-wrapper .slick-list,
	.availablenow-blk .latestnews-wrapper .slick-list,
	.sneakernews-blk .latestnews-wrapper .slick-list,
	.productdetail-main-blk .sneakernews-blk .slick-list,
	.productdetail-main-blk .you-may-slider .slick-list{margin:0 -10px;}
	.allproducts{margin:0 -10px}
	.allproducts>li{padding:0 10px;width:33.333333333333336%}
	.allproducts a.product-img{height:180px}
	.availablenow-blk .slick-slide img,
	.staffpick-blk .slick-slide img,
	.sneakernews-blk .slick-slide img,
	.productdetail-main-blk .you-may-slider .product-img img{min-height:140px;max-height:140px;}
	.latestnews-wrapper.slider-placeholder{min-height:200px}
	.latestnews-wrapper.slider-placeholder .product-item{min-height:140px}
	.productdetail-main-blk .single-image-blk{min-height:660px;}

	.slider-cnt-placeholder .prod-img-cnt-blk{height:750px;}
	.slider-cnt-placeholder .prod-sku-table ul.info-count li{height:35px;}
	.slider-cnt-placeholder .slide-title h4{margin-bottom:20px;}
	.slider-cnt-placeholder .slider-cnt .prod-count-release{margin-bottom:30px}
	.slider-cnt-placeholder .post-disclamier-blk span{height:50px;}

	/* about us */
	.about-us-page .cms-cnt-inner{padding:60px 0;}
	.about-us-page .cms-cnt-inner .cms-cnt-wrapper{margin:60px 0 0 0;padding:0;width:100%;}
	.about-us-page .cms-cnt-inner .cms-img-conatiner{width:100%;padding:0;}

	.prod-sku-table th:nth-child(1), .prod-sku-table td:nth-child(1){width:30%;}
}

@media (max-width:899px){
	.latestnews-wrapper .latestnews-blk-cnt .product-item{width:50%;padding:0 10px;margin:0 0 30px;}
	.latestnews-wrapper .latestnews-blk-cnt .product-img{text-align:center;}
	.latestnews-wrapper .latestnews-blk-cnt .product-img img{width:100%;height:100%;-o-object-fit:cover;object-fit:cover;-o-object-position:center;object-position:center;min-height:200px;max-height:200px;}
	.you-may-slider .latestnews-wrapper .slick-prev,
	.you-may-slider .latestnews-wrapper .slick-next{top:20%;}
	.productdetail-main-blk .single-image-blk{min-height:600px;}

	/* homepage */
	.home-wrap .pad-50-blk{padding:0 15px}
	.availablenow-blk .slick-slide img,
	.staffpick-blk .slick-slide img,
	.sneakernews-blk .slick-slide img,
	.productdetail-main-blk .you-may-slider .product-img img{min-height:120px;max-height:120px;}
	.latestnews-wrapper.slider-placeholder .product-item{min-height:120px}
	.product-blk-wrap{padding:0;}
	footer .container{padding:0 15px;}
	.watchlist-wrap .container{padding:0 15px;}
	.live-updates-blk .prod-details{height:calc(100% - 150px);padding:10px 10px 30px;}
	.live-updates-blk .prod-images{height:150px;}
	.live-updates-blk .prod-images.has-1-image>div{height:150px}
	.live-updates-blk .prod-images.has-3-image>div{height:75px;}
	.live-updates-blk .prod-images.has-3-image>.prod-image-0{height:150px;}
	.live-updates-blk .prod-images.has-4-image>div{height:75px;}
}

@media (min-width:420px) and (max-width:850px) and (orientation:landscape){
	.signModal .container .modal-dialog .modal-content .modal-body .img-block img{-o-object-fit:cover;object-fit:cover;height:100%;width:100%;}
	.signModal .container .modal-dialog .modal-content .modal-body .img-block{height:400px}
}
@media (min-width:420px) and (max-width:1200px) and (orientation:landscape){
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row label.form-check-label{width:100%;margin-bottom:3px;padding:0 3px;}
}
@media (max-width:830px){
	.banner-carousel{min-height:390px}
	.banner-carousel .carousel-inner .carousel-item{height:390px;}
}
@media (max-width:767px){
	.sticky-loader-wrap{padding:60px 0 10px}
	.latestnews-wrapper .slick-prev,
	.latestnews-wrapper .slick-next{top:39%;}
	.staffpick-blk .latestnews-wrapper .slick-prev,
	.staffpick-blk .latestnews-wrapper .slick-next{top:42%;}
	.you-may-slider .latestnews-wrapper .slick-prev,
	.you-may-slider .latestnews-wrapper .slick-next{top:39%;}
	.kf-warning{padding:15px 20px 15px 60px;font-size:14px;line-height:16px;margin:11px 0;}
	.kf-warning i{left:20px}
	.large-main-title{font-size:20px;line-height:23px;}
	.allproducts{margin:0 -10px;}
	.allproducts>li{width:50%}
	.allproducts>li a.product-img{width:100%;display:block;text-align:center;height:205px;}
	.top-header .right-head{-ms-flex-wrap:wrap;flex-wrap:wrap;}
	.top-header .right-head .sidelinks{margin:15px 0 0;}
	footer{padding: 24px 0 12px;}
	footer .footer-main-row{position:relative;}
	footer .footer-logo{width:100%;padding-right:90px;margin-bottom:15px;}
	footer .footer-social{position:static;}
	footer ul.socialmedia{position:absolute;top:0;right:15px;width:auto;}
	footer .footer-nav{width:100%;padding-left:0;margin:0 0 50px;}
	footer ul.copyright li{font-size:9px;line-height:10px;margin-left: 30px;}
	footer ul.storelist li{margin-left:7px}
	.top-header button.logout-head{font-size:16px!important}
	.top-header button.logout-head>span{font-size:16px!important;font-weight:bold!important;}
	.header+div{padding-top:50px;}
	body .header{height:50px;}
	.top-header{height:50px;padding:10px 0;}
	.header-res nav.navbar.navbar-dark button.navbar-toggler span{margin-top:5px;}
	.top-header span.timezone-cnt{padding-right:20px;}
	.header-res .navbar-collapse{top:50px;padding:20px 15px;max-width:291px}
	footer ul.linklist li:last-child,
	footer ul.loggedOutlist li:last-child{margin-bottom:6px;}
	footer ul.linklist li,footer ul.loggedOutlist li{font-size:10px;margin-bottom:10px;line-height:11px;}
	footer .storelist .footer-img{margin-bottom:6px;max-width:85px;}

	/* User Setting */
	span.user-set-span{padding-right:15px;padding-left:0;line-height:normal;width:calc(100% - 30px);display:inline-block;word-break:break-word;vertical-align:middle;}
	label.user-set-lbl{width:100%;}
	.user-setting-row span.span-newsletter{width:100%;padding:0;}
	span.edittxt{font-size:17px;line-height:normal;text-align:right;display:inline-block;vertical-align:middle;}
	.user-setting-row{padding:10px 0}
	button.fullbtn.view-more{height:50px}
	.latestnews-wrapper{margin:36px 0 0;min-height:100px}
	.sneakernews-blk .latestnews-wrapper{margin-top:25px;}
	.latestnews-title{font-size:20px;line-height:23px;border-bottom:1px solid #888888;padding-bottom:5px;margin-bottom:15px;}
	.latestnews-text-blk h5{font-size:18px;line-height:24px;margin-bottom:0;}
	.latestnews-blk-list p.post-detail{font-size:14px;}
	.tgl + .tgl-btn{margin:8px 0}
	.banner-carousel{min-height:320px}
	.banner-carousel .carousel-indicators{padding:0 15px;}
	.banner-carousel .carousel-inner .carousel-item{height:320px}
	.banner-carousel .carousel-caption span{display:none;}
	.profileUpdatemsg{width:100%;margin:30px 0 0;}
	.allproducts li .product-item .infos .info-count li .tooltip,
	.productdetail-cnt .product-item .infos .info-count li .tooltip{left:35%;}
	.allproducts li .product-item .infos .info-count li .tooltip .arrow,
	.productdetail-cnt .product-item .infos .info-count li .tooltip .arrow{left:63%;}
	.productdetail-main-blk ul.info-count i.fire svg{vertical-align:middle;max-width:initial}
	.productdetail-main-blk ul.info-count i.eye-icon svg{max-width:32px;}
	.productdetail-main-blk .release-soon-blk ul.info-count i.fire svg{vertical-align:inherit;}
	.productdetail-main-blk .slider-cnt{margin-bottom:32px;}
	.productdetail-main-blk .prod-below-cnt{margin-top:60px;}
	.productdetail-main-blk .prod-cat-row{margin:0 0 70px;}
	.you-may-slider .product-item .prod-details{width:100%;text-align: center;}
	.productdetail-cnt .product-item .prod-details .prod-price,
	.productdetail-cnt .product-item .prod-list-new-cnt .prod-detail-cnt,
	.productdetail-cnt .product-item .infos{display:none;}
	.productdetail-main-blk .loadmore-btn{margin-top:10px;}
	.productdetail-main-blk .popular-prod-blk .loadmore-btn{margin-top:15px;}
	.sidebar.active{margin-top:50px;}
	.watchlist-wrap .large-main-title{font-size:32px;line-height:36px;}
	.watchlist-not-found{height:400px;}
	.watchlist-not-found .wtch-col-img{width:50px;}
	.watchlist-not-found .wtch-col-content{width:calc(100% - 50px);padding-left:10px;}
	.watchlist-not-found .wtch-col-content h3{font-size:14px;line-height:16px;margin:0 0 10px;}
	.watchlist-not-found .info-count li .bookmark-icon:before{width:24px;height:24px;border-width:2px;top:-3px;left:-9px;}
	.watchlist-not-found .info-count li{font-size:14px;line-height:16px;}
	.watchlist-not-found .info-count li i.eye-icon svg{width:12px;height:8px;}
	.watchlist-not-found .info-count li i.fire svg{width:8px;height:11px;}
	.watchlist-not-found .bookmark-icon svg{width:7px;height:9px;}
	.watchlist-not-found .wtch-col-content p{font-size:9px;line-height:11px;margin:0 0 10px;}
	.watchlist-wrap .product-main-list-blk .product-list-header{margin:0 0 10px;padding:0;border-bottom:0}
	.watchlist-wrap .product-main-list-blk .product-list-header .filters-blk-wrap > .filter-btn{top:5px}
	.btn-setting{width:242px;margin:6px 0 10px 6px;padding:8px 15px;}
	.btn-logout{margin:6px 6px 10px 0;padding:8px 15px;}
	.user-setting-row .logout-head{margin:6px 6px 10px 0;padding:8px 15px!important;}
	.productdetail-main-blk .single-image-blk{min-height:550px;}

	/* cms pages */
	.cms-column-layout .cms-row .cms-col:first-child{width:100%;}
	.cms-column-layout .cms-row .cms-col:last-child{width:100%;}
	.about-page.cms-column-layout .cms-row .cms-col:first-child{width:100%;}
	.about-page.cms-column-layout .cms-row .cms-col:last-child{width:100%;}
	.about-page .cms-cnt-txt{padding:10px 0 10px;}
	.cms-page-title{font-size:40px;line-height:45px;}
	.cms-page:not(.about-page) .cms-page-title{text-align:center;margin:30px 0;}
	.cms-page h3{font-size:28px;line-height:32px;}
	.cms-page p{font-size:20px;line-height:32px;margin-bottom:30px;}
	.cms-cnt-txt p + ul,
	.cms-cnt-txt p + ol{margin-top:-30px;}
	.cms-page ul,
	.cms-page ol{margin-bottom:30px;}
	.cms-page ul li,
	.cms-page ol li{font-size:20px;line-height:32px;}
	.cms-page ul li:before{top:13px;}
	.cms-page hr{margin:50px 0 20px;}
	.allproducts li .product-item i.fire svg,
	.productdetail-cnt .product-item i.fire svg{margin-top:-5px}
	.user-set-popup-block h2{font-size:26px;margin-top:0px;margin-bottom:20px;}
	.user-set-popup-block p{font-size:16px;}
	.user-setting-blk .cst-select{margin:0}
	h4.user-title .user-close-icon svg{width:17px;}
	h6.notification-bar{top:50px;}

	/* home */
	.main-title{font-size:20px;line-height:23px;}
	span.sub-title{display:none;}
	.popular-product-inner-lisitng{height:auto;min-height:200px}
	.prod-list-new{width:100%;margin-right:0;}
	.loadmore-btn{margin-top:16px;}
	.prod--main-title{font-size:20px;line-height:23px;}
	.prod-list-new .item-list-counter{width:110px;}
	.prod-list-new .item-list-time{width:65px;}
	.item-name{width:calc(100% - 219px);font-size:11px;line-height:12px;}
	.item-time{font-size:9px;line-height:10px;}
	span.item-number{width:50px;font-size:24px;line-height:27px;}
	span.item-rank{font-size:16px;line-height:18px;}
	span.filter-btn{font-size:14px;line-height:16px;padding:6px 10px;}
	span.filter-btn svg{width:13px;}
	span.filter-span{font-size:11px;line-height:13px;margin-right:7px}
	.selected-filters .reset-filter{font-size:8px;line-height:9px;}
	.filters-blk.active-filter-blk{padding:12px;}
	.filter-row .filter-col-left label{font-size:11px;line-height:13px;margin:5px 5px 5px 0;}
	.filters-btn-blk button.apply-filter-btn{font-size:8px;line-height:9px;margin-bottom:5px;}
	.filters-btn-blk button.cancel-btn{font-size:8px;line-height:9px;}
	.filter-select-wrapper.filter-by-year{min-width:63px;}
	.filter-select-wrapper.filter-by-month{min-width:81px;}
	.filter-select-wrapper.filter-by-brand,
	.filter-select-wrapper.filter-by-modal{max-width:111px;}
	.staffpick-blk .prod-name a,
	.availablenow-blk .prod-name a,
	.sneakernews-blk .prod-name a,
	.sneakernews-blk .prod-name,
	.productdetail-cnt .product-item .prod-details .prod-name a,
	.productdetail-cnt .product-item .prod-details .prod-name{font-size:8px;line-height:9px;}
	.staffpick-blk .prod-name .prod-color,
	.availablenow-blk .prod-name .prod-color{font-size:7px;line-height:8px;}
	.staffpick-blk .prod-name,
	.availablenow-blk .prod-name,
	.sneakernews-blk .prod-name{margin:0 0 1px;white-space:normal;line-height:9px;}
	.availablenow-blk .prod-price,
	.productdetail-cnt .product-item .prod-date{font-size:7px;line-height:8px;}
	.sneakernews-blk .latestnews-blk-list p.post-detail{font-size:7px;line-height:8px;margin-top:2px;}
	.staffpick-blk .post-date{font-size:7px;line-height:9px;}
	.staffpick-blk .product-img,
	.availablenow-blk .product-img,
	.sneakernews-blk .product-img{margin-bottom:5px}
	.staffpick-blk .slick-initialized .slick-slide,
	.availablenow-blk .slick-initialized .slick-slide,
	.sneakernews-blk .slick-initialized .slick-slide,
	.productdetail-main-blk .sneakernews-blk .slick-slide,
	.productdetail-main-blk .you-may-slider .slick-slide{padding:0 4px;}
	.staffpick-blk .latestnews-wrapper .slick-list,
	.availablenow-blk .latestnews-wrapper .slick-list,
	.sneakernews-blk .latestnews-wrapper .slick-list,
	.productdetail-main-blk .sneakernews-blk .slick-list,
	.productdetail-main-blk .you-may-slider .slick-list{margin:0 -4px;}
	.availablenow-blk .prod-list-title-wrap,.sneakernews-blk .prod-list-title-wrap,.staffpick-blk .prod-list-title-wrap, .live-updates-blk .prod-list-title-wrap{margin-bottom:12px;}
	.staffpick-blk .slick-slider,.availablenow-blk .slick-slider,.sneakernews-blk .slick-slider, .productdetail-main-blk .you-may-slider .slick-slider{padding-bottom:0;border:none;}
	.availablenow-blk .slick-slide img,
	.staffpick-blk .slick-slide img,
	.sneakernews-blk .slick-slide img,
	.productdetail-main-blk .you-may-slider .product-img img{min-height:150px;max-height:150px;}
	.productdetail-main-blk .prod-footer-cnt .latestnews-wrapper{margin-top:28px;}
	.releasedate-blk .product-main-list-blk .loadmore-btn{margin-top:10px;}
	.live-updates-blk .product-item{border-radius:20px;}
	.live-updates-blk .latestnews-wrapper .latestnews-blk-cnt{margin:0 -5px;}
	.live-updates-blk .prod-details{height:calc(100% - 140px);padding:10px 10px 30px;}
	.live-updates-blk .latestnews-wrapper .latestnews-blk-cnt .product-item{margin:0 5px;padding:0;width:28.5%;}
	.live-updates-blk .prod-images{height:140px;}
	.live-updates-blk .prod-images.has-1-image>div{height:140px}
	.live-updates-blk .prod-images.has-3-image>div{height:70px;}
	.live-updates-blk .prod-images.has-3-image>.prod-image-0{height:140px;}
	.live-updates-blk .prod-images.has-4-image>div{height:70px;}
	.live-updates-blk .prod-name{white-space:normal;font-size:8px;line-height:9px;}
	.live-updates-blk .post-time{font-size:7px;line-height:8px;bottom:15px;left:10px;}
	.live-updates-blk .prod-name a pre{font-size:8px;line-height:9px;}

	/* about us */
	.about-us-page .cms-cnt-inner{padding:40px 0;}
	.about-us-page .cms-cnt-inner .cms-cnt-wrapper{margin:40px 0 0 0;}
	.about-us-page form > .col-lg-12{margin:0 0 13px;}

	/* search */
	.search-txt h5{font-size:14px;line-height:16px;margin-bottom:10px;}
	.search-txt p{font-size:9px;line-height:11px;}
	.search-alert-icon{width:50px;}

	.prod-cat-title{margin-bottom:10px;}
	.prod-cat-row .prod-cat-body .retailer-not-found{min-height:140px;font-size:22px;background-color:#fcfcfc;box-shadow:0px 4px 10px 3px rgb(0 0 0 / 25%);border-radius:6px;padding:30px;}
	.prod-cat-row .prod-head-info .prod-head-collout{font-size:14px;padding:15px 30px 15px 55px;}
	.prod-cat-row .prod-head-info .prod-head-collout i{left:16px;}
	h4.cntry-title{font-size:17px;line-height:20px;margin-bottom:10px}
	table.prod-table tbody tr td a.btn{font-size:12px;line-height:13px;padding:4px 5px 3px;}
	table.prod-table tbody tr td.res-arrow i{font-size:18px}
	table.prod-table thead tr th{font-size:11px;line-height:13px;}
	table.prod-table tbody tr td{font-size:14px;line-height:16px;padding:0 10px 10px}
	table.prod-table tbody tr td.btn-col{padding:0 10px 10px}
	table.prod-table tbody tr td.retailer-col{padding:0 10px 10px 0}
	table.prod-table tbody tr td.res-arrow{padding:0 5px 10px 0}
	table.prod-table thead tr th, table.prod-table tbody tr td{min-height:auto;}
	table.prod-table tr{padding:8px 0}
	.productdetail-main-blk .slider-cnt .col-slide-left{margin:0 -15px 40px;width:calc(100% + 30px);}
	.productdetail-main-blk{margin:0 0 30px;}
	.productdetail-main-blk h4{font-size:17px;line-height:20px;}
	.productdetail-main-blk h4.main-title{margin-bottom:15px;}
	.prod-cat-row .prod-cat-body .prod-counrywise-tbl{background-color:#fcfcfc;-webkit-box-shadow:0px 4px 10px 3px rgba(0, 0, 0, 0.25);box-shadow:0px 4px 10px 3px rgba(0, 0, 0, 0.25);border-radius:6px;padding:15px;}
	.productdetail-cnt .product-item .prod-list-new-cnt{margin-top:5px;}
	.product-main-list-blk{min-height:250px}
	.product-main-list-blk #product_listing{min-height:150px}
	.product-main-list-blk.is_search_list-blk .product-list-header{margin:0 0 10px;padding:0;border-bottom:0}
	.product-main-list-blk .product-list-header .prod-list-title-wrap{width:calc(100% - 100px);min-height:30px;}
	.product-main-list-blk .product-list-header.search-with-filter .filter-search.search-res-none{display:none;}
	.product-main-list-blk .product-list-header .filters-blk-wrap{width:100%;}
	.product-main-list-blk .product-list-header.search-with-filter .prod-list-title-wrap{width:calc(100% - 100px);}
	.product-main-list-blk .product-list-header.search-with-filter .filters-blk-wrap{width:100%;}
	.product-main-list-blk .product-list-header .filter-search{margin:0 90px 0 0;width:calc(100% - 80px)}
	.product-main-list-blk .product-list-header .filters-blk-wrap > .filter-btn{position:absolute;top:0;right:0;}
	.product-main-list-blk .product-list-header .form-control{height:30px;padding:4px 28px 4px 15px;}
	.slider-cnt-placeholder .prod-img-cnt-blk{height:530px;}
	.img-scrollar-popup .close-img-scrollar svg{height: 27px; width: 27px;}
	.img-scrollar-popup{padding:50px 0 20px;}
	.img-scrollar-popup .close-img-scrollar{ top: 90px; right: 16px;}

	/* search list */
	.no-search-found{min-height:400px}
	.product-main-list-blk.is_search_list-blk .product-list-header.search-with-filter .prod-list-title-wrap{display:none;}
	.latestnews-wrapper.slider-placeholder{min-height:140px;}
	.latestnews-wrapper.slider-placeholder .product-item-wrap{overflow-x:auto;overflow-y:hidden;white-space:nowrap;margin:0;-webkit-overflow-scrolling:touch;display:block;}
	.latestnews-wrapper.slider-placeholder .product-item-wrap .product-item{display:inline-block;vertical-align:top;width:35%;margin:0 10px 0 0}
	.latestnews-wrapper .latestnews-blk-cnt{overflow-x:scroll;overflow-y:hidden;white-space:nowrap;margin:0;-webkit-overflow-scrolling:touch;display:block;}
	.latestnews-wrapper .latestnews-blk-cnt .product-item{padding:0 3px;width:29.5%;margin:0;display:inline-block;max-width:100%;vertical-align:top;}
	.latestnews-wrapper .latestnews-blk-cnt .product-img img{min-height:120px;max-height:120px;}
	.latestnews-wrapper .view-more-btn-item .view-more{background-color:transparent!important;font-size:0;}
	.latestnews-wrapper .view-more-btn-item .view-more.is-loading .loadicon{font-size:20px}
	.sticky-loader-active .sticky-loader-span{-webkit-transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);transform:translate(-50%, -50%);}
	.productdetail-main-blk span.release-date-txt{font-size:18px;}

	.prod-sku-table th:nth-child(1), .prod-sku-table td:nth-child(1){width:30%;}
	.productdetail-main-blk .single-image-blk div img{max-width:100%;}
}
@media (max-width:600px){
	.latestnews-wrapper .slick-prev,
	.latestnews-wrapper .slick-next{top:37%;}
	.you-may-slider .latestnews-wrapper .slick-prev,
	.you-may-slider .latestnews-wrapper .slick-next{top:42%;}
	.title-404{font-size:24px;line-height:34px;}
	.allproducts>li a.product-img{height:150px;}
	.allproducts li .product-item i.eye-icon svg{width:9px;}
	.allproducts li .product-item i.fire svg, .productdetail-cnt .product-item i.fire svg{width:7px;height:9px;}
	.allproducts li .product-item li[title="Watchlist"] i, .productdetail-cnt .product-item li[title="Watchlist"] i{font-size:9px;}
	.user-setting-blk .user-setting-row .row{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;}
	.user-setting-blk .user-setting-row .row .user-col-left{width:auto;-webkit-box-flex:0;-ms-flex:0 0 auto;flex:0 0 auto;max-width:inherit;padding-right:5px;}
	.user-setting-blk .user-setting-row .row .user-col-right{-webkit-box-flex:0;-ms-flex:0 0 auto;flex:0 0 auto;max-width:inherit;width:calc(100% - 150px);padding-left:5px;}
	.releasedate-blk p.release-title{font-size:12px;line-height:13px;}
	.releasedate-blk p.release-title.only-release{font-size:20px;line-height:23px;}
	.banner-carousel{min-height:295px}
	.banner-carousel .carousel-inner .carousel-item{height:295px}
	.main_content.home-wrap{padding:20px 0 44px;}
	.home-wrap .releasedate-blk p.release-title{margin-top:20px;margin-bottom:9px;border-bottom:1px solid #efefef;padding-bottom:4px;}
	.releasedate-blk .allproducts>li{margin:0 0 15px;width:100%}
	.releasedate-blk .product-item{display:flex;justify-content:space-between;align-items:center;flex-wrap:wrap;}
	.releasedate-blk .allproducts>li a.product-img{width:147px;height:98px;border-radius:5px;}
	.releasedate-blk .allproducts li .product-item .prod-list-new-cnt{width:calc(100% - 147px);margin:0;padding:0 0 0 8px}
	.releasedate-blk .allproducts li .product-item .prod-details{width:100%;order:1;}
	.releasedate-blk .allproducts li .product-item .infos{width:100%;order:3;}
	.releasedate-blk .allproducts li .product-item .prod-detail-cnt{order:2;margin:0 0 5px}
	.releasedate-blk .allproducts li .product-item .infos .info-count{width:100%;display:flex;align-items:center;}
	.releasedate-blk .allproducts li .product-item .infos .info-count li{width:auto;margin:0 7px 0 0;font-size:11px;line-height:13px;}
	.releasedate-blk .allproducts li .product-item .prod-date{display:none;}
	.releasedate-blk .allproducts li .product-item .prod-details .prod-name,
	.releasedate-blk .allproducts li .product-item .prod-details .prod-name a,
	.releasedate-blk .allproducts li .product-item .prod-details .prod-price,
	.releasedate-blk .allproducts li .product-item .prod-details .prod-color{font-size:11px;line-height:13px;margin:0 0 4px;}
	.releasedate-blk .allproducts li .product-item .prod-list-new-cnt .prod-detail-cnt p{font-size:7px;line-height:8px;}
	.availablenow-blk .slick-slide img,
	.staffpick-blk .slick-slide img,
	.sneakernews-blk .slick-slide img,
	.productdetail-main-blk .you-may-slider .product-img img{min-height:120px;max-height:120px;}
	.slider-cnt-placeholder .prod-img-cnt-blk{height:430px;}
	.allproducts li .product-item .infos .info-count li, .productdetail-cnt .product-item .infos .info-count li{font-size:11px}
	.allproducts-with-date{display:block;margin-bottom:0;}
	.allproducts-with-date::before{display:none;}
	.productdetail-main-blk .single-image-blk{min-height:auto;}
	.live-updates-blk .latestnews-wrapper .latestnews-blk-cnt .product-item{width:30%;}

	.latestnews-wrapper .latestnews-blk-cnt .product-img img{min-height:90px;max-height:90px;}
	.latestnews-wrapper.slider-placeholder .product-item{min-height:90px;}
}

@media (max-width:480px){
	.latestnews-wrapper .slick-prev:before{width:12px;height:22px;}
	.latestnews-wrapper .slick-next:before{width:12px;height:22px;}
	.latestnews-wrapper .slick-prev, 
	.latestnews-wrapper .slick-next{top:32%;}
	.staffpick-blk .latestnews-wrapper .slick-prev,
	.staffpick-blk .latestnews-wrapper .slick-next{top:40%;}
	.you-may-slider .latestnews-wrapper .slick-prev,
	.you-may-slider .latestnews-wrapper .slick-next{top:37%;}
	.kf-warning{font-size:12px;line-height:14px;}
	.kf-warning i svg{width:20px}
	.allproducts li .product-item .infos .info-count li{font-size:14px;}
	.allproducts li .product-item .infos .datetime{font-size:14px;}
	.allproducts>li{width:100%;padding:0 10px}
	.allproducts li .product-item .prod-details .prod-name,
	.allproducts li .product-item .prod-details .prod-name a{font-size:18px;}
	.prod-cat-row .prod-cat-body .retailer-not-found{font-size:18px;padding:15px;}
	.banner-carousel .carousel-caption{padding:0 25px 0 20px;bottom:40px;}
	.banner-carousel .carousel-caption a,.banner-carousel .carousel-caption span{font-size:16px;}
	.banner-carousel .carousel-caption h5{font-size:15px;line-height:17px;margin: 0 0 4px;}
	.banner-carousel .carousel-caption p{font-size:12px;line-height:13px;}
	.sign-block button.signin-btn{max-width:100%;font-size:12.35px;line-height:14.2px;}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row label.form-check-label{font-size:12px;}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block p{font-size:14px;line-height:21px;}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row .left,
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block form .form-row .right{width:100%}
	.sign-block .kf-login-buttons button.google-signup{max-width:134px;}
	.sign-block button.apple-signup{max-width:132px;}
	.sign-block button.google-signup:before,
	.sign-block button.apple-signup:before{top:1px}
	.sign-block button.google-signup span{padding:0px 0px 0px 20px!important;}
	.user-set-popup .modal-body{padding:25px 20px;}
	.user-set-popup .modal-title h5{padding:0 20px;}
	.user-set-popup-block .btn{width:90px;padding:5px 10px;}
	.banner-carousel{min-height:195px}
	.banner-carousel .carousel-inner .carousel-item{height:195px}
	.allproducts li .product-item .infos .info-count li .tooltip,
	.productdetail-cnt .product-item .infos .info-count li .tooltip{left:0%;}
	.allproducts li .product-item .infos .info-count li .tooltip .arrow,
	.productdetail-cnt .product-item .infos .info-count li .tooltip .arrow{left:73%;}
	.search-product-listing .allproducts >li{width:100%}
	.allproducts li .product-item .infos .info-count li .tooltip,
	.productdetail-cnt .product-item .infos .info-count li .tooltip{bottom:140%}
	.productdetail-main-blk .slider-cnt ul.info-count i.fire{margin-top:-3px}
	.productdetail-main-blk ul.info-count i.fire svg{max-width:15px;}
	.productdetail-main-blk ul.info-count i.eye-icon svg{max-width:22px;}
	.productdetail-main-blk .latestnews-wrapper .latestnews-blk-cnt,
	.search-product-listing .allproducts{margin:0}
	.productdetail-main-blk .popular-prod-blk .popular-product-lisitng-main{margin-bottom:16px;}
	.default-timezone label.user-set-lbl{line-height:30px;}
	.user-setting-blk .cst-select{margin:10px 0}
	.productdetail-cnt .prod-footer-cnt .product-item .infos .info-count li .tooltip{left:8%;}
	.sign-block form .form-row input:checked + label:after{top:2px;}
	h6.notification-bar{font-size:22px;line-height:28px;}
	.allproducts>li a.product-img{height:auto;}
	.signModal .container .modal-dialog{margin-top:80px;max-width:340px;margin-left:auto;margin-right:auto;}
	.modal-open .modal{padding-right:0;}
	.signModal .container .modal-dialog .modal-content .modal-body .sign-block{padding:52px 30px 60px;}
	.signModal .container .modal-dialog .modal-content{border-radius:13px;}
	.signModal#signinModal .container .modal-dialog .modal-content .modal-body .sign-block,
	.signModal#forgotPwdModal .container .modal-dialog .modal-content .modal-body .sign-block,
	.signModal#resetModal .container .modal-dialog .modal-content .modal-body .sign-block{max-width:100%;margin:0 auto;padding:45px 30px 55px;}
	.availablenow-blk .slick-slide img,
	.staffpick-blk .slick-slide img,
	.sneakernews-blk .slick-slide img,
	.productdetail-main-blk .you-may-slider .product-img img{min-height:90px;max-height:90px;}
	.slider-cnt-placeholder .prod-img-cnt-blk{height:310px;}
	.prod-cat-subtitle{width:100%;}

	.latestnews-wrapper .latestnews-blk-cnt .product-img img{min-height:71px;max-height:71px;}
	.latestnews-wrapper.slider-placeholder .product-item{min-height:71px;}
	.live-updates-blk .latestnews-wrapper .latestnews-blk-cnt .product-item{width:35%;}
	.live-updates-blk .prod-details{height:calc(100% - 120px);}
	.live-updates-blk .prod-images{height:120px;}
	.live-updates-blk .prod-images.has-1-image>div{height:120px}
	.live-updates-blk .prod-images.has-3-image>div{height:60px;}
	.live-updates-blk .prod-images.has-3-image>.prod-image-0{height:120px;}
	.live-updates-blk .prod-images.has-4-image>div{height:60px;}

	/* home */
	.popular-prod-blk .popular-product-lisitng-filter li{font-size:9px;line-height:10px;}
	.popular-prod-blk .popular-product-lisitng-main{margin-bottom:10px;}
	button.load-more{width:100%;font-size:13px;line-height:14px;padding:8px 20px}
	.recent-prod-blk .prod-list-new{min-height:40px}
	.recent-prod-blk .item-image{display:none;}
	.recent-prod-blk .item-name{width:calc(100% - 90px);}
	.item-uploaded{font-size:11px;line-height:12px;}
	span.item-rank .rank-item-new{font-size:9px;}
	.recent-prod-blk .prod--main-title{margin-bottom:13px;}
	.releasedate-blk button.load-more{padding:4px 20px;line-height:15px;}
	

	/* about us */
	.about-us-page .cms-cnt-inner{padding:0;}
	.about-us-page .cms-cnt-inner .cms-img-conatiner{margin:0 -15px;width:auto;}
	.about-us-page.cms-column-layout .about-title{margin:0 0 15px 0;}
	.about-us-page.cms-column-layout .cms-cnt-txt{margin:0 0 28px 0;}
	.about-us-page .cms-cnt-inner .cms-cnt-wrapper{margin:30px 0 0 0;}
	.about-us-page form{margin:0;}
	.about-us-page form > div{padding:0;}
}
@media (max-width:400px){
	.sign-block .kf-login-buttons button.google-signup{max-width:100%;}
	.sign-block button.apple-signup{max-width:100%}

	.latestnews-wrapper .slick-prev, 
	.latestnews-wrapper .slick-next{top:29%;}
	.staffpick-blk .latestnews-wrapper .slick-prev,
	.staffpick-blk .latestnews-wrapper .slick-next{top:38%;}
	.you-may-slider .latestnews-wrapper .slick-prev,
	.you-may-slider .latestnews-wrapper .slick-next{top:35%;}
	footer .footer-logo{padding-right:0;}
	footer ul.socialmedia{position:static;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;}
	footer .footer-nav{margin:0 0 30px;}
	footer ul.storelist,
	footer ul.copyright{text-align:center;}
	.btn-setting{width:165px;}
	.btn-logout{width:110px;}
	.user-setting-row .logout-head{width:110px;}
	.user-setting-select{float:none;margin:8px 0}
	.user-setting-blk .user-setting-row .row{-ms-flex-wrap:wrap;flex-wrap:wrap;}
	.user-setting-blk .user-setting-row .row .user-col-left{width:100%;-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;padding-right:15px;}
	.user-setting-blk .user-setting-row .row .user-col-right{width:100%;-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;text-align:left!important;padding-left:15px;}

	/* home */
	.prod-list-new .item-list-counter{width:90px;}
	.item-name{width:calc(100% - 184px);}
	span.item-number{width:40px;font-size:18px;line-height:22px;}
	.item-image{width:94px;height:58px;}
	.availablenow-blk .slick-slide img,
	.staffpick-blk .slick-slide img,
	.sneakernews-blk .slick-slide img,
	.productdetail-main-blk .you-may-slider .product-img img{min-height:75px;max-height:75px;}
	.live-updates-blk .latestnews-wrapper .latestnews-blk-cnt .product-item{width:40%;}
	.live-updates-blk .prod-details{height:calc(100% - 100px);}
	.live-updates-blk .prod-images{height:100px;}
	.live-updates-blk .prod-images.has-1-image>div{height:100px}
	.live-updates-blk .prod-images.has-3-image>div{height:50px;}
	.live-updates-blk .prod-images.has-3-image>.prod-image-0{height:100px;}
	.live-updates-blk .prod-images.has-4-image>div{height:50px;}

	/* about us */
	.about-us-page form button[type="submit"]{min-width:175px;}
	.about-us-page form .about-label-button p{margin-right:25px;}

	.watchlist-not-found .wtch-not-fount-box{max-width:100%}
	/* .releasedate-blk .allproducts>li a.product-img{width:100%;height:auto;}
	.releasedate-blk .allproducts li .product-item .prod-list-new-cnt{width:100%;padding:10px 0 0;} */

	table.prod-table tbody tr td{text-align:left;padding:0 0 5px}
	table.prod-table thead tr th.release-type{display:none;}
	.productdetail-main-blk table.prod-table tbody tr:not(.is_open) td.release-type{display:none;}
	table.prod-table tbody tr td.release-type{order:6;width:100%}
	table.prod-table tbody tr td.time-col{width:100%;padding:0 0 5px}
	table.prod-table tbody tr td.reminder-col{padding:0;width:100%}
	table.prod-table tbody tr td.btn-col, table.prod-table thead tr th.btn-col{width:45%;}
	table.prod-table tbody tr td.retailer-col, table.prod-table thead tr th.retailer-col{width:50%}
	table.prod-table tbody tr td.retailer-col{padding:0 10px 5px 0}
	table.prod-table tbody tr td.btn-col{padding:0 10px 5px}
	.prod-sku-table th:nth-child(3),.prod-sku-table td:nth-child(3){width:25%;}
	.prod-sku-table th:nth-child(2),.prod-sku-table td:nth-child(2){width:35%;}

	.latestnews-wrapper .latestnews-blk-cnt .product-item{width:35%;}
	.top-header span.timezone-cnt { padding-right: 15px; }
}
.kf-warning a {
    color: black;
    border-bottom: 1px solid black;
    font-weight: bold;
	transition: all .25s ease-in-out;
	-webkit-transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
}
.kf-warning a:hover {
    color: #707070;
    text-decoration: none;
    border-color: #707070;
}
@media (min-width:1024px){
    .productdetail-main-blk .slider-cnt .col-slide-left .slide-wrapper{position:relative;}
    .productdetail-main-blk .slider-cnt .col-slide-left .slide-wrapper .zoom-img-icon{right:20px!important;bottom:20px!important;}
}