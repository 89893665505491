.most-popular-main .product-blk-main{width:100%;}
.most-popular-main .most-popular-wrap{padding:12px 0;position: relative;}
.most-popular-main .product-blk-wrap{-ms-flex-wrap:wrap;flex-wrap:wrap;padding:0;}
.most-popular-main .releasedate-blk{padding-top:0;}
.back-to-home{width:100%;text-align:right;}
.back-to-home a{font-size:14px;line-height:16px;font-family:"Arial";font-weight:400;color:#a8a8a8;text-decoration:none;}
.most-popular-main .popular-prod-blk{width:100%;}
.most-popular-main .popular-product-lisitng-main{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;}
.most-popular-main .popular-product-lisitng-main p{width:100%;font-size:16px;line-height:18px;font-family:"Arial";font-weight:400;color:#707070;margin:8px 0 0;}
.most-popular-main .popular-prod-time-filter{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;}
.most-popular-main .most-popular-products-main{padding:16px 0 35px;}
.most-popular-products-main .prod-list-new{width:calc(33.33% - 31px);margin:0 15.5px;}
.most-popular-products-main .popular-product-inner-lisitng{margin:0 -15.5px 26px;}
.most-popular-products-main .popular-product-inner-lisitng:last-child{margin-bottom:0;}
.most-popular-wrap-blk{position: relative;}
.most-popular-wrap-blk.sticky-loader-active {width: 100%; min-height: 200px;margin-top:50px;}
.most-popular-wrap-blk.sticky-loader-active .sticky-loader-span{transform:translate(-50%, -50%);}
.popular-product-inner-blk{position: relative;}
.popular-prod-blk .loadmore-btn.loadbtn-disabled button.load-more { border-color: #d9d9d9; cursor: default; color: #d9d9d9; }
.most-popular-main .allproducts>li .display-text-none{display: none}
.most-popular-main .allproducts>li.product-list-week-place .display-text-none{display: block ;}
.msp-rank-message{font-size:10px;line-height:11px;color:#707070;display:block;text-align:center;margin:10px 0 0}
@media (min-width:768px){
	.most-popular-main .allproducts>li{width:33.333333333333336%;}
	.most-popular-main .allproducts>li{margin:26px 0;}
	.most-popular-main .allproducts>li.product-list-week-place{width:50%;margin:8px 0 46px;}
	.most-popular-main .prod-rank-counter,.most-popular-main .prod-list-counter{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;}
	.most-popular-main .prod-list-counter{position:relative;top:15px;left:-15px;z-index:11;}
	.most-popular-main .releasedate-blk .item-number{font-size:26px;font-weight:400;line-height:30px;color:#fff;font-family:"Arial";display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;border-radius:50px;min-width:36px;min-height:36px;background:#000;padding:3px 7px 3px 5px;width:auto;}
	.most-popular-main .releasedate-blk .item-number span{font-size:18px;line-height:24px;}
	.most-popular-main .releasedate-blk span.item-rank{margin-left:-9px;}
	.most-popular-main .product-list-week-place .prod-rank-counter{margin-bottom:19px;}
	.most-popular-main .product-list-week-place .prod-list-counter{position:static;}
	.most-popular-main .product-list-week-place .item-number{padding:3px 17px 3px 14px;margin-right:7px;}
	.most-popular-main .product-list-week-place p{font-size:26px;font-weight:400;line-height:30px;color:#000;font-family:"Arial";margin:0;}
	.most-popular-main .allproducts .product-list-week-place a.product-img{height:435px;}
	.most-popular-main .allproducts li.product-list-week-place .product-item .prod-name,.most-popular-main .allproducts li.product-list-week-place .product-item .prod-name a,.most-popular-main .allproducts li.product-list-week-place .product-item .prod-date,.most-popular-main .allproducts li.product-list-week-place .product-item .prod-price,.most-popular-main .allproducts li.product-list-week-place .product-item .info-count li{font-size:26px;line-height:30px;}
	.most-popular-main .allproducts li.product-list-week-place .product-item .prod-list-new-cnt p,.most-popular-main .allproducts li.product-list-week-place .infos .fa-bookmark-o{font-size:17px;line-height:19px;}
	.most-popular-main .product-list-week-place span.item-rank{margin-left:9px;}
	.most-popular-main li.product-list-week-place .infos .eye-icon svg{width:22px;height:14px;}
	.most-popular-main li.product-list-week-place .infos .fire svg{width:15px;height:20px;}
	.most-popular-main li.product-list-week-place .product-item .prod-details{width:calc(100% - 85px);}
	.most-popular-main li.product-list-week-place .product-item .infos{width:85px;}
}
@media (min-width:1025px){
	.most-popular-main .allproducts>li{width:25%;}
}
@media (max-width:1440px){
	.most-popular-main .allproducts .product-list-week-place a.product-img{height:355px;}
	.most-popular-main .container{padding:0 20px;}
	.most-popular-main .allproducts li.product-list-week-place .product-item .prod-name,.most-popular-main .allproducts li.product-list-week-place .product-item .prod-name a,.most-popular-main .allproducts li.product-list-week-place .product-item .prod-date,.most-popular-main .allproducts li.product-list-week-place .product-item .prod-price,.most-popular-main .allproducts li.product-list-week-place .product-item .info-count li{font-size:22px;line-height:26px;}
	.most-popular-main .allproducts li.product-list-week-place .product-item .prod-list-new-cnt p,.most-popular-main .allproducts li.product-list-week-place .infos .fa-bookmark-o{font-size:15px;line-height:17px;}
	.most-popular-main li.product-list-week-place .infos .eye-icon svg{width:20px;height:12px;}
	.most-popular-main li.product-list-week-place .infos .fire svg{width:14px;height:18px;}
}
@media (max-width:1200px){
	.most-popular-main .releasedate-blk .item-number{font-size:22px;line-height:26px;padding:3px 5px 4px 4px;}
	.most-popular-main .releasedate-blk .item-number span{font-size:16px;line-height:22px;}
	.most-popular-main .product-list-week-place .item-number{padding:3px 16px 4px 15px;}
	.most-popular-main .product-list-week-place p{font-size:22px;line-height:26px;}
	.most-popular-main .product-list-week-place .prod-rank-counter{margin-bottom:14px;}
	.most-popular-main .allproducts>li.product-list-week-place{margin:8px 0 26px;}
	.most-popular-main .allproducts>li{margin:12px 0;}
	.most-popular-products-main .prod-list-new{width: calc(50% - 31px)}
}
@media (max-width:991px){
	.most-popular-main .allproducts .product-list-week-place a.product-img{height:260px;}
	.most-popular-main li.product-list-week-place .product-item .infos{width:70px;}
	.most-popular-main li.product-list-week-place .product-item .prod-details{width:calc(100% - 70px);}
	.most-popular-main .allproducts li.product-list-week-place .product-item .prod-name,.most-popular-main .allproducts li.product-list-week-place .product-item .prod-name a,.most-popular-main .allproducts li.product-list-week-place .product-item .prod-date,.most-popular-main .allproducts li.product-list-week-place .product-item .prod-price,.most-popular-main .allproducts li.product-list-week-place .product-item .info-count li{font-size:19px;line-height:23px;}
	.most-popular-main li.product-list-week-place .infos .eye-icon svg{width:18px;height:12px;}
	.most-popular-main li.product-list-week-place .infos .fire svg{width:12px;height:16px;}
	.most-popular-main .allproducts li.product-list-week-place .product-item .prod-list-new-cnt p,.most-popular-main .allproducts li.product-list-week-place .infos .fa-bookmark-o{font-size:14px;line-height:16px;}
	.most-popular-main .allproducts>li.product-list-week-place{margin:8px 0 12px;}
	.most-popular-main .allproducts>li{margin:10px 0;}
}
@media (max-width:830px){
	.most-popular-main .allproducts .product-list-week-place a.product-img{height:240px;}
	.most-popular-main .popular-prod-time-filter{width:100%;justify-content:space-between;}
	.most-popular-main .popular-prod-name-filter{width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;padding:15px 0 10px;}
}
@media (max-width:767px){
	.most-popular-main .releasedate-blk li{width:100%;padding:0;margin:0;}
	.most-popular-main .releasedate-blk li.product-list-week-place{margin:0;}
	.most-popular-main .releasedate-blk .product-item{display:flex;flex-wrap:nowrap;align-items:center;border-top:1px solid #D7D7D7;padding:10px 0;}
	.most-popular-main .releasedate-blk li:nth-child(5n) .product-item{border-bottom:1px solid #D7D7D7;}
	.most-popular-main li.product-list-week-place .product-item .prod-details{width:100%;}
	.most-popular-main .releasedate-blk .product-item .prod-list-img{display:flex;flex-wrap:nowrap;margin-right:14px;}
	.most-popular-main .releasedate-blk .prod-rank-counter{width:110px;display:flex;flex-wrap:nowrap;align-items:center;margin:0;}
	.most-popular-main .releasedate-blk .item-number{font-size:24px;line-height:28px;padding:0px 3px;}
	.most-popular-main .releasedate-blk .item-number + p,.most-popular-main .releasedate-blk .item-number > span{display:none;}
	.most-popular-main .releasedate-blk .allproducts>li a.product-img{display:inline-block;width:95px;height:63px;}
	.most-popular-main .allproducts .product-list-week-place a.product-img{height:63px;}
	.most-popular-main .releasedate-blk .allproducts li .product-item .prod-list-new-cnt{margin-top:0;width:calc(100% - 219px);padding:0;}
	.most-popular-main .releasedate-blk li .infos,.most-popular-main .releasedate-blk li .prod-detail-cnt{display:none;}
	.most-popular-main .releasedate-blk li .prod-date,.most-popular-main .releasedate-blk li .prod-price{display:none;}
	.most-popular-main .releasedate-blk .allproducts li .product-item .prod-name a{font-size:12px;line-height:13px;}
	.most-popular-main .allproducts li.product-list-week-place .product-item .prod-name a{font-size:12px;line-height:13px;}
	.most-popular-main .product-blk-main{margin:20px 0 0;}
	.most-popular-main .product-blk-main .popular-prod-blk{margin-top:18px;}
	.most-popular-main .product-blk-main .popular-product-lisitng-filter li,.most-popular-main .popular-product-lisitng-main p{font-size:14px;line-height:16px;}
	.most-popular-main .product-blk-main .popular-product-lisitng-filter{margin:5px 0;}
	.most-popular-products-main .prod-list-new{width:100%;margin:0;}
	.most-popular-products-main .popular-product-inner-lisitng{margin-left:-10px;margin-right:-10px;}
	.most-popular-main .popular-prod-name-filter{display:none;}
	.prod-list-new:nth-child(5n):not(:last-child){border-bottom:0;}
	.most-popular-main .popular-prod-blk .popular-product-lisitng-main h3{font-size:32px;line-height:37px;}
	.most-popular-main .most-popular-wrap-blk{width:100%;}
}
@media (max-width:400px){
	.most-popular-main .releasedate-blk .prod-rank-counter{width:90px;}
	.most-popular-main .releasedate-blk .item-number{font-size:18px;line-height:22px;width:40px;}
	.most-popular-main .releasedate-blk .item-rank{font-size:14px;line-height:16px;}
	.most-popular-main .releasedate-blk .allproducts>li a.product-img{width:80px;height:58px;}
	.most-popular-main .releasedate-blk .allproducts li .product-item .prod-list-new-cnt{width:calc(100% - 184px);}
	.most-popular-main .popular-prod-blk .popular-product-lisitng-main h3{font-size:21px;line-height:26px;}
}